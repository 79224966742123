import DefaultDesktopImage from "@assets/desktop-default.png";
import DefaultMobileImage from "@assets/mobile-default.png";
import clsx from "clsx";
import { type ImgHTMLAttributes, useState } from "react";

import { getFormattedCaaSImageUrl } from "./helper";

export type Prettify<T> = {
  [K in keyof T]: T[K];
};

export type OptimisedImageProps = Prettify<
  ImgHTMLAttributes<HTMLImageElement> & {
    rootClassName?: string;
    src?: string;
    filters?: Record<string, CaaSImageFilters>;
    pictureRootClassName?: string;
    suffix?: React.ReactNode;
  }
>;

export type CaaSImageFilters = {
  breakpoint?: string; // breakpoint
  f?: "jpeg" | "png" | "webp" | "avif" | "gif" | "svg"; // format
  w?: number; // width 10 to 1280
  h?: number; // height 10 to 1280
  q?: number; // quality 20 - 100
  dpr?: number; // dpr 1 - 3
};

const smFilter: CaaSImageFilters = { w: 240, q: 80, dpr: 3 };
const mdFilter: CaaSImageFilters = { w: 270, q: 80, dpr: 3 };
const lgFilter: CaaSImageFilters = { w: 300, q: 80, dpr: 3 };
const xlFilter: CaaSImageFilters = { w: 480, q: 80, dpr: 2 };
const xxlFilter: CaaSImageFilters = { w: 400, q: 80, dpr: 3 };

// CaaS Image API
// - https://sph.atlassian.net/wiki/spaces/PAPP/pages/1514865469/CaaS+how-tos#Image-optimisation-API
// - https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api
export default function OptimisedImage({
  pictureRootClassName,
  rootClassName,
  src,
  filters = {},
  suffix,
  ...rest
}: OptimisedImageProps): React.ReactElement {
  const [imageError, setImageError] = useState<boolean>(false);

  const computedFilters = {
    sm: smFilter,
    md: mdFilter,
    lg: lgFilter,
    xl: xlFilter,
    xxl: xxlFilter,
    ...filters,
  };

  return (
    <picture className={pictureRootClassName}>
      {src && !imageError ? (
        <>
          <source
            media="(min-width: 1024px)"
            srcSet={getFormattedCaaSImageUrl(src, computedFilters.xxl)}
            type="image/avif"
          />
          <source
            media="(min-width: 992px)"
            srcSet={getFormattedCaaSImageUrl(src, computedFilters.xl)}
            type="image/avif"
          />

          <source
            media="(min-width: 576px)"
            srcSet={getFormattedCaaSImageUrl(src, computedFilters.lg)}
            type="image/avif"
          />

          <source
            media="(min-width: 320px)"
            srcSet={getFormattedCaaSImageUrl(src, computedFilters.md)}
            type="image/avif"
          />

          <source
            srcSet={getFormattedCaaSImageUrl(src, computedFilters.sm)}
            type="image/avif"
          />

          <img
            className={clsx(
              "w-full max-w-full lg:max-w-[600px]",
              rootClassName,
            )}
            src={getFormattedCaaSImageUrl(src, computedFilters.xl)}
            alt={rest.alt}
            onError={() => {
              setImageError(true);
            }}
            {...rest}
          />
        </>
      ) : null}

      {imageError || !src ? (
        <img
          className={clsx(
            "!hidden w-full max-w-full lg:!block lg:max-w-[600px]",
            rootClassName,
          )}
          src={DefaultDesktopImage}
          alt={rest.alt}
        />
      ) : null}

      {imageError || !src ? (
        <img
          className={clsx("!block w-full max-w-full lg:!hidden", rootClassName)}
          src={DefaultMobileImage}
          alt={rest.alt}
        />
      ) : null}

      {suffix}
    </picture>
  );
}
