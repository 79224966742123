/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AdTargetingType } from "@constants/ads";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import { useCallback, useEffect } from "react";
import { useSnapshot } from "valtio";

type GoogleAdsTargetingProps = {
  targeting?: AdTargetingType | Array<AdTargetingType>;
};

export default function useGoogleAdsTargeting({
  targeting,
}: GoogleAdsTargetingProps = {}) {
  const { user } = useSnapshot(userState);

  const handleGlobalAdsetting = useCallback(() => {
    window.googletag = window.googletag || { cmd: [], slots: {} };
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.slots = window.googletag.slots || {};

    googletag.cmd.push(function () {
      switch (user?.usertype) {
        case OKTAUserTypeEnum.SUBSCRIBER:
          googletag.pubads().setTargeting("subscriber", "Y");
          break;

        case OKTAUserTypeEnum.REGISTETERED:
          googletag.pubads().setTargeting("subscriber", "R");
          break;

        default:
          googletag.pubads().setTargeting("subscriber", "N");
          break;
      }

      if (targeting) {
        if (Array.isArray(targeting)) {
          targeting.forEach((target) => {
            googletag.pubads().setTargeting(target.key, target.value);
          });
        } else {
          googletag.pubads().setTargeting(targeting.key, targeting.value);
        }
      }

      googletag.pubads().collapseEmptyDivs();
    });
  }, [targeting, user]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleGlobalAdsetting();
    }
  }, [handleGlobalAdsetting]);
}
