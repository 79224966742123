import ArrowLeft from "@assets/icons/article/arrow-left.svg";
import ArrowRight from "@assets/icons/article/arrow-right.svg";
import CloseIcon from "@assets/icons/article/close-icon.svg";
import globalState from "@stores/global";
import viewportState from "@stores/viewport";
import { ArticleDetailNS } from "@typings/Data";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { useSnapshot } from "valtio";

export interface IImageGalleryModal {
  open?: boolean;
  activeIndex?: number;
  images?: ArticleDetailNS.Article["images"] | null;
  onClose?: VoidFunction;
  showWatermark?: boolean;
}

export default function ImageGalleryModal({
  open = false,
  images = [],
  activeIndex = 0,
  onClose,
  showWatermark = false,
}: IImageGalleryModal) {
  const { isClientSideMounted } = useSnapshot(globalState);
  const { isDesktopView } = useSnapshot(viewportState);
  const sliderRef = useRef<Slider>(null);
  const sliderSettings: Settings = {
    speed: 500,
    arrows: false,
    dots: false,
    autoplay: false,
    // adaptiveHeight: true,
    beforeChange: (_, next) => {
      setCurrentIndex(next);
    },
  };

  useEffect(() => {
    if (open) {
      setCurrentIndex(0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const activeImage = images?.[currentIndex] || null;

  if (!isClientSideMounted) {
    return null;
  }

  const isSingleImage = (images || []).length == 1;
  const showPreNextButton = isDesktopView && !isSingleImage;

  return open ? (
    <div
      className="fixed inset-0 z-[10000] overscroll-contain bg-cinema"
      onTouchMove={(e) => {
        e.preventDefault();
      }}
    >
      {/* header title */}
      <div className="fixed inset-0 z-[10002] box-border flex h-[50px] min-h-[50px] items-center justify-center border-b border-solid border-white px-[20px]">
        <button
          className="pointer-events-auto absolute right-[20px] top-1/2 flex h-[30px] w-[30px] -translate-y-1/2 items-center justify-center rounded-full bg-button-secondary-default hover:bg-button-secondary-hover"
          onClick={() => {
            console.log("click button1");
            onClose?.();
          }}
        >
          <img
            src={CloseIcon}
            alt="close"
            className=" h-[22px] w-[22px] cursor-pointer"
          />
        </button>

        <div className=" text-[22px] font-medium leading-[150%] text-white">
          {currentIndex + 1}/{(images || []).length}
        </div>
      </div>

      {/*  */}
      <div
        onTouchMove={(e) => {
          e.stopPropagation();
        }}
        className={clsx(
          "imageGalleryModal fixed inset-x-0 top-[50px] z-[10001] box-border flex h-[calc(100vh-50px)] max-h-[calc(100vh-50px)] w-full items-center justify-center overflow-hidden",
          isDesktopView ? "px-[20px]" : "",
        )}
      >
        {showPreNextButton && (
          <button
            className="flex min-w-max items-center justify-center"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <div className=" flex h-[40px] w-[40px] items-center justify-center rounded-full bg-button-secondary-default hover:bg-button-secondary-hover">
              <img src={ArrowLeft} alt="" className=" m-0 h-[24px] w-[24px]" />
            </div>
          </button>
        )}

        <div
          className={clsx(
            "relative box-border flex-1",
            isDesktopView
              ? isSingleImage
                ? "max-w-[calc(100vw-40px)]"
                : "max-w-[calc(100vw-120px)]"
              : "max-w-[calc(100vw)]",
          )}
        >
          <Slider {...sliderSettings} ref={sliderRef}>
            {(images || []).map((item, index: number) => {
              return (
                <div key={index}>
                  <img
                    alt=""
                    src={`${item.url}${showWatermark ? "?o=zbimg&oloc=se" : ""}`} // watermark
                    className="mx-auto my-0 box-border h-max max-h-[calc(100vh-50px)] w-max max-w-full rounded-[4px] object-contain"
                  />
                </div>
              );
            })}
          </Slider>
        </div>

        {showPreNextButton && (
          <button
            className="flex min-w-max items-center justify-center"
            onClick={() => sliderRef.current?.slickNext()}
          >
            <div className=" flex h-[40px] w-[40px] items-center justify-center rounded-full bg-button-secondary-default hover:bg-button-secondary-hover">
              <img src={ArrowRight} alt="" className=" m-0 h-[24px] w-[24px]" />
            </div>
          </button>
        )}
      </div>

      {activeImage?.caption ? (
        <div className=" fixed inset-x-0 bottom-0 z-[10001] bg-overlay-secondary px-[20px] text-center">
          <figcaption className="mx-auto my-[16px] min-h-max max-w-[800px] text-center text-[14px] font-normal text-white">
            {activeImage.caption}
          </figcaption>
        </div>
      ) : null}
    </div>
  ) : null;
}
