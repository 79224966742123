import { useScript } from "@sphtech/web2-core/hooks";
import viewportState from "@stores/viewport";
import { useEffect } from "react";
import { useSnapshot } from "valtio";

/**
 * @name useFBInit
 * @description re-parse the fb-post/fb-video elements for article page
 */
export default function useFBInit(renderBodyContent: string) {
  const { isMobileView } = useSnapshot(viewportState);

  // add <div id="fb-root"></div>
  useEffect(() => {
    const exist = document.getElementById("fb-roor");
    if (exist) {
      return;
    }
    const fbRoot = document.createElement("div");
    fbRoot.id = "fb-root";
    document.body.appendChild(fbRoot);
  }, []);

  // add  <script async crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
  useScript({
    id: "fb-script",
    src: "https://connect.facebook.net/en_US/sdk.js",
    async: true,
  });

  useEffect(() => {
    window.fbAsyncInit = function () {
      if (window.FB) {
        window.FB.init({
          xfbml: true,
          version: "v15.0",
        });
      }
    };

    const fbPostElements = document.getElementsByClassName("bff-fb-post");
    for (const element of fbPostElements) {
      if (!isMobileView) {
        element.setAttribute("data-width", "500");
      } else {
        element.setAttribute("data-width", "auto");
      }
    }

    // reload FB embed
    window.fbAsyncInit();
  }, [renderBodyContent, isMobileView]);
}
