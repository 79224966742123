import { proxy } from "valtio";

export enum FONT_SIZE_OPTION {
  small = "max-lg:!text-base", // 10
  standard = "max-lg:!text-[17px]", // 11
  large = "max-lg:!text-[19px]", // 12
  xlarge = "max-lg:!text-[22px]", // 14
}
interface IFontState {
  fontSize: FONT_SIZE_OPTION;
  updateGlobalFontSize: (newFontSize: IFontState["fontSize"]) => void;
}

const fontSizeEmMapping: Record<number, FONT_SIZE_OPTION> = {
  10: FONT_SIZE_OPTION.small,
  11: FONT_SIZE_OPTION.standard,
  12: FONT_SIZE_OPTION.large,
  14: FONT_SIZE_OPTION.xlarge,
};

const fontSizeEmMappingReverse: Record<FONT_SIZE_OPTION, number> = {
  [FONT_SIZE_OPTION.small]: 10,
  [FONT_SIZE_OPTION.standard]: 11,
  [FONT_SIZE_OPTION.large]: 12,
  [FONT_SIZE_OPTION.xlarge]: 14,
};

const initialFontSize =
  typeof window !== "undefined"
    ? ((window.localStorage.getItem("zbFontSizing") ?? 11) as number)
    : 11;

/**
 * @description set default value FONT_SIZE_OPTION.standard if not exist, to adapt to the situation that when navigate to web1.0 page,
 * it will set default value 10, back to web2.0 page, will cause unexpected result.
 */
if (typeof window !== "undefined") {
  if (!window.localStorage.getItem("zbFontSizing")) {
    window.localStorage.setItem(
      "zbFontSizing",
      `${fontSizeEmMappingReverse[FONT_SIZE_OPTION.standard]}`,
    );
  }
}

/**
 *  Font size store
 * @returns {IFontState.fontSize} - Current font size
 * @returns {IFontState.updateGlobalFontSize} - Function to change font size
 * @example updateGlobalFontSize(FONT_SIZE_OPTION.large);
 */
const fontState = proxy<IFontState>({
  fontSize: fontSizeEmMapping[initialFontSize],
  updateGlobalFontSize: (newFontSize: IFontState["fontSize"]) => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        "zbFontSizing",
        `${fontSizeEmMappingReverse[newFontSize]}`,
      );
    }

    fontState.fontSize = newFontSize;
  },
});

/**
 * fontsize 16 18 20 24
 */
export const fontSizeClassNameOptions = {
  [FONT_SIZE_OPTION.small]: "text-[16px]",
  [FONT_SIZE_OPTION.standard]: "text-[18px]",
  [FONT_SIZE_OPTION.large]: "text-[20px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[24px]",
} as Record<FONT_SIZE_OPTION, string>;

/**
 * fontsize 12 14 16 18
 */
export const fontSizeClassNameOptions_1 = {
  [FONT_SIZE_OPTION.small]: "text-[12px]",
  [FONT_SIZE_OPTION.standard]: "text-[14px]",
  [FONT_SIZE_OPTION.large]: "text-[16px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[18px]",
} as Record<FONT_SIZE_OPTION, string>;

/**
 * fontsize 10 12 14 16
 */
export const fontSizeClassNameOptions_2 = {
  [FONT_SIZE_OPTION.small]: "text-[10px]",
  [FONT_SIZE_OPTION.standard]: "text-[12px]",
  [FONT_SIZE_OPTION.large]: "text-[14px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[16px]",
} as Record<FONT_SIZE_OPTION, string>;

/**
 * fontsize 30 32 34 38
 */
export const fontSizeClassNameOptions_3 = {
  [FONT_SIZE_OPTION.small]: "text-[30px]",
  [FONT_SIZE_OPTION.standard]: "text-[32px]",
  [FONT_SIZE_OPTION.large]: "text-[34px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[38px]",
} as Record<FONT_SIZE_OPTION, string>;

/**
 * fontsize 22 24 26 30
 */
export const fontSizeClassNameOptions_4 = {
  [FONT_SIZE_OPTION.small]: "text-[22px]",
  [FONT_SIZE_OPTION.standard]: "text-[24px]",
  [FONT_SIZE_OPTION.large]: "text-[26px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[30px]",
} as Record<FONT_SIZE_OPTION, string>;

/**
 * fontsize 20 22 24 28 for subheading
 */
export const fontSizeClassNameOptions_7 = {
  [FONT_SIZE_OPTION.small]: "text-[20px]",
  [FONT_SIZE_OPTION.standard]: "text-[22px]",
  [FONT_SIZE_OPTION.large]: "text-[24px]",
  [FONT_SIZE_OPTION.xlarge]: "text-[28px]",
} as Record<FONT_SIZE_OPTION, string>;

export default fontState;
