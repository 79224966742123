export const SUB_HREF =
  "https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header";

export enum MODAL_TITLE {
  MAIN_TITLE = "订户专享 赠阅文章",
  MAIN_TITLE_WITH_TOKEN = "您收到一篇订户专享文章",
}

export enum ANONY_USER {
  MAIN_TXT = "早报VIP会员登录后，即可使用此功能。",
  MAIN_TXT_WITH_TOKEN = "有早报VIP会员送您这篇订户专享文章，供您免费阅读。",
  DESCRIPTION = "早报VIP会员每月可赠阅 5 篇订户专享文章。",
  DESCRIPTION_WITH_TOKEN = "成为早报VIP会员，解锁更多早报精彩内容。",
}

export enum LOGIN_USER {
  MAIN_TXT = "赠阅订户专享文章为早报VIP会员专享功能，早报VIP会员登录后，即可使用此功能。",
  MAIN_TXT_WITH_TOKEN = "有早报VIP会员送您这篇订户专享文章，供您免费阅读。",
  MAIN_TXT_WITH_EXPIRED = "您的免费阅读期限已过。",
  DESCRIPTION = "早报VIP会员每月可赠阅 5 篇订户专享文章。",
}

export enum SUBSCIBE_USER {
  // MAIN_TXT = "", 需要动态传入count 不存为静态
  MAIN_TXT = "选择赠阅文章将生成赠阅链接，您本月的余额将减少一次。链接分享期限为 30 天。",
  MAIN_TXT_NO_CHANGE = "您此前生成这篇文章的赠阅链接仍有效。您可以再赠阅此篇文章，您本月的余额保持不变。",
  MAIN_TXT_HAS_EXPIRED = "您此前生成这篇文章的赠阅链接已失效。选择赠阅文章将生成新的赠阅链接，您本月的余额将减少一次。链接分享期限为 30 天。",
  MAIN_TXT_NO_REMAINING = "这个月您还可赠阅 0 篇订户专享文章。",
  DESCRIPTION = "早报VIP会员每月可赠阅 5 篇订户专享文章。",
  // DESCRIPTION = "点击“赠阅文章”生成链接发送给亲友，收到链接的亲友可免费阅读这篇订户专享文章。",
  DESCRIPTION_NO_REMAINING = "早报VIP会员每月可赠阅 5 篇文章。您此前已赠阅 5 篇文章，本月余额为零。",
  DESCRIPTION_COUNT_NO_CHANGE = "您之前已生成过这篇文章的赠阅链接，您本月的余额保持不变。",
}
