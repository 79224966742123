import { type HTMLProps } from "react";

interface AriaIframeProps extends HTMLProps<HTMLIFrameElement> {
  // Source URL for the iframe
  src: string;

  // A descriptive title for the iframe content
  title: string;

  // Allow the iframe to be displayed in fullscreen mode
  allowFullScreen?: boolean;
}

export default function AriaIframe({
  src,
  title,
  allowFullScreen = false,
  ...rest
}: AriaIframeProps) {
  return (
    <iframe
      src={src}
      title={title}
      aria-label={title}
      allowFullScreen={allowFullScreen}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      loading="lazy"
      {...rest}
    />
  );
}
