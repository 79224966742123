import MostReadArticleCard from "@components/ArticleCard/MostReadArticleCard/MostReadArticleCard";
import { ArticlePreviewType } from "@typings/Data";

export default function SideMostReadArticleList({
  articles = [],
}: {
  articles: Array<ArticlePreviewType>;
}) {
  return (
    <ul
      id="block-latestnews"
      data-testid="block-latestnews"
      className={`most-read-article-list pt-[16px]`}
    >
      {articles.slice(0, 5).map((article) => {
        return (
          <li key={article.id}>
            <MostReadArticleCard article={article} />
          </li>
        );
      })}
    </ul>
  );
}
