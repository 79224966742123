// import "@components/BrightcoveVideoCarousel/BrightcoveVideoCarousel.css";

import Slider, { Settings } from "@ant-design/react-slick";
import ArrowNext from "@assets/icons/arrow-forward-icon.svg?react";
import ArrowPrev from "@assets/icons/arrow-prev-icon.svg?react";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import { getDuration } from "@constants/getDuration";
import globalState from "@stores/global";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useSnapshot } from "valtio";

const brightcovePlaylistPlayerId: string = import.meta.env
  .VITE_BC_PLAYLIST_PLAYER_ID as string;
// console.log(brightcovePlaylistPlayerId, "BC Playlist Player ID");
export type BrightcoveVideoCarouselProps = {
  playlistId: number | string;
  account?: number | string;
  player?: string;
  additionalClasses?: string;
};

export type BrightcoveVideoObject = {
  name: string;
  thumbnail: string;
  duration: number;
};

export default function BrightcoveVideoCarousel({
  playlistId = 4829147999001,
  account = 4802324430001,
  player = brightcovePlaylistPlayerId, // brightcovePlayerId to be update,
  additionalClasses = "",
}: BrightcoveVideoCarouselProps) {
  const [videoArray, setVideoArray] = useState<Array<BrightcoveVideoObject>>(
    [],
  );

  const [currentVideo, setCurrentVideo] = useState<number>(0);

  const { isClientSideMounted } = useSnapshot(globalState);

  const sliderRef = { slider: useRef<Slider>(null) };
  const brightCovePlayerRef = useRef(null);
  //success method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = (params: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const player = params.ref.player_;
    player.playlist.autoadvance(0);
    // console.log(player, "BRIGHTCOVE PLAYER");
    // load playlist
    player.catalog.getPlaylist(playlistId, (error: object, getPlaylist: []) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (getPlaylist && getPlaylist.length > 0) {
          setVideoArray(getPlaylist);
        } else {
          getPlaylist = [];
          setVideoArray(getPlaylist);
        }

        // console.log(getPlaylist, "playlist");
      } catch (err) {
        console.log("Error in getting the Playlist:", error);
      }
    });

    player.on("playlistitem", () => {
      const num = player.playlist.currentItem() as number;
      sliderRef.slider.current?.slickGoTo(num);
      // console.log(player.playlist.currentItem(), "playlist");
    });
  };
  const settings: Settings = {
    className: "bc-playlist",
    prevArrow: <PreArrw />,
    nextArrow: <NextArrw />,
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (_, next: number) => {
      setCurrentVideo(next);
      // console.log(next, "next");
      brightCovePlayerRef.current?.player?.playlist.currentItem(next);
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className={`brightcove-video-carousel layout-container ${additionalClasses} py-[16px]`}
      id="jumbotron-video-display"
    >
      <div className="brightcove-container" id="brightcove-video">
        {isClientSideMounted && (
          <ReactPlayerLoader
            ref={brightCovePlayerRef}
            accountId={account}
            playerId={player}
            playlistId={playlistId}
            attrs={{ className: "brightcove-video-main" }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, prettier/prettier
            onSuccess={(params: { ref: { player_: any } }) => {
              onSuccess(params);
            }}
            options={{
              muted: true,
              autoplay: true,
              playsinline: true,
            }}
          />
        )}
        <div className="brightcove-carousel">
          {isClientSideMounted && videoArray.length !== 0 ? (
            <Slider ref={sliderRef.slider} {...settings}>
              {videoArray.map((item, index: number) => {
                return (
                  <button
                    className={clsx("vjs-playlist-item")}
                    key={index}
                    onClick={() => {
                      // console.log(index, "myindex");
                      brightCovePlayerRef.current?.player?.playlist.currentItem(
                        index,
                      );
                      setCurrentVideo(index);
                      sliderRef.slider.current?.slickGoTo(index);
                    }}
                  >
                    <div className="w-3/2 relative rounded-[4px]">
                      <OptimisedImage
                        rootClassName={clsx(
                          "vjs-playlist-thumbnail h-full w-full overflow-hidden rounded-[4px] object-cover object-center",
                          {
                            "border-4 border-divider-secondary":
                              currentVideo == index,
                          },
                        )}
                        src={item.thumbnail}
                        alt="article images"
                        loading={"lazy"}
                      />
                      {/* <picture
                        className={clsx(
                          "vjs-playlist-thumbnail vjs-icon-play",
                          {
                            "border-4 border-divider-secondary":
                              currentVideo == index,
                          },
                        )}
                      >
                        <img
                          alt={item.name}
                          src={item.thumbnail}
                          className={clsx("w-full")}
                        />
                      </picture> */}
                      <time className="vjs-playlist-duration">
                        {item.duration && getDuration(item.duration)}
                      </time>
                      <ArticleTypeIcon itemTypeProps={"video"} />
                    </div>

                    <div className="min-h-[54px] pt-[16px]">
                      <p
                        className={clsx(
                          "clear-both line-clamp-2 text-left text-header4 text-link-headline-on-dark-default hover:text-link-headline-on-dark-hover",
                        )}
                      >
                        {item.name}
                      </p>
                    </div>
                  </button>
                );
              })}
            </Slider>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const PreArrw = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
  const { onClick } = props;
  return (
    <button
      // aria-label="previous"
      className="text-button1-label-inverse absolute left-[28px] top-[calc(50%-46px)] z-10  h-[30px] w-[30px] rounded-full bg-button-secondary-default hover:bg-button-secondary-hover lg:top-[27%] xl:top-[33%]"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      onClick={onClick}
    >
      <div className="mx-auto h-[20px] w-[20px] text-icon-on-dark-default">
        <ArrowPrev />
      </div>
    </button>
  );
};

const NextArrw = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
  const { onClick } = props;
  return (
    <button
      // aria-label="next"
      className="text-button1-label-inverse absolute right-[28px] top-[calc(50%-46px)] h-[30px] w-[30px] rounded-full bg-button-secondary-default hover:bg-button-secondary-hover md:right-[32px] lg:top-[27%] xl:top-[33%] "
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      onClick={onClick}
    >
      <div className="mx-auto h-[20px] w-[20px] text-icon-on-dark-default">
        <ArrowNext />
      </div>
    </button>
  );
};
