import { BFF_BASE_URL } from "@constants/bff";
import type { ArticlePreviewType } from "@typings/Data";
import axios from "axios";
import { useEffect, useState } from "react";

type ResponseType = {
  code: number;
  response:
    | {
        furtherReadingArticles: ArticlePreviewType[];
      }
    | undefined
    | null;
};

export function useFurtherReading({ documentId }: { documentId: string }) {
  const [data, setData] = useState<Array<ArticlePreviewType>>([]);

  const getInfo = async () => {
    if (!documentId) {
      return;
    }
    const requestPath = `${BFF_BASE_URL}/client/further-reading/${documentId}`;
    try {
      const res = await axios.get<ResponseType>(requestPath);
      setData(res.data.response?.furtherReadingArticles || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { furtherReadingArticles: data };
}
