import ArrowForward from "@assets/icons/arrow-forward-icon.svg?react";
import Link from "@components/Link/Link";
import clsx from "clsx";
import type { ReactElement, ReactNode } from "react";

export type CardProps = {
  /**
   * The root class name for the component.
   * @type {string}
   */
  rootClassName?: string;
  /**
   * The class name for the heading component.
   * @type {string}
   */
  headingClassName?: string;
  /**
   * The class name for the content component.
   * @type {string}
   */
  contentClassName?: string;
  /**
   * The heading to display in the card.
   * @type {string}
   */
  heading?: string;
  /**
   * The heading link to display in the card.
   * @type {string}
   */
  headingLink?: string;
  /**
   * The heading component to display in the right of card heading.
   * @type {ReactNode}
   * @default null
   */
  headingComponent?: ReactNode;
  /**
   * Whether to display the border at the bottom of the card heading.
   * @type {boolean}
   * @default false
   */
  isBorderless?: boolean;
  /**
   * The content to display inside the card
   */
  children: ReactNode;
  /**
   *
   */
  isBreakingNews?: boolean;
  /**
   * for homepage main topic banner wall
   */
  isMainTopic?: boolean;
  /**
   * for inverse white border line
   */
  isInverse?: boolean;
  /**
   * to hide link when there is no clickable header
   */
  isLink?: boolean;
  /**
   * referrer for link
   */
  referrer?: string | null;
  /**
   * to set padding for multicarousel
   */
  isMultiCarousel?: boolean;

  borderClassName?: string;
  headerClassName?: string;
};

/**
 * Card
 * A card displays a group of articles with different layout.
 * @param {CardProps} props
 * @returns {ReactElement}
 */
export default function Card({
  rootClassName = "",
  headingClassName = "",
  contentClassName = "",
  borderClassName = "",
  heading = "",
  isMultiCarousel = false,
  isLink = true,
  isInverse = false,
  isBreakingNews = false,
  isMainTopic = false,
  headingLink = "",
  headingComponent = null,
  isBorderless = false,
  referrer = null,
  children,
  headerClassName = "",
}: CardProps): ReactElement {
  return (
    <div className={clsx("w-full px-8 pt-[16px]", rootClassName)}>
      {isBorderless ? null : (
        <div
          className={clsx("card-border border-t pb-[8px]", borderClassName, {
            "border-divider-brand": !isMainTopic,
            "border-divider-highlight-primary": isMainTopic,
            "border-divider-primary": isInverse,
            "lg:mx-[24px]": isMultiCarousel,
          })}
        ></div>
      )}

      <div
        className={`card-header ${headerClassName} flex w-full items-center justify-between gap-[16px]`}
      >
        <h2
          className={clsx(
            "flex flex-row items-center justify-between whitespace-nowrap text-header2 text-emphasis",
            headingClassName,
            {
              "lg:mx-[24px]": isMultiCarousel,
            },
          )}
        >
          {isBreakingNews ? (
            <div className="mr-3 h-[1.6rem] w-[1.6rem] rounded-full bg-logo-brand-secondary">
              <div className="m-[4px] h-[8px] w-[8px] rounded-full border-[1px] border-white motion-safe:animate-ping"></div>
            </div>
          ) : null}

          {isLink ? (
            <Link
              className={clsx(
                "text-link-emphasis-default hover:text-link-emphasis-hover",
                {
                  "text-link-highlight-default hover:text-link-highlight-hover":
                    isMainTopic,
                  "text-link-on-dark-default hover:text-link-on-dark-hover":
                    isInverse,
                },
              )}
              to={headingLink}
              referrer={referrer}
              target="_self"
            >
              {heading}
            </Link>
          ) : (
            heading
          )}

          {isLink ? (
            <Link
              className={clsx(
                "ml-[8px] h-[24px] w-[24px] rounded-full px-2 text-white hover:text-white",
                "bg-button-primary-default hover:bg-button-primary-hover",
              )}
              to={headingLink}
              target={"_self"}
            >
              <ArrowForward />
            </Link>
          ) : null}
        </h2>
        {headingComponent}
      </div>

      {contentClassName !== "" ? (
        <div className={contentClassName}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
}
