/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import useGA from "@hooks/useGA";
import globalState from "@stores/global";
import userState from "@stores/user";
import { ReactElement, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

type GADataProps = {
  title?: string;
  pubdate?: string;
  articleid?: string;
  cue_articleid?: string;
  language?: string;
  at?: string;
  svc?: string;
  author?: string;
  level2?: string;
  level2_ga?: string;
  level2_local?: string;
  chapter1?: string;
  chapter1_en?: string;
  chapter2?: string;
  chapter2_en?: string;
  chapter3?: string;
  /** @deprecated no in use */
  story_path?: string;
  story_threads?: string;
  keyword?: string;
  contentcat?: "free" | "premium";
  contenttype?: "" | "listing" | "article" | "video" | undefined; // listing article and video(if has_video flag) is for articlepage
  pagination?: number;
  visitorcat?: 1 | 2 | undefined;
  termid?: string;
  product_flag?: string;
  user_keyword?: string;
  adblocker?: 0 | 1;
  cd_version?: "" | "zbsg2" | undefined;
  lotameid?: string;
  printcat?: string;
  abVariant?: string;
  permutive_id?: string;
  /** @deprecated no longer required */
  ac?: string;
  content_publication_utc?: string;
  content_last_updated_utc?: string;
  grapeshot?: string;
};

/**
 * GAData
 * Description: This component renders the _data layer_ for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const { user } = useSnapshot(userState);
  const {
    level2_gaDefault,
    level2_localDefault,
    chapter1Default,
    chapter1_enDefault,
    chapter2Default,
    chapter2_enDefault,
    chapter3Default,
    story_pathDefault,
    languageDefault,
  } = useGA();

  //https://www.zaobao.com.sg/<<level2>>/<<chapter1>>/<<chapter2>>/<<chapter3>>/<<story_path>>
  const {
    articleid = "",
    cue_articleid = "",
    at = "", // mysphw cookie
    svc = "", // svc cookie
    author = "", // Comma separated author name
    chapter1 = chapter1Default,
    contentcat = 1, // Paid (2) or free (1)
    contenttype = "", // Index/listing = "", article = 1 photo = 2 video = 3
    keyword = "", // Comma separated keyword names
    // if in article, level2 = main category parent or main category, main category is the first category
    // if breakingnews, level2 = breaking news
    // level2 = level2Default,
    level2_ga = level2_gaDefault,
    level2_local = level2_localDefault,
    chapter2 = chapter2Default,
    chapter3 = chapter3Default,
    pagination = 1,
    permutive_id = "5f876161-9740-4cc8-9b64-4585990b2690", // window.localStorage.getItem("permutive-id") || "",
    pubdate = "", // Post date of article otherwise ""
    story_threads = "", // Comma separated story thread name
    story_path = story_pathDefault,
    title = "", // ie I_am_the_article_title, Section_name_Index, Breaking_News_Index
    termid = "", // Section term id
    product_flag = "",
    user_keyword = "",
    cd_version = "zbsg2", // to determine if from pv is from zbsg1 or zbsg2, no value is zbsg1
    printcat = "",
    abVariant = "ZB-DEFAULT",
    chapter1_en = chapter1_enDefault, // chapter1_en 给permutive用的，逻辑不一样 用 level2 的 english name
    chapter2_en = chapter2_enDefault,
    language = languageDefault,
    content_publication_utc = props.content_publication_utc,
    content_last_updated_utc = props.content_last_updated_utc,
    grapeshot = "",
  } = props;

  useEffect(() => {
    if (typeof window !== "undefined") {
      globalState.isGAReady = true;
    }
  }, []);

  return (
    <Helmet
      prioritizeSeoTags
      htmlAttributes={{
        "data-react-helmet": true,
      }}
    >
      <script id="ga_data_layer">
        {`
          console.log("123321 GAData.tsx")
          var gs_channels = "${grapeshot}";
          var _data = {};
          _data = {
            "title"          : "${title}",
            "pubdate"        : "${pubdate}",
            "language"       : "${language}",
            "articleid"      : "${articleid}",
            "cue_articleid"  : "${cue_articleid}",
            "at"             : "${at || user?.mysphw || ""}",
            "svc"            : "${svc || user?.svc || ""}",
            "author"         : "${author}",
            "level2_ga"      : "${level2_ga}",
            "level2_local"   : "${level2_local}",
            "chapter1"       : "${chapter1}",
            "chapter1_en"    : "${chapter1_en}", 
            "chapter2"       : "${chapter2}",
            "chapter2_en"    : "${chapter2_en}",
            "chapter3"       : "${chapter3}",
            "story_threads"  : "${story_threads}",
            "story_path"  : "${story_path}",
            "contentcat"     : "${contentcat}",
            "contenttype"    : "${contenttype}",
            "keyword"        : "${keyword}",
            "pagination"     : "${pagination}",
            "permutive_id"   : "${
              typeof window !== "undefined"
                ? window.localStorage.getItem("permutive-id")
                : permutive_id || "5f876161-9740-4cc8-9b64-4585990b2690"
            }",
            "visitorcat"     : ${user?.service_type_value ?? 1},
            "termid"         : "${termid}",
            "product_flag"   : "${product_flag}",
            "user_keyword"   : "${user_keyword}",
            "cd_version"     : "${cd_version}",
            "printcat"       : "${printcat}",
            "abVariant"      : "${abVariant}",
            "content_publication_utc"     : "${content_publication_utc ? formatDateString(content_publication_utc) : ""}",
            "content_last_updated_utc"    : "${content_last_updated_utc ? formatDateString(content_last_updated_utc) : ""}",
          };

          var isArticlePage = false;
          if (typeof _data != "undefined" && typeof _data.articleid != "undefined" && _data.articleid != "") {
            isArticlePage = true;
          }

          window.getCookieByName = window.getCookieByName || function (name) {
            match = document.cookie.match(new RegExp(name + "=([^;]+)"));
            if (match) {
              return match[1];
            }
          };
          
          if (gs_channels) {
            // Ensure googletag is defined, it may load slower than our bundle
            window.googletag = window.googletag || { cmd: [] };
            window.googletag.cmd = window.googletag.cmd || [];

            googletag.cmd.push(function addGsChannels() {
              googletag.pubads().setTargeting("gs_channels", gs_channels);
            });

            window.gs_channels = gs_channels;
          }
          if (typeof getCookieByName === "function") {
            if(typeof window !== "undefined" && window.getCookieByName("visitorcat") != undefined && window.getCookieByName("mysphw") != undefined) {
              var visitorcat = window.getCookieByName("visitorcat");
              _data.visitorcat = visitorcat;
              _data.at = window.getCookieByName("mysphw");
            }
          }

          if(window.getCookieByName("visitorcat") != undefined && window.getCookieByName("mysphw") != undefined) {
            var visitorcat = window.getCookieByName("visitorcat");
            _data.visitorcat = visitorcat;
            _data.ac =  visitorcat;
            _data.at = window.getCookieByName("mysphw");
          }

          if(window.getCookieByName("svc") != undefined) {
            var svc = window.getCookieByName("svc");
            _data.svc = svc;
          }
            /**
           * Permutive sphl
           */
          if (typeof window !== "undefined") {
            var per_articleid = "";
            var per_chapter1 = "";
            var per_chapter2 = "";
            var per_chapter3 = "";
            var per_content_category = "";
            var per_content_type = "";
            var per_gsKeywords = [];
            var per_keyword = [];
            var per_pagination = 1;
            var per_printcat = "";
            var per_sph_bs = [];
            var per_sph_iab = [];
            var per_sph_seg = [];
            var per_story_threads = "";
            var per_visitor_category = "anonymous";
            var per_userid = "";
            var per_ptid = "";

            if (typeof _data != "undefined") {
              if (
                typeof per_getcookie("mysphw") != "undefined" &&
                per_getcookie("mysphw") != ""
              ) {
                per_userid = per_getcookie("mysphw");
              }
              if (
                typeof per_getcookie("mysph_lr") != "undefined" &&
                per_getcookie("mysph_lr") != ""
              ) {
                per_ptid = per_getcookie("mysph_lr");
              }
              if (typeof _data.articleid != "undefined" && _data.articleid != "") {
                per_articleid = _data.articleid;
              }
              if (typeof _data.chapter1_en != "undefined" && _data.chapter1_en != "") {
                per_chapter1 = _data.chapter1_en;
              }
              if (typeof _data.chapter2_en != "undefined" && _data.chapter2_en != "") {
                per_chapter2 = _data.chapter2_en;
              }
              if (typeof _data.chapter3 != "undefined" && _data.chapter3 != "") {
                per_chapter3 = _data.chapter3;
              }
              if (typeof _data.contentcat != "undefined" && _data.contentcat != "") {
                switch (_data.contentcat) {
                  case "1":
                    per_content_category = "free";
                    break;
                  case "2":
                    per_content_category = "premium";
                    break;
                  default:
                    per_content_category = "free";
                }
              }
              if (typeof _data.contenttype != "undefined" && _data.contenttype != "") {
                per_content_type = _data.contenttype;
              }
              if (typeof _data.keyword != "undefined" && _data.keyword != "") {
                per_keyword = _data.keyword.toLowerCase().split(",");
              }
              if (typeof _data.pagination != "undefined" && _data.pagination != "") {
                per_pagination = parseInt(_data.pagination);
              }
              if (typeof _data.printcat != "undefined" && _data.printcat != "") {
                per_printcat = _data.printcat;
              }
              if (typeof _data.sph_bs != "undefined" && _data.sph_bs != "") {
                per_sph_bs = _data.sph_bs.toLowerCase().split(",");
              }
              if (typeof _data.sph_iab != "undefined" && _data.sph_iab != "") {
                per_sph_iab = _data.sph_iab.toLowerCase().split(",");
              }
              if (
                typeof per_getcookie("sph_seg") != "undefined" &&
                per_getcookie("sph_seg") != ""
              ) {
                per_sph_seg = per_getcookie("sph_seg").toLowerCase().split(",");
              }
              if (
                typeof _data.story_threads != "undefined" &&
                _data.story_threads != ""
              ) {
                per_story_threads = _data.story_threads;
              }
              if (
                typeof per_getcookie("visitorcat") != "undefined" &&
                per_getcookie("visitorcat") != ""
              ) {
                switch (per_getcookie("visitorcat")) {
                  case "1":
                    per_visitor_category = "anonymous";
                    break;
                  case "2":
                    per_visitor_category = "subscriber";
                    break;
                  case "3":
                    per_visitor_category = "registered";
                    break;
                  case "4":
                    per_visitor_category = "sphanonymous";
                    break;
                  case "5":
                    per_visitor_category = "lite";
                    break;
                  case "6":
                    per_visitor_category = "freetrial";
                    break;
                  case "7":
                    per_visitor_category = "inapppurchase";
                    break;
                  case "8":
                    per_visitor_category = "corpmoejc";
                    break;
                  case "9":
                    per_visitor_category = "corpmoepoly";
                    break;
                  case "10":
                    per_visitor_category = "corpmoeite";
                    break;
                  case "12":
                    per_visitor_category = "verifiedreg";
                    break;
                  case "13":
                    per_visitor_category = "nonverifiedreg";
                    break;
                  default:
                    per_visitor_category = "anonymous";
                }
              }
            }
          
            if (typeof gs_channels != "undefined" && gs_channels != "") {
              per_gsKeywords = gs_channels.toLowerCase().split(",");
              console.log(123321, per_gsKeywords);
            }

            function per_getcookie(cname) {
              if (document.cookie.length > 0) {
                c_start = document.cookie.indexOf(cname + "=");
                if (c_start != -1) {
                  c_start = c_start + cname.length + 1;
                  c_end = document.cookie.indexOf(";", c_start);
                  if (c_end == -1) {
                    c_end = document.cookie.length;
                  }
                  return unescape(document.cookie.substring(c_start, c_end));
                }
              }
              return "";
            }

            var per_suid = per_getcookie("suid");

            !(function (e, o, n, i) {
              if (!e) {
                (e = e || {}), (window.permutive = e), (e.q = []);
                var t = function () {
                  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
                    /[018]/g,
                    function (e) {
                      return (
                        e ^
                        ((window.crypto || window.msCrypto).getRandomValues(
                          new Uint8Array(1),
                        )[0] &
                          (15 >> (e / 4)))
                      ).toString(16);
                    },
                  );
                };
                (e.config = i || {}),
                  (e.config.apiKey = o),
                  (e.config.workspaceId = n),
                  (e.config.environment = e.config.environment || "production"),
                  (window.crypto || window.msCrypto) && (e.config.viewId = t());
                for (
                  var g = [
                      "addon",
                      "identify",
                      "track",
                      "trigger",
                      "query",
                      "segment",
                      "segments",
                      "ready",
                      "on",
                      "once",
                      "user",
                      "consent",
                    ],
                    r = 0;
                  r < g.length;
                  r++
                ) {
                  var w = g[r];
                  e[w] = (function (o) {
                    return function () {
                      var n = Array.prototype.slice.call(arguments, 0);
                      e.q.push({ functionName: o, arguments: n });
                    };
                  })(w);
                }
              }
            })(
              window.permutive,
              "ab403253-b305-47fa-a31b-3efb2473166f",
              "5f876161-9740-4cc8-9b64-4585990b2690",
              {},
            );
            (window.googletag = window.googletag || {}),
              (window.googletag.cmd = window.googletag.cmd || []),
              window.googletag.cmd.push(function () {
                if (0 === window.googletag.pubads().getTargeting("permutive").length) {
                  // console.log("load permutive");
                  var e = window.localStorage.getItem("_pdfps");
                  window.googletag
                    .pubads()
                    .setTargeting("permutive", e ? JSON.parse(e) : []);
                  var o = window.localStorage.getItem("permutive-id");
                  window.googletag.pubads().setTargeting("puid", o ? o : "");
                  window.googletag.pubads().setTargeting("ptime", Date.now().toString());
                  window.permutive.config.viewId &&
                    window.googletag
                      .pubads()
                      .setTargeting("prmtvvid", window.permutive.config.viewId);
                  window.permutive.config.workspaceId &&
                    window.googletag
                      .pubads()
                      .setTargeting("prmtvwid", window.permutive.config.workspaceId);
                }
              });

            permutive.identify([
              {
                id: per_userid,
                tag: "userid",
                priority: 0,
              },
              {
                id: per_ptid,
                tag: "ptid",
                priority: 1,
              },
              {
                id: per_suid,
                tag: "suid",
                priority: 2,
              },
            ]);

            permutive.addon("web", {
              page: {
                articleid: per_articleid,
                chapter1: per_chapter1,
                chapter2: per_chapter2,
                chapter3: per_chapter3,
                contentcat: per_content_category,
                contenttype: per_content_type,
                gsKeywords: per_gsKeywords,
                keywords: per_keyword,
                pagination: per_pagination,
                printcat: per_printcat,
                sph_bs_list: per_sph_bs,
                sph_iab_list: per_sph_iab,
                sph_seg: per_sph_seg,
                story_threads: per_story_threads,
                visitorcat: per_visitor_category,
              },
            });
          }
        `}
      </script>
    </Helmet>
  );
}

const formatDateString = (dateStringToBeFormatted: string) => {
  return new Date(dateStringToBeFormatted).toISOString();
};
