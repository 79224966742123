import { vwoPreconnect, vwoSmartcode } from "./scripts/vwoCode";
import useLink from "@hooks/useLink";
import { useScript } from "@sphtech/web2-core/hooks";
import useScriptFunction from "@hooks/useScriptFunction";
import { permutiveScript, permutiveSphl } from "./scripts/permutive";

export default function HeadScriptProvider() {
  // VWO Smartcode
  useLink({
    id: "vwo-smartcode-preconnect",
    rel: "preconnect",
    href: vwoPreconnect,
  });
  useScriptFunction({
    id: "vwo-smartcode",
    value: vwoSmartcode,
  });
  // Edge Permutive App
  useScript({
    id: "edge-permutive-app",
    src: permutiveScript,
  });
  // Permutive SPHL
  // useScriptFunction({
  //   id: "permutive-sphl",
  //   value: permutiveSphl,
  // });
  return null;
}
