import ShareNodes from "@assets/icons/share_nodes.png";
import clsx from "clsx";
import { type ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export type AmpSocialShare = {
  rootClassName?: string;
};

export default function AmpSocialShare({
  rootClassName = "",
}: AmpSocialShare): ReactElement {
  const socials = ["facebook", "twitter", "whatsapp", "email"];
  const baseUrl = import.meta.env.VITE_DRUPAL_ENDPOINT;
  const location = useLocation();

  return (
    <>
      <Helmet>
        <script
          async={undefined}
          {...{ "custom-element": "amp-social-share" as string }}
          src="https://cdn.ampproject.org/v0/amp-social-share-0.1.js"
        ></script>
      </Helmet>

      <amp-accordion
        class={clsx("social-share h-[50px] w-[50px]", rootClassName)}
      >
        <section>
          <h4 className="h-[50px] w-[50px] border-0 bg-transparent p-[15px]">
            <amp-img
              alt="联合早报"
              src={ShareNodes}
              width="19"
              height="18"
              layout="fixed"
            ></amp-img>
          </h4>
          <div
            className={clsx(
              "social-list border-t-[1px] border-t-[--color-red-500] bg-white",
            )}
          >
            {socials.map((social) => (
              <amp-social-share
                key={social}
                type={social}
                width="50"
                height="40"
                class="bg-transparent brightness-[0.6] saturate-0"
                data-param-url={baseUrl + location.pathname + location.search}
                aria-label={`Share on ${social}`}
              ></amp-social-share>
            ))}
          </div>
        </section>
      </amp-accordion>
    </>
  );
}
