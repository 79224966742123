import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import useParams from "@hooks/useSafeParams";
import { getLayout } from "@pages/WebCategory/helper";
import { CustomContext } from "@sphtech/web2-core/ssr";
import { useRouteContext } from "@sphtech/web2-core/ssr";
import viewportState from "@stores/viewport";
import { SeoMetaInfo, WebCategoryLevel2ContextType } from "@typings/Data";
import { getBreadcrumbItems } from "@utils/navigation";
import { type ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

export default function WebCategoryPage(): ReactElement {
  const {
    context: {
      payload: { seoMetaInfo, tagName },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel2ContextType;
  }> = useRouteContext();

  useGoogleAdsTargeting({ targeting: { key: "page", value: "listing" } });
  const { isDesktopView, isInAppBrowser } = useSnapshot(viewportState);
  const { level1 = "", level2 = "", level3 = "", level4 = "" } = useParams();
  const Layout = getLayout({ level1, level2 });

  const sectionAdsName = [level1, level2, level3, level4]
    .filter(Boolean)
    .join("/");

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  const getChapter1 = () =>
    level2 && !(level3 || level4) ? tagName : undefined;

  const breadcrumbItems = getBreadcrumbItems({ level1, level2 });
  const sectionCustomTitle = breadcrumbItems.at(-1)?.label ?? "";

  const seoTitle = generateSEOTitle(seoMetaInfo, sectionCustomTitle);
  // console.log("seoTitle", seoTitle, sectionCustomTitle);

  let sectionChildrenTitles = "";
  const lastCrumbItem = breadcrumbItems[breadcrumbItems.length - 1];
  // console.log("lastCrumbItem", lastCrumbItem);

  // eslint-disable-next-line
  if (lastCrumbItem && lastCrumbItem.children) {
    // eslint-disable-next-line
    const children = lastCrumbItem.children;
    // eslint-disable-next-line
    if (children) {
      children.forEach((child, index) => {
        if (index === children.length - 1) {
          sectionChildrenTitles = sectionChildrenTitles + "和" + child.label;
        } else {
          sectionChildrenTitles = sectionChildrenTitles + child.label + ", ";
        }
      });
    }
  }
  // console.log("sectionChildrenTitles", sectionChildrenTitles);

  const seoDescription = generateSEODescription(
    seoMetaInfo,
    sectionCustomTitle,
    sectionChildrenTitles,
  );
  // console.log("seoDescription", seoDescription);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{seoTitle}</title>
      </Helmet>

      <MetaTags
        type="website"
        title={seoTitle}
        description={seoDescription}
        slug={"/" + [level1, level2, level3, level4].filter(Boolean).join("/")}
      />

      <GAData
        level2_ga={level1}
        title={seoTitle}
        adblocker={getAdBlocker()}
        chapter1={getChapter1()}
      />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="abm-google-ad masthead-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <Layout />

      {isInAppBrowser ? null : <Footer />}
      {!isInAppBrowser ? (
        <CatfishGoogleAd slot={GoogleAdsSlotFactory.catfish(sectionAdsName)} />
      ) : null}
    </>
  );
}

const generateSEOTitle = (
  seoMetaInfo: SeoMetaInfo | undefined,
  sectionCustomTitle: string,
): string => {
  return seoMetaInfo?.seoTitle || `${sectionCustomTitle} | 联合早报`;
};

const generateSEODescription = (
  seoMetaInfo: SeoMetaInfo | undefined,
  sectionCustomTitle: string,
  sectionChildrenTitles: string,
): string => {
  return (
    seoMetaInfo?.seoDescription ||
    `${sectionCustomTitle}新闻-通过zaobao.com.sg获取${sectionChildrenTitles || sectionCustomTitle}的最新新闻和热门新闻。`
  );
};
