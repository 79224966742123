import { proxy } from "valtio";

const languageState = proxy<{
  translationHandler: {
    convert: () => void;
    restore: () => void;
  } | null;
  isTraditionalChinese: boolean;
}>({
  translationHandler: null,
  isTraditionalChinese: false,
});

export default languageState;
