import FooterContactus from "@components/Footer/FooterComponents/FooterContactUs";
import FooterCopyright from "@components/Footer/FooterComponents/FooterCopyright";
import FooterFollowus from "@components/Footer/FooterComponents/FooterFollowUs";
import FooterNavigation from "@components/Footer/FooterComponents/FooterNavigation";
import FooterSubscribe from "@components/Footer/FooterComponents/FootSubscribe";
import clsx from "clsx";
import { type ReactElement } from "react";
export default function Footer(): ReactElement {
  return (
    <footer
      className={clsx(
        "text-content-inactive relative bg-[--color-grey-600] py-[50px] text-label2",
      )}
    >
      <div className="footer-main layout-container">
        <div className="row">
          <div className="w-full px-8 md:block lg:basis-3/12">
            <FooterSubscribe />
          </div>
          <div className="flex w-9/12 basis-full px-8 md:flex-wrap lg:basis-9/12">
            <div
              className={clsx(
                "mb-[30px] hidden flex-wrap",
                "md:basis-full",
                "lg:mb-0 lg:flex",
                "xl:basis-9/12 xxl:basis-8/12",
              )}
            >
              <FooterNavigation />
            </div>
            <div
              className={clsx(
                "lg:px-[15px]",
                "md:w-auto md:max-w-full md:shrink-0 md:grow-0 md:basis-auto",
                "xl:basis-3/12",
                "xxl:basis-4/12",
              )}
            >
              <FooterContactus />
              <FooterFollowus />
            </div>
          </div>
        </div>
      </div>
      <div className="layout-container footer-sub">
        <div className="mb-[32px] mt-[22px] h-[1px] w-full bg-[--color-grey-700] lg:mb-[40px] lg:mt-[20px]"></div>
        <div className="row">
          <FooterCopyright />
        </div>
      </div>
    </footer>
  );
}
