import { stringify } from "qs";

import { CaaSImageFilters } from "./OptimisedImage";

// CaaS Image API
// - https://sph.atlassian.net/wiki/spaces/PAPP/pages/1514865469/CaaS+how-tos#Image-optimisation-API
// - https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api
export const getFormattedCaaSImageUrl = (
  url?: string,
  filters?: CaaSImageFilters,
) => {
  if (!url) return "";
  if (!filters) return url;
  return `${url}?${stringify(filters)}`;
};
