import { useLanguageListener } from "@hooks/useChineseConverter";
import clsx from "clsx";
import {
  type ReactElement,
  type ReactNode,
  type SyntheticEvent,
  useEffect,
  useState,
} from "react";

export type PopoverProps = {
  rootClassName?: string;
  rootContentClassName?: string;
  /**
   * The placement of the popover
   */
  placement?: "bottomRight" | "bottomLeft";
  /**
   * The content to display inside the popover
   */
  content: ReactElement;
  /**
   * The content to display inside the popover
   */
  children: ReactNode;
};

/**
 * Popover
 * Description: A popover component that can be used to display content
 * @param {PopoverProps} props
 * @returns {ReactElement}
 */
export default function Popover({
  rootClassName,
  rootContentClassName,
  placement = "bottomLeft",
  children,
  content,
}: PopoverProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={clsx(
        rootClassName,
        "relative flex flex-row items-center text-base",
      )}
    >
      <div
        role="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setIsOpen(!isOpen);
          }
        }}
        tabIndex={-1}
        aria-label="popover trigger"
        aria-roledescription="popover trigger"
        className="cursor-pointer"
      >
        {children}
      </div>
      {isOpen ? (
        <PopoverContent
          setIsOpen={setIsOpen}
          content={content}
          placement={placement}
          rootContentClassName={rootContentClassName}
        />
      ) : null}
    </div>
  );
}

function PopoverContent({
  placement,
  rootContentClassName,
  content,
  setIsOpen,
}: Partial<PopoverProps> & {
  setIsOpen: (isOpen: boolean) => void;
}) {
  useLanguageListener();

  const closePopover = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("click", closePopover);
    }, 0);

    return () => {
      document.removeEventListener("click", closePopover);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        rootContentClassName,
        "absolute top-full z-50 cursor-text select-text",
        {
          "right-0": placement === "bottomRight",
          "left-0": placement === "bottomLeft",
        },
      )}
      aria-label="popover content"
      aria-roledescription="popover"
      role="presentation"
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {content}
    </div>
  );
}
