import SearchIcon from "@assets/icons/search-gray.svg?react";
import Button from "@components/Button/Button";
import clsx from "clsx";
import { useState } from "react";

export type SearchInputProps = {
  rootClassName?: string;
  fontClassName?: string;
  iconPosition?: "left" | "right";
  showButton?: boolean;
  onSubmit: () => void;
};

export default function SearchInput({
  rootClassName = "",
  fontClassName = "",
  iconPosition = "left",
  showButton = true,
  onSubmit,
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleSubmitSearch = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (!searchValue) {
      setSearchErrorMessage("请输入关键词");
      return;
    }

    // Prevent default actions or allowing them to bubble up
    e.preventDefault();
    e.stopPropagation();

    window.open(`/search/site/${encodeURIComponent(searchValue)}`, "_self");

    setSearchValue("");
    setIsFocused(false);
    onSubmit();
  };

  return (
    <div
      className={clsx(
        "w-full rounded-[4px] bg-secondary p-8 text-base",
        rootClassName,
      )}
    >
      <div className="flex flex-row items-start">
        <div
          className={clsx("relative flex flex-1 flex-col", {
            "mr-[15px]": showButton,
          })}
        >
          <button
            className={clsx(
              "absolute left-[16px] top-[8px] transition-[filter] duration-300",
              {
                "left-auto right-[15px]": iconPosition === "right",
                "max:lg:filter-brand": isFocused,
              },
            )}
            onClick={handleSubmitSearch}
          >
            <div
              className={clsx(
                "h-[24px] w-[24px] p-[2px] text-icon-secondary-default",
                {
                  "!text-icon-alert-default": searchErrorMessage !== "",
                },
              )}
            >
              <SearchIcon />
            </div>
          </button>
          <input
            className={clsx("web2-modal-input", fontClassName, {
              "pl-[48px]": iconPosition === "left",
              "pr-[30px]": iconPosition === "right",
              "border-input-border-error placeholder-input-text-error":
                searchErrorMessage !== "",
            })}
            type="search"
            value={searchValue}
            placeholder="输入搜索词"
            name="keys"
            id="zbSearchKeyword"
            data-testid="zbSearchKeyword"
            onBlur={() => {
              setIsFocused(false);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSubmitSearch(event);
              }
            }}
            onChange={(event) => {
              if (searchErrorMessage) {
                setSearchErrorMessage("");
              }
              setSearchValue(event.target.value);
            }}
          />
          <div className="pb-[2px] pl-[16px] text-label5 text-input-text-error">
            {searchErrorMessage}
          </div>
        </div>
        <Button
          rootClassName={clsx(
            "h-[30px] justify-center bg-transparent !px-[10px] py-8 text-button1 !text-button-ghost-default hover:bg-transparent",
            {
              hidden: !showButton,
            },
          )}
          onClick={handleSubmitSearch}
        >
          搜索
        </Button>
      </div>
    </div>
  );
}
