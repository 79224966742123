import PlayIconRound from "@assets/icons/icon-play-solid-round-corners.svg";
import InteractiveIcon from "@assets/icons/interactive.svg?react";
import PodcastIcon from "@assets/icons/podcast.svg?react";
import { Fragment, ReactElement } from "react";

const ArticleTypeIcon = ({
  itemTypeProps,
}: {
  itemTypeProps?: string;
}): ReactElement => {
  return (
    <Fragment>
      {itemTypeProps !== "article" ? (
        <div
          data-type={itemTypeProps}
          className="absolute left-0 top-0 flex aspect-[3/2] h-full w-full items-center justify-center"
        >
          {itemTypeProps === "video" ? (
            <div className="h-[40px] w-[40px] rounded-full bg-black/40">
              <img
                className="mx-auto mt-[8px] !h-[24px] !w-[24px]"
                src={PlayIconRound}
                alt="item-type"
              />
            </div>
          ) : itemTypeProps === "podcast" ? (
            <div className=" flex h-[40px] w-[40px] items-center justify-center rounded-full bg-overlay-secondary text-icon-on-dark-default">
              <PodcastIcon />
            </div>
          ) : itemTypeProps === "interactive" ? (
            <div className=" flex h-[40px] w-[40px] items-center justify-center rounded-full bg-overlay-secondary text-icon-on-dark-default">
              <InteractiveIcon />
            </div>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );
};

export default ArticleTypeIcon;
