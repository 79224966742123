import Dropdown from "@components/Dropdown/Dropdown";
import { headerExtraNavigation } from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement } from "react";

export type ExtraNavigationItemsProp = {
  rootClassName?: string;
  navigationItemClassName?: string;
};

export default function ExtraNavigationItems({
  rootClassName = "",
  navigationItemClassName = "",
}: ExtraNavigationItemsProp): ReactElement {
  return (
    <nav
      className={clsx(
        "flex flex-row justify-between lg:space-x-[30px]",
        rootClassName,
      )}
    >
      {headerExtraNavigation.map((menuItem) => {
        return (
          <Dropdown
            key={menuItem.label}
            rootClassName={clsx("text-secondary", navigationItemClassName)}
            menuItem={menuItem}
          />
        );
      })}
    </nav>
  );
}
