import Ticker from "@components/Ticker/Ticker";
import { useNewstickerFromDrupal } from "@hooks/ClientSideFromDrupal/useNewstickerFromDrupal";

export default function HomepageNewsticker() {
  const { newsticker } = useNewstickerFromDrupal();
  const shouldRender =
    newsticker &&
    Object.keys(newsticker).length !== 0 &&
    "articles" in newsticker; // newsticker && Object.keys(newsticker).length !== 0;

  return (
    <div>
      {shouldRender ? (
        <section id="newsticker" className="py-[16px]">
          <div className="layout-container">
            <div className="flex w-full flex-wrap">
              <Ticker
                termUrl={newsticker.termUrl}
                termName={newsticker.termName}
                articles={newsticker.articles}
              />
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}
