export default function loadScriptContent(id: string, value: string) {
  if (document.getElementById(id)) {
    return;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = value;
  script.id = id;
  document.body.appendChild(script);
}
