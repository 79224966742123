import { BFF_BASE_URL } from "@constants/bff";
import type { ArticlePreviewType } from "@typings/Data";
import axios from "axios";
import { useEffect, useState } from "react";

function useTickerArticles() {
  const [tickerArticles, setTickerArticles] = useState<ArticlePreviewType[]>(
    [],
  );

  useEffect(() => {
    axios
      .get(`${BFF_BASE_URL}/client/realtime-articles`)
      .then((res) => {
        setTickerArticles(
          res.data.response.realtimeArticles as ArticlePreviewType[],
        );
      })
      .catch((err) => {
        console.log("Error Fetching Ticker Articles: ", err);
      });
  }, []);

  return { tickerArticles };
}

export { useTickerArticles };
