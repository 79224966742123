import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import OptimisedImage, {
  CaaSImageFilters,
} from "@components/OptimisedImage/OptimisedImage";
import Timestamp from "@components/Timestamp/Timestamp";
import { ArticlePreviewType } from "@typings/Data";
// import clsx from "clsx";
import { ReactElement } from "react";

const VerticalArticleCard = ({
  rootClassName = "",
  hideTimestamp = false,
  article,
  loading = "lazy",
  imageOptimise = {},
}: {
  rootClassName?: string;
  hideTimestamp?: boolean;
  article: ArticlePreviewType;
  loading?: "lazy" | "eager";
  imageOptimise?: Record<string, CaaSImageFilters>;
}): ReactElement => {
  // console.log(level1 ? true : false);
  return (
    <div
      className={`card vertical-article-card ${rootClassName}`}
      data-testid={`test-article-card-${article.id}`}
    >
      <div className="card-content">
        <Link
          to={article.href}
          className={"content-image"}
          aria-label={article.title}
        >
          <OptimisedImage
            rootClassName="relative aspect-[3/2] w-full overflow-hidden rounded-[4px] object-cover object-center lg:h-full"
            src={article.thumbnail}
            alt="article images"
            loading={loading}
            pictureRootClassName="relative aspect-[3/2] flex"
            suffix={<ArticleTypeIcon itemTypeProps={article.item_type} />}
            filters={imageOptimise} // to set image custom image optimise
          />
        </Link>

        <div className="content-header">
          <Link to={article.href} aria-label={article.title}>
            <h2>{article.title}</h2>
          </Link>

          {hideTimestamp ? null : (
            <div className="timestamp mt-[4px] text-label4">
              <Timestamp parsable={article.timestamp} format="relative" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerticalArticleCard;
