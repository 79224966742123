import VerticalArticleList from "@components/ArticleCardList/VerticalArticleList/VerticalArticleList";
import BrightcoveVideoCarousel from "@components/BrightcoveVideoCarousel/BrightcoveVideoCarousel";
import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import Level2Template from "@components/Templates/Level2Template";
import { useBrightcovePlaylistFromDrupal } from "@hooks/ClientSideFromDrupal/useBrightcovePlaylistFromDrupal";
import useParams from "@hooks/useSafeParams";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { WebCategoryLevel2ContextType } from "@typings/Data";
import { Fragment, type ReactNode } from "react";
export default function Level2WebCategory() {
  const {
    context: {
      payload: { articles = [], tagName },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel2ContextType;
  }> = useRouteContext();

  const { level1 = "", level2 = "" } = useParams();
  const levelKey = [level1, level2].filter(Boolean).join("_");

  const { brightcovePlaylist } = useBrightcovePlaylistFromDrupal({
    level1: level1,
    level2: level2,
  });
  const playlistId = brightcovePlaylist[0]?.playlistId;

  function renderFullWidthBanner() {
    return <HomepageFullWidthBanner additionalClasses="!mb-0" />;
  }

  const renderSectionNavigation: () => ReactNode = () => {
    return <SectionNavigation level={levelKey} tagName={tagName} />;
  };

  const renderPageContent: () => ReactNode = () => {
    return (
      <Fragment>
        {playlistId && playlistId !== "" ? (
          <BrightcoveVideoCarousel playlistId={playlistId} />
        ) : null}
        <div className="level-2-page-content w-full">
          <VerticalArticleList
            articles={articles}
            rootClassName="card-listing"
          />
        </div>
      </Fragment>
    );
  };

  return (
    <Level2Template
      topSlots={[renderSectionNavigation, renderFullWidthBanner]}
      mainSlots={[renderPageContent]}
    />
  );
}
