import AuthorEmailIcon from "@assets/icons/author-email.svg?react";
import AuthorFallbackImage from "@assets/icons/author-fallback-image.svg?react";
import { AuthorProfileType } from "@typings/Data";

const AuthorCard = ({
  authorProfile,
}: {
  authorProfile: AuthorProfileType;
}) => {
  return (
    <div className="author-card flex w-full flex-col items-center border-b-[1px] border-divider-primary px-8 py-[16px] lg:flex-row lg:gap-[16px]">
      <div className="flex w-full items-center pb-[8px] lg:pb-0">
        <div className="author-headshot mr-[16px] flex h-[60px] min-h-[60px] w-[60px] min-w-[60px] items-center justify-center overflow-hidden rounded-full bg-tertiary">
          {authorProfile.headshotImage === "" ? (
            <AuthorFallbackImage />
          ) : (
            <img
              src={authorProfile.headshotImage + "?w=80&h=80&dpr=3"}
              alt="Author's Placholder Headshot"
            />
          )}
        </div>

        <div className="author-information flex flex-1 flex-col gap-[8px]">
          {authorProfile.name === "" ? null : (
            <h1 className="flex h-full items-center text-header2 font-medium text-brand">
              {authorProfile.name}
            </h1>
          )}

          {authorProfile.email === "" ? null : (
            <span className="flex gap-[8px]">
              <div
                id="email-icon"
                className="flex h-[24px] w-[24px] items-center justify-center text-icon-secondary-default"
              >
                <AuthorEmailIcon />
              </div>
              <a
                className="max-w-[calc(100%-40px)] overflow-hidden text-ellipsis border-b-[1px] border-link-in-component-primary text-body2 text-link-in-component-primary hover:text-link-in-component-primary md:max-w-96"
                href={`mailto:${authorProfile.email}`}
              >
                {authorProfile.email}
              </a>
            </span>
          )}
        </div>
      </div>

      {authorProfile.designation === "" ? null : (
        <div
          id="author-designation"
          className="w-full text-body2 text-secondary"
        >
          {authorProfile.designation}
        </div>
      )}
    </div>
  );
};

export default AuthorCard;
