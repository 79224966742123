import viewportState from "@stores/viewport";
import clsx from "clsx";
import { stringify } from "qs";
import { type ComponentProps, type ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnapshot } from "valtio";

export type LinkProps = ComponentProps<typeof RouterLink> & {
  className?: string;
  children: ReactNode;
  referrer?: string | null;
  to: string;
};

/**
 * @description A wrapper for react-router-dom's Link component
 * @param {string} className
 * @param {string} to
 * @param {ReactNode} children
 * @param {any} rest
 * @returns {ReactNode}
 */
export default function Link({
  className = "",
  children,
  to = "#",
  referrer = null,
  ...rest
}: LinkProps) {
  const { isMobileOrTabletDevice } = useSnapshot(viewportState);
  const target = isMobileOrTabletDevice ? "_self" : "_blank";
  const queryString = stringify({ ref: referrer }, { skipNulls: true });

  if (queryString) {
    to += `?${queryString}`;
  }

  return (
    <RouterLink
      reloadDocument
      className={clsx("cursor-pointer touch-auto", className)}
      to={to}
      aria-label={typeof children === "string" ? children : to}
      rel={""}
      target={target}
      {...rest}
    >
      {children}
    </RouterLink>
  );
}
