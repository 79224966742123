import { useParams } from "react-router-dom";

const useAmp = () => {
  const { level1, level2, level3, level4, level5, level6 } = useParams();

  const allPaths = [level1, level2, level3, level4, level5, level6].filter(
    Boolean,
  );

  return allPaths.includes("_amp");
};

export default useAmp;
