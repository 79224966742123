import { navigationDictionary } from "@constants/navigation";
import { Fragment, type ReactElement, useEffect, useState } from "react";
import { useLocation, useRouteError } from "react-router-dom";

export default function ErrorPage(): ReactElement {
  const error = useRouteError() as {
    message: string;
  };
  const { pathname } = useLocation();
  const [, level1 = ""] = pathname.split("/");
  const [navigatorInformation, setNavigatorInformation] = useState<{
    userAgent: string;
    platform: string;
    appVersion: string;
    vendor: string;
  }>(); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (
      (level1 in navigationDictionary || !level1) &&
      typeof window !== "undefined" &&
      import.meta.env.MODE !== "development"
    ) {
      // setTimeout(() => {
      //   window.location.href = pathname;
      // }, 500);
    }
  }, [pathname, level1]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setNavigatorInformation({
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        appVersion: window.navigator.appVersion,
        vendor: window.navigator.vendor,
      });
    }
  }, []);

  return (
    <Fragment>
      <div
        className="min-h-[500px] w-full"
        dangerouslySetInnerHTML={{
          __html: `
  <div class="dialog-off-canvas-main-canvas" data-off-canvas-main-canvas><div class="enclose"><div class="msg-box"><div class="container text-center"><a href="https://www.zaobao.com.sg/"><img src="/themes/custom/zbsg2020/images/logo.png" border="0" id="logo" width="113" /></a><h3>很抱歉，你所访问的页面已不存在了。</h3><p>如有疑问，请电邮<a href="mailto:zbonline@sph.com.sg" class="en">zbonline@sph.com.sg</a></p><p>你仍然可选择浏览<a href="https://www.zaobao.com.sg">首页</a>或以下栏目内容 :</p><div class="capsule"><ul class="nav-list"><li><a href="https://www.zaobao.com.sg/news">新闻</a></li><li><a href="https://www.zaobao.com.sg/lifestyle">生活</a></li><li><a href="https://www.zaobao.com.sg/entertainment">娱乐</a></li><li><a href="https://www.zaobao.com.sg/finance">财经</a></li><li><a href="https://www.zaobao.com.sg/news/sports">体育</a></li><li><a href="https://www.zaobao.com.sg/videos">视频</a></li><li><a href="https://www.zaobao.com.sg/podcast">播客</a></li></ul></div></div></div><hr><footer>新报业媒体有限公司版权所有（公司登记号：202120748H）</footer></div></div>
    `,
        }}
      ></div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      
        body,html{width:100%;height:100%;background-color:#fbebef;}
html{font-family:sans-serif;line-height:1.4;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}
body{margin:0;padding:0;height:100%;background:#fbebef ;color:#000;font-size:16px;font-family:Roboto,Noto Sans,Microsoft YaHei,Hiragino Sans GB,WenQuanYi Micro Hei,Heiti SC;overflow-x:hidden;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;position:relative;
background-image: url("/themes/custom/zbsg2020/images/circle-bg.svg");background-size:100% auto;background-repeat:no-repeat;background-position:0 0;}h3{font-size:1.4em;margin:1em 0;font-weight:600;font-variation-settings: "wght" 600;}
p{letter-spacing:0.1em;font-weight:normal;font-variation-settings: "wght" 400;display:block;}
hr{margin:2em auto;box-sizing:content-box;height:1px;overflow:visible;width:10%;background:#a50034;color:#a50034;border:none;}
a{background-color:transparent;border-style:none;text-decoration:none;color:#a50034;font-size:inherit;transition:all 0.35s ease-in-out;-webkit-transition:all 0.35s ease-in-out;-moz-transition:all 0.35s ease-in-out;-ms-transition:all 0.35s;}
a:active,a:hover{outline-width:0;outline:none;}
small{font-size:80%;}
img{border-style:none;}
.en{letter-spacing:0;}
.text-center{text-align:center;display:block;}#logo{width:160px;height:auto;margin:1em auto;text-align:center;display:block;}.row{width:100%;display:block;margin-bottom:0.6em;}
.enclose{margin:0;width:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.msg-box{position:relative;vertical-align:middle;padding:0;margin:1em auto;width:100%;display:block;color:#7d8488!important;}
.container{padding:2em 2.6em;border-radius:0.8em;width:60%;display:block;margin:auto;background:#fff;}
.note{font-size:0.9em;color:#666;}.social-media{margin:2em auto;text-align:center;}
.social-media a{margin:0.4em 0.1em;display:inline-block;}
.social-media img{height:44px;}
.badge-ios,.badge-android{height:34px!important;}#facebook .st0{fill:#4267B2;}
#facebook .st1{fill:#FFFFFF;}
#twitter .st0{fill:#1DA1F2;}
#twitter .st1{fill:#FFFFFF;}
#youtube .st0{fill:#FF0000;}
#youtube .st1{fill:#FFFFFF;}footer{width:100%;height:40px;left:0;bottom:0;font-size:14px;text-align:center;}.capsule,.nav-list{display:block;}
.nav-list li{display:inline-block;}
.capsule{font-size:0.9em;margin:2em auto;}
.nav-list,.nav-list li{margin:0;padding:0;list-style-type:none;}
.nav-list{margin:0 auto;text-align:center;}
.nav-list li{margin:3px 1px;text-align:center;box-shadow:0 0 50px rgba(0,0,0,0.04);white-space:nowrap;}
.nav-list li a{padding:0.5em 1.2em 0.4em 1.2em;display:block;border:1px solid #a50034;color:#a50034;border-radius:50px;}
.nav-list li:hover a{margin-top:0;background:#fff;color:#fff;background:#a50034;}@media (min-width:1100px){
.container{width:40%;}
}@media (min-width:200px) and (max-width:767px){
.capsule{width:100%;}
}
@media screen and (max-width:700px){
body{background-size:auto 100%;background-position:center;background-color:#FFDDE5;}
.enclose{width:90%;}
.social-media img{height:40px;}
.container{padding:1em 5%;width:90%;}
#page-503-sg .enclose{margin:0 auto;position:relative;top:auto;left:auto;transform:none;}
}
      `,
        }}
      />
      <div className="invisible fixed top-5 px-2 text-center text-2xl text-red-500">
        {error.message ? error.message.toString() : "Error"} <br />
        {navigatorInformation ? (
          <div>
            <div>{navigatorInformation.userAgent}</div>
            <div>{navigatorInformation.platform}</div>
            <div>{navigatorInformation.appVersion}</div>
            <div>{navigatorInformation.vendor}</div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}
