import StaticPageNavigation from "@components/StaticPageNavigation/StaticPageNavigation";

export default function Contribute() {
  return (
    <main className="my-4">
      <section>
        <div className="layout-container">
          <StaticPageNavigation />
          <div className="w-[80%] border-[1px] border-solid border-black">
            <p className="py-80">CONTRIBUTE PAGE</p>
          </div>
        </div>
      </section>
    </main>
  );
}
