import Link from "@components/Link/Link";
import fontState from "@stores/font";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";

export type AccordionProps = {
  id?: string;
  label?: string;
  link?: string;
  children?: React.ReactNode;
  rootClassName?: string;
  rootChildrenClassName?: string;
  isExpanded?: boolean;
  isMatchUrl?: boolean;
  isChildMatchUrl?: boolean;
  renderHeader?: () => React.ReactElement;
  onToggle?: (
    e: React.SyntheticEvent,
    accordionId: string,
    newIsExpand: boolean,
  ) => void;
};

export default function Accordion({
  label = "",
  link = "#",
  children = "",
  rootClassName,
  rootChildrenClassName,
  isExpanded = false,
  // isMatchUrl = false,
  // isChildMatchUrl = false,
  renderHeader,
  onToggle,
  id = "",
}: AccordionProps): React.ReactElement {
  const { fontSize } = useSnapshot(fontState);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef(null);
  const uid = `side-navigation-${id}`;

  const handleToggleAccordion = (e: React.SyntheticEvent) => {
    if (onToggle && children) {
      onToggle(e, id, !isExpanded);
    }
  };

  useEffect(() => {
    setChildrenHeight(
      (childrenRef.current as unknown as HTMLElement).scrollHeight,
    );
  }, []);

  return (
    <div
      className={clsx(
        rootClassName,
        "border-b border-divider-secondary hover:cursor-pointer",
      )}
      id={uid}
      data-testid={uid}
    >
      {renderHeader ? (
        renderHeader()
      ) : (
        <button
          onClick={handleToggleAccordion}
          className={clsx(
            "label-clickable relative flex w-full flex-row items-center justify-between truncate text-base leading-[42px] tracking-[2px] text-primary",
            fontSize,
            {
              "after:content-dropdown-triangle after:border-y-primary after:transition-transform after:duration-500":
                !!children,
              "after:[transform:rotate3d(1,0,0,180deg)]": isExpanded,
              // "font-semibold": isMatchUrl || isChildMatchUrl,
            },
          )}
        >
          <Link to={link}>{label}</Link>
        </button>
      )}
      <div
        ref={childrenRef}
        className={clsx(
          "box-border overflow-hidden bg-white px-[10px] transition-[height] duration-300",
          rootChildrenClassName,
        )}
        style={{ height: isExpanded ? childrenHeight : 0 }}
      >
        <div className="py-[9px]">{children}</div>
      </div>
    </div>
  );
}
