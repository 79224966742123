import Link from "@components/Link/Link";
import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";

const Ticker = ({
  termUrl,
  articles = [],
  termName,
  rootClassName, // article,
}: {
  termUrl?: string;
  rootClassName?: string;
  termName?: string;
  articles?: Array<{ title: string; url: string }>;
}): ReactElement => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0); // default active first item, from Array[1] to add is-active

  useEffect(() => {
    const interval = setInterval((): void => {
      setCurrentActiveIndex((c) => {
        if (c >= articles.length - 1) {
          return 0;
        }
        return c + 1;
      });
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [articles]);

  const triggerList = () => {
    setCurrentActiveIndex((c) => {
      if (c >= articles.length - 1) {
        return 0;
      }
      return c + 1;
    });
  };

  return (
    <div
      className={clsx(
        "relative block w-full items-center overflow-hidden rounded-[4px] bg-tertiary lg:flex",
        {
          rootClassName: rootClassName,
        },
      )}
      id="ticker"
    >
      <div className="inline-block w-auto bg-success-secondary px-8 py-2 text-left lg:block lg:py-4">
        {termUrl ? (
          <Link
            to={termUrl + "?ref=newsticker"}
            className="lg:border-separator inline-block lg:my-0 lg:flex"
          >
            <h3 className="text-label1 text-brand-secondary">{termName}</h3>
          </Link>
        ) : null}
      </div>
      <div className="max-w-full grow">
        {articles.length > 0 ? (
          <ul className="newsticker-link-list relative flex items-center overflow-hidden px-8 py-[8px] lg:h-[24px]  lg:py-[unset] lg:pl-8 lg:pr-[16px]">
            {articles.map((item, index) => {
              return (
                <li
                  key={item.url}
                  className={clsx("newsticker-link-item text-left", {
                    "is-active": index === currentActiveIndex,
                  })}
                >
                  {item.url && (
                    <Link
                      className="line-clamp-1 text-label2 text-link-headline-default hover:text-link-headline-hover"
                      to={item.url + "?ref=newsticker"}
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="pl-8">
            <h3 className="animate-pulse text-header4 text-alert">网路出错</h3>
          </div>
        )}
      </div>
      {articles.length > 1 ? (
        <div className="hidden max-w-full basis-auto pr-8 lg:block">
          <button
            onClick={() => {
              triggerList();
            }}
            className="whitespace-nowrap rounded-[100px] border-[1px] border-button-tertiary-default px-[8px] py-[4px] text-button3 text-button-tertiary-default hover:bg-button-tertiary-hover"
          >
            下一篇
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Ticker;
