import {
  navigationDictionary,
  type NavigationItem,
} from "@constants/navigation";

export function getBreadcrumbItems({
  level1 = "",
  level2 = "",
}): NavigationItem[] {
  if (!level1) {
    return [];
  }

  const items: NavigationItem[] = [navigationDictionary[level1]];

  if (level2) {
    items.push(
      navigationDictionary[[level1, level2].filter(Boolean).join("_")],
    );
  }

  return items;
}
