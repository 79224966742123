import TickerArticleCard from "@components/ArticleCard/TickerArticleCard/TickerArticleCard";
import { ArticlePreviewType } from "@typings/Data";

export default function TickerArticleList({
  articles,
}: {
  articles: Array<ArticlePreviewType>;
}) {
  return (
    <ul data-testid="block-latestnews" className="ticker-article-list">
      {articles.length
        ? articles.slice(0, 6).map((article) => {
            return (
              <li key={article.id} className="mb-[16px] last:mb-[8px]">
                <TickerArticleCard article={article} />
              </li>
            );
          })
        : null}
    </ul>
  );
}
