import FooterImage from "@assets/footer/footer-img.png";
import Link from "@components/Link/Link";
// import clsx from "clsx";
import { type ReactElement } from "react";

const FooterSubscribe = (): ReactElement => {
  return (
    <>
      <div className="mb-[3.2rem] pb-0 text-center lg:text-left">
        <div className="mb-[2.4rem] h-full w-full text-center lg:text-left">
          <img
            className="inline-block object-contain object-center"
            src={FooterImage}
            alt="footer-subscribe"
          />
        </div>
        <div className="footer-left-disc">
          <h2 className="mb-[4px] text-header1 text-white">成为联合早报VIP</h2>
          <p className="text-base text-primary-inverse">
            解锁全网内容、电子报及专享福利
          </p>
          <Link
            className="mt-[1.6rem] inline-block rounded-button-radius-full bg-control-brand-tertiary-default px-[24px] py-[14px] text-base font-medium leading-[1.6rem] text-white hover:text-white"
            to="https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&amp;utm_medium=sph-publication&amp;utm_source=zb&amp;utm_content=subscribebutton-footer"
            target="_blank"
          >
            立即订阅
          </Link>
        </div>
      </div>
      <div className="mb-[32px] block h-[1px] w-full bg-[--color-grey-700] lg:hidden"></div>
    </>
  );
};

export default FooterSubscribe;
