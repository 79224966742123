import Email from "@assets/icons/email-icon.svg?react";
import Epaper from "@assets/icons/epaper-icon.svg?react";
import Instgram from "@assets/icons/ig-icon.svg?react";
import XmrbLogo from "@assets/icons/logo-shinmin.svg?react";
import Phone from "@assets/icons/phone-icon.svg?react";
import Tiktok from "@assets/icons/tiktok-icon.svg?react";
import Wechat from "@assets/icons/wechat-icon.svg?react";
import Whatsapp from "@assets/icons/whatsapp-icon.svg?react";
import Link from "@components/Link/Link";
import {
  navigationDictionary,
  type NavigationItem,
} from "@constants/navigation";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useParams from "@hooks/useSafeParams";
import clsx from "clsx";
// import { useParams } from "react-router-dom";

const fbIcon = faFacebookF as IconProp;

/**
 * @description A component that renders a section navigation based on the level key
 * @param {string} level the key of the navigation item
 * @param {string} rootClassName the root class name
 * @returns {ReactNode}
 */

export default function SectionNavigation(props: {
  level: string;
  rootClassName?: string;
  tagName?: string; // for keywords page & phase 5 web category pages
}) {
  const { level1: level1FromRoute = "", level2: level2FromRoute } = useParams();
  const { level: levelFromProps, rootClassName = "", tagName = "" } = props;
  const levelKey = levelFromProps || level2FromRoute || level1FromRoute;
  const levelItemPlaceholder = {
    key: "",
    label: tagName || "",
    href: "",
    children: [],
  } as NavigationItem;

  const levelItem =
    levelKey in navigationDictionary
      ? navigationDictionary[levelKey]
      : levelItemPlaceholder;

  return (
    <div
      id="section-navigation"
      className={clsx(
        "w-full items-center gap-[16px]  pt-[16px]",
        rootClassName,
        { "block lg:flex": levelItem.key === "publication_xin-ming-ri-bao" },
        { flex: levelItem.key !== "publication_xin-ming-ri-bao" },
        {
          "pl-8 lg:px-8": levelItem.key !== "publication_xin-ming-ri-bao",
        },
      )}
    >
      <div
        className={clsx({
          "pb-[16px] pl-8 lg:pb-0":
            levelItem.key === "publication_xin-ming-ri-bao",
        })}
      >
        <h1 className="text-display1 text-brand">
          {level1FromRoute !== "keywords" ? levelItem.label : tagName}
        </h1>
      </div>
      <div
        className={clsx(
          "no-scrollbar w-full grow basis-0 overflow-x-scroll lg:overflow-auto",
        )}
      >
        {levelItem.key === "publication_xin-ming-ri-bao" ? (
          <ul className="block max-w-full flex-row flex-wrap items-center pl-8 sm:flex lg:justify-start lg:overflow-auto lg:pl-0">
            <li className="mb-[8px] pr-16 lg:mb-[unset]">
              <ul className="flex items-center">
                <li className="pr-16">
                  <XmrbLogo />
                </li>
                <li className="group">
                  <a
                    href="https://www.zaobao.com.sg/epapers"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center text-link-default hover:text-link-hover"
                  >
                    <Epaper />
                    <p className="mb-[4px] ml-[5px] whitespace-nowrap text-body1 text-secondary group-hover:text-link-hover">
                      电子报
                    </p>
                  </a>
                </li>
              </ul>
            </li>
            <li className="mb-[8px] lg:mb-[unset] lg:pr-16">
              <ul className="flex items-center space-x-16">
                <li>
                  <a
                    href="https://www.facebook.com/ShinMinDailyNewsXinMingRiBao"
                    target="_blank"
                    rel="noreferrer"
                    className="group flex h-[24px] w-[24px] items-center justify-center text-link-default"
                  >
                    <FontAwesomeIcon
                      icon={fbIcon}
                      className="text-base group-hover:text-link-hover"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/shinmindailynews"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link-default hover:text-link-hover"
                  >
                    <Instgram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@shinmindailynews"
                    className="h-[24px] w-[24px] text-link-default hover:text-link-hover"
                  >
                    <Tiktok />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:shinmin@sph.com.sg"
                    className="h-[24px] w-[24px] text-link-default hover:text-link-hover"
                  >
                    <Email />
                  </a>
                </li>
              </ul>
            </li>
            <li className="lg:ml-[unset]">
              <ul className="items-center sm:flex sm:space-x-16">
                <li className="group -ml-[4px] flex items-center lg:ml-[unset]">
                  <a
                    href="https://wa.me/91918727"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-[8px] h-[24px] w-[24px] text-link-default hover:text-link-hover"
                  >
                    <Whatsapp />
                  </a>
                  <a
                    href="tel:+659191 8727"
                    className="flex items-center text-link-default hover:text-link-hover"
                  >
                    <span className="mr-[8px] h-[24px] w-[24px]">
                      <Wechat />
                    </span>

                    <p className="mb-[4px] text-body1 text-secondary group-hover:text-link-hover">
                      9191&nbsp;8727
                    </p>
                  </a>
                </li>
                <li className="group">
                  <a
                    href="tel:18008227288"
                    className="-ml-[4px] flex items-center text-link-default hover:text-link-hover lg:ml-[unset]"
                  >
                    <span className="mr-[8px] h-[24px] w-[24px]">
                      <Phone />
                    </span>
                    <p className="mb-[4px] text-body1 text-secondary group-hover:text-link-hover">
                      1800&nbsp;822&nbsp;7288
                    </p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        ) : level1FromRoute && level1FromRoute !== "keywords" ? (
          <ul className="no-scrollbar flex max-w-full flex-row flex-nowrap overflow-x-scroll lg:flex-wrap lg:justify-start lg:overflow-auto">
            {levelItem.children
              ?.filter((it) => !it.displaySettings?.isHiddenInNavigation)
              .map((it) => it.key)
              .map((subLevelKey) => {
                // console.log("subLevelKey: ", subLevelKey);
                const subLevelItem =
                  subLevelKey in navigationDictionary
                    ? navigationDictionary[subLevelKey]
                    : levelItemPlaceholder;
                // console.log("subLevelItem: ", subLevelItem);
                if (subLevelItem.href) {
                  return (
                    <li key={subLevelItem.key} className="">
                      <Link
                        className={clsx(
                          "block whitespace-nowrap px-[12px] py-[8px] text-button2 text-secondary hover:bg-tertiary hover:text-button1 hover:text-brand",
                        )}
                        to={subLevelItem.href}
                        target="_self"
                      >
                        {subLevelItem.label}
                      </Link>
                    </li>
                  );
                }
              })}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
