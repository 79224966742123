import fallbackImage from "@assets/desktop-default.png";
import ArrowNext from "@assets/icons/arrow-forward-icon.svg?react";
import ArrowPrev from "@assets/icons/arrow-prev-icon.svg?react";
import Link from "@components/Link/Link";
import { useCampaignCarouselFromDrupal } from "@hooks/ClientSideFromDrupal/useCampaignCarouselFromDrupal";
import { useWindowSize } from "@hooks/useWindowSize";
import globalState from "@stores/global";
import type { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { useSnapshot } from "valtio";
export default function CampaignCarousel() {
  const { campaignCarouselArticles = [] } = useCampaignCarouselFromDrupal();
  const { isClientSideMounted } = useSnapshot(globalState);
  // console.log("CampaignCarousel: ", campaignCarouselArticles);
  const shouldRender =
    isClientSideMounted &&
    campaignCarouselArticles &&
    campaignCarouselArticles.length > 0 &&
    campaignCarouselArticles.length >= 3;

  return (
    <div>
      {shouldRender ? (
        <section className="homepage-campaign_carousel-section">
          <div className="layout-container">
            <div className="w-full border-t-[1px] border-divider-primary py-[16px]">
              <HomepageCarousel articles={campaignCarouselArticles} />
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}

function HomepageCarousel({
  rootClassName,
  articles = [],
}: {
  rootClassName?: string;
  articles: Array<ArticlePreviewType>;
}) {
  const { width } = useWindowSize();
  const { isClientSideMounted } = useSnapshot(globalState);
  const homepageSlider = useRef<Slider>(null);
  const [listHeight, setListheight] = useState<number>();
  const settings: Settings = useMemo(() => {
    return {
      dots: false,
      speed: 500,
      autoplay: true,
      slidesToShow: 3,
      infinite: true,
      arrows: false,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
      ],
    };
  }, []);

  useEffect(() => {
    if (isClientSideMounted) {
      const slickDotsHeight = document.querySelector(".slick-dots");
      switch (true) {
        case width >= 576 && width < 992:
          slickDotsHeight
            ? setListheight(slickDotsHeight.clientHeight)
            : setListheight(0);
          break;
        case width < 576:
          slickDotsHeight
            ? setListheight(slickDotsHeight.clientHeight)
            : setListheight(0);
          break;
        default:
          setListheight(0);
      }
    }
  }, [width, listHeight, isClientSideMounted]);
  return (
    <Fragment>
      {articles.length >= 3 ? (
        <div className="homepage-carousel relative">
          <button
            aria-label="previous"
            className={clsx(
              "absolute z-[1] ml-[16px] h-[30px] w-[30px] rounded-[50%] bg-button-secondary-default hover:bg-button-secondary-hover lg:top-[calc(50%-15px)]",
              {
                "top-[calc(50%-20px)]": listHeight == 15 || listHeight == 0,
                "top-[calc(50%-30px)]": listHeight == 30,
              },
            )}
            onClick={() => homepageSlider.current?.slickPrev()}
          >
            <div className="mx-auto h-[20px] w-[20px]  text-white">
              <ArrowPrev />
            </div>
          </button>
          <button
            aria-label="next"
            className={clsx(
              "absolute right-0 z-[1] mr-[16px] h-[30px] w-[30px] rounded-[50%] bg-button-secondary-default hover:bg-button-secondary-hover lg:top-[calc(50%-15px)]",
              {
                "top-[calc(50%-20px)] ": listHeight == 15 || listHeight == 0,
                "top-[calc(50%-30px)] ": listHeight == 30,
              },
            )}
            onClick={() => homepageSlider.current?.slickNext()}
          >
            <div className="mx-auto h-[20px] w-[20px] text-white">
              <ArrowNext />
            </div>
          </button>
          <Slider
            ref={homepageSlider}
            {...settings}
            className={`section-carousel ${rootClassName}`}
          >
            {articles.map((article, index) => {
              const { id, image_uri, label, url } = article;
              return (
                <Link
                  to={url || "#"}
                  className={"section-banner"}
                  key={id || index}
                  target="_blank"
                >
                  <img
                    alt={label}
                    src={image_uri ? image_uri : fallbackImage}
                    loading={index > 0 ? "lazy" : "eager"}
                  />
                </Link>
              );
            })}
          </Slider>
        </div>
      ) : null}
    </Fragment>
  );
}
