import ArticleIcon from "@assets/icons/article.svg";
import BookmarkIcon from "@assets/icons/bookmark.svg";
import FilterIcon from "@assets/icons/filter.svg";
import GiftIcon from "@assets/icons/gift.svg";
import NewsletterIcon from "@assets/icons/newsletter.svg";
import Button from "@components/Button/Button";
import SubscriptionPrompt from "@components/SubscriptionPrompt/SubscriptionPrompt";
import clsx from "clsx";
import { type ReactElement } from "react";

export type NonLoggedInPopoverProps = {
  rootClassName?: string;
};

/**
 * NonLoggedInPopover
 * @see https://www.figma.com/file/dhLEXhWqoec8sUhgcbzgdC/100-Years-Zaobao_Iterations?node-id=601%3A8085&mode=dev
 * @returns {ReactElement}
 */
export default function NonLoggedInPopover({
  rootClassName = "",
}: NonLoggedInPopoverProps): ReactElement {
  const handleOpenMySPHLightbox = () => {
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openLogin();
    }
  };

  const handleOpenMySPHLightboxFree = () => {
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openSignUp();
    }
  };

  return (
    <div
      className={clsx(
        "shadow-around-light visible flex min-w-[300px] flex-col space-y-[16px] whitespace-nowrap bg-tertiary pb-[32px] text-left",
        "group-hover:visible",
        rootClassName,
      )}
    >
      <SubscriptionPrompt />

      <div className="text-center text-subheader2 text-primary">
        免费成为会员，享有更多功能
      </div>

      <Button
        rootClassName="!mx-auto !my-[24px]"
        size="large"
        onClick={() => {
          if (window._mySPHObj !== undefined) {
            window._mySPHObj.openLogin();
          }
        }}
      >
        <span
          role="button"
          tabIndex={-1}
          onClick={handleOpenMySPHLightbox}
          onKeyDown={handleOpenMySPHLightbox}
        >
          登录
        </span>
        /
        <span
          role="button"
          tabIndex={-1}
          onClick={handleOpenMySPHLightboxFree}
          onKeyDown={handleOpenMySPHLightboxFree}
        >
          免费注册
        </span>
      </Button>

      <div className="tw-col mx-auto !mt-0 max-w-[220px] space-y-[16px]">
        <ZaobaoGift />
        <ZaobaoBookmark />
        <ZaobaoContentFilter />
        <ZaobaoNewsletter />
        <ZaobaoFreeArticle />
      </div>
    </div>
  );
}

const featureClassNames = "content-primary flex flex-row items-center";

export function ZaobaoGift() {
  return (
    <div className={featureClassNames}>
      <div className="card-icon">
        <img width="20" height="20" src={GiftIcon} alt="gift" />
      </div>
      早报会员专享福利
    </div>
  );
}

export function ZaobaoBookmark() {
  return (
    <div className={featureClassNames}>
      <div className="card-icon">
        <img width="20" height="20" src={BookmarkIcon} alt="bookmark" />
      </div>
      一键收藏 (只限应用程序)
    </div>
  );
}

export function ZaobaoContentFilter() {
  return (
    <div className={featureClassNames}>
      <div className="card-icon">
        <img width="20" height="20" src={FilterIcon} alt="filter" />
      </div>
      自设内容栏目(只限应用程序)
    </div>
  );
}

export function ZaobaoNewsletter() {
  return (
    <div className={featureClassNames}>
      <div className="card-icon">
        <img width="20" height="20" src={NewsletterIcon} alt="newsletter" />
      </div>
      精选新闻电邮速递
    </div>
  );
}

export function ZaobaoFreeArticle() {
  return (
    <div className={featureClassNames}>
      <div className="card-icon">
        <img width="20" height="20" src={ArticleIcon} alt="article" />
      </div>
      阅读免费文章
    </div>
  );
}
