import { useEffect } from "react";

/**
 * @name useEmbedElementStyle
 * @description adjust embed-wrapper element style, since we cannot use :has and some structure related reason, difficult to set using pure CSS.
 */
export default function useEmbedElementStyle(renderBodyContent: string) {
  useEffect(() => {
    const embedIframeElements = document.querySelectorAll(
      ".bff-embed-wrapper > .embed-wrapper:not(.omny-wrapper)",
    );
    console.log(embedIframeElements, "embedIframeElements");
    for (const ele of embedIframeElements) {
      const childEle = ele.querySelector(
        ":scope > iframe:not(.instagram-media)",
      );
      // exclude instagram
      if (
        childEle &&
        !childEle.getAttribute("src")?.includes("instagram.com")
      ) {
        const embedWrapperClass = "pb-[56.25%]";
        ele.classList.add(embedWrapperClass);
        const iframeClass =
          "absolute top-0 bottom-0 left-0 right-0 w-full h-full";
        childEle.className = iframeClass;
      }
    }
  }, [renderBodyContent]);
}
