import Link from "@components/Link/Link";
import {
  navigationDictionary,
  type NavigationItem,
} from "@constants/navigation";
import useParams from "@hooks/useSafeParams";
import globalState from "@stores/global";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { type ReactElement, useEffect, useState } from "react";
import { useSnapshot } from "valtio";

export type DropdownProps = {
  menuItem: NavigationItem;
  rootClassName?: string;
  rootChildrenClassName?: string;
};

export default function Dropdown({
  menuItem,
  rootClassName = "",
  rootChildrenClassName = "",
}: DropdownProps): ReactElement {
  const { level1 = "", level2 = "" } = useParams();
  const sectionName = [level1, level2].filter(Boolean).join("_");
  const { isHeaderStuck } = useSnapshot(globalState);
  const { isTouchableDevice } = useSnapshot(viewportState);
  const { href, label, children, key } = menuItem;
  const [active, setActive] = useState<string>(sectionName);

  useEffect(() => {
    switch (true) {
      case sectionName.includes("realtime") ||
        (sectionName.includes("news") &&
          sectionName !== "news_sports" &&
          sectionName !== "videos_news" &&
          sectionName !== "livestream_cmgnewsroom" &&
          sectionName !== "livestream_news"):
        setActive("news");
        break;
      case sectionName.includes("video") || sectionName.includes("livestream"):
        setActive("videos");
        break;
      case sectionName.includes("finance"):
        setActive("finance");
        break;
      case sectionName.includes("forum"):
        setActive("forum");
        break;
      case sectionName.includes("entertainment"):
        setActive("entertainment");
        break;
      case (sectionName.includes("lifestyle") || sectionName == "horoscope") &&
        sectionName !== "lifestyle_health":
        setActive("lifestyle");
        break;
      case sectionName == key:
        setActive(sectionName);
        break;
      default:
      // setActive(sectionName);
    }
  }, [active, setActive, sectionName, level1, key]);
  return (
    <div
      className={clsx(
        "border-control-default-primary relative flex cursor-pointer flex-row items-center text-body2",
        "hover:border-control-brand-primary",
        {
          "hover:bg-secondary hover:text-link-in-component-brand-primary":
            !!children,
          group: !isTouchableDevice,
        },
        rootClassName,
      )}
    >
      {href !== undefined && (
        <Link
          className={clsx("relative tracking-[2px]", {
            "hover:text-inherit": !children && isHeaderStuck,
            "before:absolute before:bottom-[-11px] before:left-0 before:right-0 before:block before:h-[4px] before:bg-[#f4f5f7] before:content-['']":
              isHeaderStuck && key === active,
          })}
          to={href}
          target="_self"
        >
          {label}
        </Link>
      )}

      {children && (
        <div
          className={clsx(
            "before:content-dropdown-triangle",
            {
              group: isTouchableDevice,
            },
            rootChildrenClassName,
          )}
        >
          <DropdownMenu
            rootClassName={clsx(menuItem.key, "font-normal")}
            subMenuItems={children.map(({ key: childKey }) => {
              return navigationDictionary[childKey];
            })}
          />
        </div>
      )}
    </div>
  );
}

export type DropdownMenuProps = {
  rootClassName?: string;
  onClickMenuItem?: (navigationItem: NavigationItem) => void;
  subMenuItems: NavigationItem[];
};

export function DropdownMenu({
  rootClassName = "",
  subMenuItems,
  onClickMenuItem,
}: DropdownMenuProps): ReactElement {
  return (
    <div
      className={clsx(
        rootClassName,
        "separator-column-primary",
        "absolute left-0 top-[42px] z-10 hidden columns-2 bg-tertiary py-2.5 shadow-lg",
        "group-hover:block",
      )}
    >
      {subMenuItems.map((child) => {
        if (child.href !== undefined) {
          return (
            <Link
              key={child.label}
              className="whitespace-nowrap text-secondary"
              to={child.href}
              aria-label={child.label}
              target={"_self"}
              onClick={() => {
                if (onClickMenuItem) {
                  onClickMenuItem(child);
                }
              }}
            >
              <div className="px-[15px] py-[5px]">{child.label}</div>
            </Link>
          );
        }
      })}
    </div>
  );
}
