import AmpFooterContactus from "@components/AmpFooter/AmpFooterComponents/AmpFooterContactUs";
import AmpFooterCopyright from "@components/AmpFooter/AmpFooterComponents/AmpFooterCopyright";
import AmpFooterFollowus from "@components/AmpFooter/AmpFooterComponents/AmpFooterFollowUs";
import clsx from "clsx";
import { type ReactElement } from "react";
export default function AmpFooter(): ReactElement {
  return (
    <footer
      className={clsx(
        "amp-footer text-content-inactive relative bg-[--color-grey-600] py-[50px] text-label2 text-primary-inverse",
      )}
    >
      <div
        className={clsx(
          "footer-main layout-container",
          "mb-[30px] lg:max-w-[768px]",
        )}
      >
        <div className="row">
          <div className="basis-full px-[15px]">
            <div className="xxl:basis-6/12">
              <AmpFooterContactus />
              <AmpFooterFollowus />
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "footer-sub layout-container",
          "lg:max-w-[768px]",
          "before:absolute before:left-0 before:right-0 before:mx-auto before:h-[1px] before:w-[calc(100%-30px)] before:bg-[--color-grey-700] before:content-[''] sm:before:w-full",
        )}
      >
        <div className="row">
          <div className="mt-[30px] basis-full px-[15px]">
            <AmpFooterCopyright />
          </div>
        </div>
      </div>
    </footer>
  );
}
