import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import WebCategorySidebar, {
  checkIfListingPage,
} from "@components/Templates/WebCategorySidebar";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useCasPaywallArticle from "@hooks/useCasPaywallArticle";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import useParams from "@hooks/useSafeParams";
import { useScript } from "@sphtech/web2-core/hooks";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import viewportState from "@stores/viewport";
import { ArticleDetailContextType } from "@typings/Data";
import clsx from "clsx";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { useSnapshot } from "valtio";

import ArticlePageSEO from "./ArticlePageSEO";
import ArticleAppLinkMeta from "./components/ArticleAppLinkMeta";
import ContentCenter from "./layouts/ContentCenter";
import ContentLeft from "./layouts/ContentLeft";

export default function ArticlePage(): ReactElement {
  const {
    context: {
      payload: { article, grapeshot },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();
  const { level1, level2 } = useParams();

  const tags = article?.tags
    .map((target) => {
      return target.name;
    })
    .join(",");

  useGoogleAdsTargeting({
    targeting: [
      { key: "page", value: "article" },
      { key: "zbtags", value: `${tags}` },
      { key: "zbarticleid", value: `${article?.document_id}` },
    ],
  });
  const { isDesktopView, isInAppBrowser } = useSnapshot(viewportState);

  // meta tag
  const title = article?.metatags.title || ""; // article?.headline ? `${article.headline} | 联合早报` : "";
  const description = article?.metatags.description || "";
  const canonical_url = article?.metatags.canonical_url || "";
  const keywords = article?.metatags.keywords || "";

  const sectionAdsName = checkIfListingPage(level1, level2);

  /* Outbrain */
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  useCasPaywallArticle();

  return (
    <>
      <ArticlePageSEO article={article} />

      <ArticleAppLinkMeta urlPath={article?.url_path || ""} />

      <MetaTags
        type="website"
        title={title}
        description={description}
        keywords={keywords}
        slug={canonical_url}
        isArticlePage
        ogTitle={article?.og_tags.title}
        ogDescription={article?.og_tags.description}
        ogImageUrl={article?.og_tags.image}
      />

      <GAData
        contenttype={
          (article?.assets || []).includes("has_video") ? "video" : "article"
        }
        author={(article?.author || [])
          .map((i) => i.name)
          .filter(Boolean)
          .join("|")}
        articleid={article?.document_id}
        cue_articleid={article?.document_id}
        contentcat={article?.paid_mode == "paid" ? "premium" : "free"}
        story_threads={(article?.story_threads || [])
          .map((i) => i.name)
          .filter(Boolean)
          .join(",")}
        keyword={keywords}
        pubdate={
          article?.create_time
            ? dayjs
                .unix(article.create_time)
                .tz("Asia/Singapore")
                .format("YYYY-MM-DD HH:mm:ss")
            : ""
        }
        title={title}
        content_publication_utc={
          article && article.create_time
            ? new Date(article.create_time * 1000).toUTCString()
            : ""
        }
        content_last_updated_utc={
          article && article.update_time
            ? new Date(article.update_time * 1000).toUTCString()
            : ""
        }
        grapeshot={grapeshot}
      />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.IMU1}
          isRenderDOM={false}
          slot={GoogleAdsSlotFactory.imu1(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.MIDARTICLE}
          isRenderDOM={false}
          // TODO make sure all story path is not in the slot name
          slot={GoogleAdsSlotFactory.midarticlespecial(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}
      {/* <ArticleDefaultLayout /> */}
      <main className="articlePage relative pb-[40px] pt-[16px]">
        <div className="layout-container">
          <div className={clsx("row text-base lg:flex-nowrap")}>
            {/* left */}
            {isDesktopView ? (
              <ContentLeft className="flex w-full flex-col items-start px-[20px] lg:w-2/12" />
            ) : null}

            {/* center */}
            <ContentCenter className="box-border max-w-full flex-1 px-[20px] lg:w-6/12 lg:px-[20px] xl:w-7/12" />

            {/* right */}
            <div className="mt-12 box-border flex w-full flex-col items-start sm:mt-0 lg:w-4/12 xl:w-3/12">
              <WebCategorySidebar isArticlePage />
            </div>
          </div>
        </div>
      </main>
      {isInAppBrowser ? null : <Footer />}
      {!isInAppBrowser ? (
        <CatfishGoogleAd slot={GoogleAdsSlotFactory.catfish(sectionAdsName)} />
      ) : null}
    </>
  );
}
