import Link from "@components/Link/Link";
import viewportState from "@stores/viewport";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

export default function ReadMore() {
  const [faqUrl, setFaqUrl] = useState<string>("");
  const { isTouchableDevice } = useSnapshot(viewportState);

  useEffect(() => {
    const host = window.location.origin;
    setFaqUrl(`${host}/faq?tab=gift`);
  }, []);
  return (
    <Link
      to={faqUrl}
      className="text-link-headline-hover underline hover:text-link-headline-hover"
      target={isTouchableDevice ? "_self" : "_blank"}
    >
      了解更多
    </Link>
  );
}
