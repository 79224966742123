// helper function to get duration convert in milliseconds to min and sec
export const getDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  let remainingSeconds: number | string = Math.floor(seconds % 60);

  // Add leading zeros if the values are less than 10
  remainingSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  // Format the output
  return minutes + ":" + remainingSeconds;
};
