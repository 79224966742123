import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import Timestamp from "@components/Timestamp/Timestamp";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";
import { ReactElement } from "react";

const StandardArticleCard = ({
  id,
  rootClassName = "",
  hideTimestamp,
  article,
  loading = "lazy",
}: {
  id?: string;
  rootClassName?: string;
  hideTimestamp?: boolean;
  article: ArticlePreviewType;
  loading?: "lazy" | "eager";
}): ReactElement => {
  return (
    <Link
      to={article.href}
      className={`card standard-card ${rootClassName}`}
      data-testid={`standard-card-${article.id}`}
      aria-label={article.title}
      referrer={id}
    >
      <div
        className={clsx("card-content", {
          "desktop-content": !hideTimestamp,
          "mobile-content": article.thumbnail && !hideTimestamp,
        })}
      >
        <div
          className={clsx("content-header", {
            "w-[60%]": !!article.thumbnail,
          })}
        >
          <h2
            className={clsx("text-primary transition-all", {
              "line-clamp-3": rootClassName === "no-image" && article.thumbnail,
              "!line-clamp-2":
                rootClassName === "no-image" && !article.thumbnail,
            })}
          >
            {article.title}
          </h2>
          {hideTimestamp ? null : (
            <div className="mt-[4px] text-overline text-secondary">
              <Timestamp parsable={article.timestamp} format="relative" />
            </div>
          )}
        </div>
        {article.thumbnail ? (
          <OptimisedImage
            pictureRootClassName="relative aspect-[3/2] w-[40%] rounded-[4px] h-min"
            rootClassName="h-auto rounded-[4px] object-cover object-center"
            src={article.thumbnail}
            alt={article.title}
            loading={loading}
            suffix={<ArticleTypeIcon itemTypeProps={article.item_type} />}
          />
        ) : null}
      </div>
    </Link>
  );
};

export default StandardArticleCard;
