import { DropdownMenu } from "@components/Dropdown/Dropdown";
import Popover from "@components/Popover/Popover";
import { navigationDictionary } from "@constants/navigation";
import { type ReactElement } from "react";

export default function WebsiteDropdown(): ReactElement {
  return (
    <Popover
      placement="bottomRight"
      rootContentClassName="!top-[32px]"
      content={
        <DropdownMenu
          rootClassName="!relative !top-0 !block !columns-1 !text-left"
          subMenuItems={[
            navigationDictionary.site_global,
            navigationDictionary.site_cn,
          ]}
        />
      }
    >
      <div className="after:content-dropdown-triangle label-clickable relative flex flex-row items-center pl-[15px] text-base tracking-[2px] text-primary">
        {navigationDictionary.site_sg.label}
      </div>
    </Popover>
  );
}
