import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import { ReactElement, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

import PublicationDefaultLayout from "./PublicationDefaultLayout";

export default function PublicationPage(): ReactElement {
  useGoogleAdsTargeting({ targeting: { key: "page", value: "listing" } });
  const [tagName, setTagName] = useState("");

  const { level1 = "publication", level2 = "" } = useParams();

  const seoTitle = `${tagName} | 联合早报`;
  const seoDescription_default = `${tagName}新闻 - 《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。获取有关${tagName}的更多信息，尽在 zaobao.com.sg。`;
  const sectionAdsName = `publication`;
  const canonical_url = `/publication/${level2}`;

  const seoDescription_XinMingRiBao = `《新明日报》由著名武侠小说家金庸与斧标驱风油创办人梁润之创办。1967年3月18日创刊，以小市民生活为市场定位。获取新明日报的报道与电子报，尽在 zaobao.com.sg。`;
  const seoDescription_ZaoBaoXiaoYuan = `早报校园 - 《联合早报》是全球华文受众信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。获取早报校园的更多信息，尽在 zaobao.com.sg。`;

  const { isMobileView, isDesktopView, isInAppBrowser } =
    useSnapshot(viewportState);

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
      </Helmet>

      <MetaTags
        type="website"
        title={seoTitle}
        description={
          level2 === "xin-ming-ri-bao"
            ? seoDescription_XinMingRiBao
            : level2 === "zao-bao-xiao-yuan"
              ? seoDescription_ZaoBaoXiaoYuan
              : seoDescription_default
        }
        slug={canonical_url}
      ></MetaTags>

      <GAData level2_ga={level1} title={seoTitle} adblocker={getAdBlocker()} />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <PublicationDefaultLayout setTagName={setTagName} />

      {isInAppBrowser ? null : <Footer />}

      {isMobileView ? (
        isInAppBrowser ? null : (
          <CatfishGoogleAd
            slot={GoogleAdsSlotFactory.catfish(sectionAdsName)}
          />
        )
      ) : null}
    </>
  );
}
