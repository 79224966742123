import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";

interface IArticleAuthor {
  className?: string;
}
export default function ArticleAuthor({ className = "" }: IArticleAuthor) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const authors = article?.author || [];
  const storySource = (article?.story_source || []).slice(0, 1); // an article can have only one story source
  const custom_byline = article?.custom_byline;

  return (
    <div className={className}>
      <div>
        {isValidArray(authors) && (
          <>
            {/* 作者之间 间隔逗号 */}
            {authors.map((item, index) => {
              return (
                <span key={index} className="mb-[4px] mr-[4px]">
                  <Link
                    to={item.url_path || "#"}
                    className="!text-link-in-component-primary underline"
                    target="_self" // within the same tab
                  >
                    {item.name}
                  </Link>
                  {index !== authors.length - 1 && <span>,</span>}
                </span>
              );
            })}
          </>
        )}

        {custom_byline ? (
          <span className=" mb-[4px] mr-[4px] text-secondary">
            {custom_byline}
          </span>
        ) : null}

        {isValidArray(storySource) && (
          <>
            {(isValidArray(authors) || custom_byline) && (
              <span key="separator" className=" mb-[4px] mr-[4px]">
                /
              </span>
            )}

            <span key="story-source" className=" mb-[4px] mr-[4px]">
              {storySource.map((item, index) => {
                return (
                  <Link
                    to={item.urlPath || "#"}
                    key={index}
                    className=" !text-link-in-component-primary underline"
                    target="_self" // within the same tab
                  >
                    {item.name}
                  </Link>
                );
              })}
            </span>
          </>
        )}
      </div>
    </div>
  );
}
