export default function Faq() {
  return (
    <main className="my-4">
      <section>
        <div className="layout-container">
          <FAQSideNavigation />
          <div className="w-[80%] border-[1px] border-solid border-black">
            <p className="py-80">FAQ PAGE</p>
          </div>
        </div>
      </section>
    </main>
  );
}

const FAQSideNavigation = () => {
  return (
    <div className="w-[20%] border-[1px] border-solid border-black">
      <p>FAQ Page Side Navigation</p>
    </div>
  );
};
