import { BFF_BASE_URL } from "@constants/bff";
import axios from "axios";
import { useState } from "react";

const feedbacks = {
  SUCCESS: "订阅成功！",
  ERROR: "很抱歉，系统无法加载，请刷新页面再尝试一次。",
  EMAIL_ERROR: "请输入有效的电邮地址。",
  EXISTED_EMAIL_ERROR:
    "此电邮地址已有记录。如果您的收件箱没有收到新闻速递，请您查看垃圾邮件文件夹。",
};

const responseStatus = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export default function useEmarsys() {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const handleVerifyEmail = async (email: string) => {
    // check email regex
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(email)) {
      setStatus(responseStatus.FAILED);
      setMessage(feedbacks.EMAIL_ERROR);
      return;
    }

    try {
      const response = await axios.post<{
        status: string;
        message: string;
      }>(`${BFF_BASE_URL}/newsletter`, { email });
      const { status } = response.data;
      setStatus(status);

      console.log("newsletter status", status);

      if (status === responseStatus.SUCCESS) {
        setMessage(feedbacks.SUCCESS);
        return;
      }

      if (status === responseStatus.FAILED) {
        setMessage(feedbacks.ERROR);
        return;
      }

      if (!status) {
        setStatus(responseStatus.FAILED);
        setMessage(feedbacks.EXISTED_EMAIL_ERROR);
        return;
      }
    } catch (error) {
      setStatus(responseStatus.FAILED);
      setMessage(feedbacks.ERROR);
    }
  };

  return {
    status,
    message,
    handleVerifyEmail,
  };
}
