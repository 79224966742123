import { type ListingPageTemplateProps } from "@pages/WebCategory/helper";
import fontState from "@stores/font";
import clsx from "clsx";
import { Fragment, type ReactElement } from "react";
import { useSnapshot } from "valtio";

const LandingPageTemplate = ({
  topSlots = [],
  mainSlots = [],
  // sideSlots = [],
}: ListingPageTemplateProps): ReactElement => {
  const { fontSize } = useSnapshot(fontState);
  return (
    <main className="main layout-container">
      <div className={clsx("row text-base", fontSize)}>
        <div className="w-full">
          {topSlots.map((renderSlot, index) => {
            return <Fragment key={index}>{renderSlot()}</Fragment>;
          })}
        </div>
      </div>
      <div className="row">
        <div className="tw-col w-full">
          {mainSlots.map((renderSlot, index) => {
            return <Fragment key={index}>{renderSlot()}</Fragment>;
          })}
        </div>
        {/* <div className="tw-col lg:w-4/12 xl:w-3/12">
          {sideSlots.map((renderSlot, index) => {
            return <Fragment key={index}>{renderSlot()}</Fragment>;
          })}
          <WebCategorySidebar />
        </div> */}
      </div>
      <div id="spacer" className="h-[40px] w-full"></div>
    </main>
  );
};

export default LandingPageTemplate;
