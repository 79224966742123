import { CAS_EXIT_INTERVENTION_ID } from "@constants/cas";

export type WavePaywallProps = {
  paywallId?: string;
};

export default function WavePaywall({
  paywallId = CAS_EXIT_INTERVENTION_ID,
}: WavePaywallProps) {
  return <div id={`sph_cdp_${paywallId}`}></div>;
}
