import FontIcon from "@assets/icons//article/font-size.svg";
import useFontSize from "@hooks/useFontSize";
import { FONT_SIZE_OPTION } from "@stores/font";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { useState } from "react";
import { useSnapshot } from "valtio";

interface IArticleFont {
  className?: string;
}

type ArticleFontSizeOptionValue = "小" | "标准" | "中" | "大";
// map global font size to article font size
const fontSizeOptionsMap: Record<FONT_SIZE_OPTION, ArticleFontSizeOptionValue> =
  {
    [FONT_SIZE_OPTION.small]: "小",
    [FONT_SIZE_OPTION.standard]: "标准",
    [FONT_SIZE_OPTION.large]: "中",
    [FONT_SIZE_OPTION.xlarge]: "大",
  };

const fontSizeOptions = [...Object.keys(fontSizeOptionsMap)].map((item) => {
  return {
    label: fontSizeOptionsMap[item as FONT_SIZE_OPTION],
    value: item as FONT_SIZE_OPTION,
  };
});

export default function ArticleFont({ className = "" }: IArticleFont) {
  const [showFontSize, setShowFontSize] = useState(false);
  const { isDesktopView } = useSnapshot(viewportState);

  const { fontSize, setFontSize } = useFontSize();

  return (
    <div className={className}>
      <div className="flex w-full flex-row-reverse text-[16px]">
        <div className="relative ml-auto h-max w-max cursor-pointer bg-white">
          <button
            className=" flex w-[81px] items-center justify-center py-[8px]"
            onClick={() => {
              setShowFontSize((v) => !v);
            }}
          >
            <div
              className={clsx(
                "flex h-[40px] w-[40px] items-center justify-center rounded-full transition-all duration-200",
                isDesktopView && "hover:bg-button-ghost-hover",
              )}
            >
              <img
                src={FontIcon}
                alt="share icon"
                className="m-0 h-[24px] w-[24px]"
              />
            </div>
          </button>
          <div
            className={`box-border w-[81px] overflow-hidden rounded-[4px] border border-divider-primary transition-all duration-300
              ${showFontSize ? "max-h-[240px]" : "max-h-0 border-0"} 
              ${!isDesktopView ? "absolute left-0 top-full z-[43]" : "block"}
              `}
          >
            {fontSizeOptions.map((item, index) => {
              const active = item.value == fontSize;
              const isLastIndex = index == fontSizeOptions.length - 1;

              return (
                <button
                  className={clsx(
                    "flex w-full justify-center py-[8px] transition-all duration-200",
                    active
                      ? "bg-button-segmented-secondary-default font-medium text-emphasis"
                      : "bg-button-segmented-primary-default font-normal text-secondary",
                    !isLastIndex && "border-b",
                    isDesktopView &&
                      "hover:bg-button-segmented-secondary-hover",
                  )}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFontSize(item.value);
                  }}
                >
                  {item.label}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
