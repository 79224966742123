import Link from "@components/Link/Link";
import {
  headerNavigation,
  NavigationChildren,
  navigationDictionary,
} from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement } from "react";
const level1CategoryCls = clsx(
  "font-medium text-white",
  "hover:text-white",
  "hover:underline-offset-2",
  "hover:underline",
);
const level1CorporateCls = clsx(
  "font-medium text-white",
  "hover:text-white",
  "hover:underline-offset-2",
  "hover:underline",
  "text-base",
);
export default function FooterNavigation(): ReactElement {
  const columnCls = "shrink-0 grow-0 basis-auto lg:w-auto lg:max-w-full";
  const listCol = "px-6";
  return (
    <div className="flex w-full flex-wrap  justify-between xl:justify-start">
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem("news", level1CorporateCls)}
        </ul>
      </div>
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem("finance", level1CorporateCls)}
        </ul>
      </div>
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem("forum", level1CorporateCls)}
          {FooterNavigationItem("entertainment", level1CorporateCls)}
        </ul>
      </div>
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem("lifestyle", level1CorporateCls)}
        </ul>
      </div>
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem("lifestyle_health", level1CorporateCls)}
          {FooterNavigationItem("news_sports", level1CorporateCls)}
          {FooterNavigationItem("videos", level1CorporateCls)}
          {FooterNavigationItem("podcast", level1CorporateCls)}
          {FooterNavigationItem("interactive-graphics", level1CorporateCls)}
          {FooterNavigationItem("special", level1CorporateCls)}
        </ul>
      </div>
      <div className={columnCls}>
        <ul className={clsx(listCol)}>
          {FooterNavigationItem(
            "publication_xin-ming-ri-bao",
            level1CorporateCls,
          )}
          {FooterNavigationItem("zbclub", level1CorporateCls)}
          {FooterNavigationItem("epapers", level1CorporateCls)}
          {FooterNavigationItem("site_global", level1CorporateCls)}
          {FooterNavigationItem("site_cn", level1CorporateCls)}
        </ul>
      </div>
    </div>
  );
}
function FooterNavigationItem(key: string, className?: string): ReactElement {
  const menu = navigationDictionary[key];
  let subMenu = null;
  const children =
    headerNavigation.filter((it) => it.key === key).at(-1)?.children || [];
  // if (key === "news") {
  //   let childTmp = [children[children.length - 1]];
  //   for (let i = 0; i < children.length - 1; i++) {
  //     childTmp.push(children[i]);
  //   }
  //   children = childTmp;
  // }
  if (children.length > 0) {
    subMenu = (
      <li>
        <FooterNavigationSubItem subMenuItems={children} />
      </li>
    );
  }
  return (
    <>
      {menu.href && (
        <li className="mb-4">
          <Link
            className={className || level1CategoryCls}
            to={menu.href}
            target={"_self"}
          >
            {menu.label}
          </Link>
        </li>
      )}
      {subMenu}
    </>
  );
}
export type FooterNavigationSubItemProps = {
  onClickMenuItem?: (navigationItem: NavigationChildren) => void;
  subMenuItems: NavigationChildren[];
};
export function FooterNavigationSubItem({
  subMenuItems,
  onClickMenuItem,
}: FooterNavigationSubItemProps): ReactElement {
  return (
    <ul
      className={clsx("mb-8 pl-0", {
        "columns-2": subMenuItems.length > 10,
      })}
    >
      {subMenuItems.map((child, index) => (
        <li className="leading-[21px]" key={`submenu-${index}`}>
          <Link
            key={`subMenuItems_${child.key}`}
            className={clsx(
              "whitespace-nowrap text-label2 leading-4 text-primary-inverse",
              "hover:text-primary-inverse",
              "hover:underline-offset-2",
              "hover:underline",
            )}
            to={child.href || "/"}
            target={"_self"}
            onClick={() => {
              if (onClickMenuItem) {
                onClickMenuItem(child);
              }
            }}
          >
            {child.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
