import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";

interface IArticleReleated {
  className?: string;
}

export default function ArticleReleated({ className = "" }: IArticleReleated) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const articles = (article?.recommended_articles || []).slice(0, 2); // max 2 articles

  return (
    isValidArray(articles) && (
      <div className={className} id="related-articles">
        <div className="ml-auto box-border w-full max-w-[216px] rounded-[4px] bg-tertiary px-[20px] py-[16px] text-[18px]">
          <h2 className="my-0 mb-[16px] text-ellipsis font-medium text-emphasis">
            延伸阅读
          </h2>
          <div>
            {articles.map((item, index) => {
              return (
                <div key={index}>
                  <Link
                    to={item.href}
                    className=" !text-link-headline-default no-underline hover:!text-link-headline-hover"
                    target="_self" // within the same tab
                  >
                    {!!item.thumbnail && (
                      <div className=" relative">
                        <img
                          alt=""
                          src={item.thumbnail}
                          className=" my-0 mb-[16px] max-w-full rounded-[4px] object-contain"
                        />
                        {/* video postcast interactive */}
                        {!!item.item_type && (
                          <ArticleTypeIcon itemTypeProps={item.item_type} />
                        )}
                      </div>
                    )}

                    <div className="my-0 line-clamp-4 leading-normal">
                      {item.title}
                    </div>
                  </Link>
                  {/* divider */}
                  {index !== articles.length - 1 && (
                    <div className=" my-[16px] h-[1px] bg-divider-primary"></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
}
