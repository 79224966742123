import regProfileIcon from "@assets/icons/reg-profile-icon.webp";
import subscriberProfileIcon from "@assets/icons/subscriber-profile-icon.webp";
import Button from "@components/Button/Button";
import Link from "@components/Link/Link";
import SubscriptionPrompt from "@components/SubscriptionPrompt/SubscriptionPrompt";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import clsx from "clsx";
import Cookie from "js-cookie";
import { type ReactElement } from "react";
import { useSnapshot } from "valtio";

import { ZaobaoBookmark, ZaobaoContentFilter } from "./NonLoggedInPopover";

export type LoggedInPopoverProps = {
  rootClassName?: string;
};

/**
 * LoggedInPopover
 * @see https://www.figma.com/file/dhLEXhWqoec8sUhgcbzgdC/100-Years-Zaobao_Iterations?node-id=601%3A8085&mode=dev
 * @returns {ReactElement}
 */
export default function LoggedInPopover({
  rootClassName = "",
}: LoggedInPopoverProps): ReactElement {
  const { user } = useSnapshot(userState);

  const userIcon =
    user?.usertype === OKTAUserTypeEnum.SUBSCRIBER
      ? subscriberProfileIcon
      : regProfileIcon;

  const handleLogout = () => {
    if (window._mySPHObj !== undefined) {
      Cookie.remove("mySPHUserType"); // for premium article
      userState.user = {};
      Cookie.remove("visitorcat"); // for paywall
      window._mySPHObj.openLogout();
    }
  };

  const handleUpdatePassword = () => {
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openUpdatePassword();
    }
  };

  return (
    <div
      className={clsx(
        "shadow-around-light visible flex min-w-[300px] flex-col bg-tertiary text-left",
        "group-hover:visible",
        rootClassName,
      )}
    >
      <SubscriptionPrompt
        rootClassName={clsx({
          hidden: user?.usertype === OKTAUserTypeEnum.SUBSCRIBER,
        })}
      />

      <div
        className={clsx(
          "whitespace-nowrap px-[16px] pb-[32px] pt-[24px]",
          "group-hover:visible",
          rootClassName,
        )}
      >
        <div className="flex flex-row items-center space-x-[16px]">
          <img className="w-[60px]" src={userIcon} alt="yser profile" />

          <div className="flex flex-col space-y-[8px]">
            <div className="text-subheader2 leading-[20px]">
              {user?.display_name}
            </div>

            <span id="sph_user_name" className="hidden">
              {user?.loginid}
            </span>

            <div
              className={clsx(
                "w-fit rounded-[10px] pl-[10px] pr-[8px] text-center text-badge",
                {
                  "bg-subscriber-primary text-white":
                    user?.usertype === OKTAUserTypeEnum.SUBSCRIBER,
                  "bg-button-tertiary-hover text-primary":
                    user?.usertype === OKTAUserTypeEnum.REGISTETERED,
                },
              )}
            >
              {user?.usertype === OKTAUserTypeEnum.SUBSCRIBER && "订户"}
              {user?.usertype === OKTAUserTypeEnum.REGISTETERED && "注册用户"}
            </div>
          </div>
        </div>

        <ul className="my-[24px] flex flex-col">
          <li className="border-b pb-[16px]">
            <Link
              className="content-secondary"
              target="_blank"
              to="https://subscribe.sph.com.sg/your-account/?pub-code=zb&amp;utm_campaign=manageaccount&amp;utm_medium=sph-publication&amp;utm_source=zb&amp;utm_content=manageaccountlink-dropdown&amp;go-back-url=https://www.zaobao.com.sg/realtime/singapore"
            >
              <p className="text-user-function">管理账户</p>
            </Link>
          </li>
          <li className="border-b py-[16px]">
            <button
              className="content-secondary"
              onClick={handleUpdatePassword}
            >
              <p className="text-user-function">更新密码</p>
            </button>
          </li>
          <li className="border-b py-[16px]">
            <button
              id="logout_link"
              data-testid="logout_link"
              className="content-secondary"
              onClick={handleLogout}
            >
              <p className="text-user-function">退出</p>
            </button>
          </li>
        </ul>

        <div className="mb-[16px] text-user-function text-secondary">
          下载早报应用就能使用以下功能
        </div>

        <div className="space-y-[8px]">
          <ZaobaoBookmark />
          <ZaobaoContentFilter />
        </div>

        <div className="!mt-[24px] flex flex-row justify-center">
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (typeof window !== "undefined") {
                window.open("/products", "_blank");
              }
            }}
          >
            下载早报应用
          </Button>
        </div>
      </div>
    </div>
  );
}
