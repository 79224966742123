import { headerNavigation, type NavigationItem } from "@constants/navigation";
import clsx from "clsx";
import { ReactElement } from "react";

export default function AmpNavigation({
  rootClassName,
}: {
  rootClassName?: string;
}): ReactElement {
  // Home Navigation
  const homeNavigation = headerNavigation.find(
    (item) => item.key === "home",
  ) as NavigationItem;

  // Navigations that have children
  const navigationWithChildren = headerNavigation.filter(
    (item) => item.children && item.children.length > 0,
  );

  // Navigations that do not have children and key is not "home"
  const navigationWithoutChild = headerNavigation.filter(
    (item) => !item.children && item.key !== "home",
  );

  return (
    <>
      <div className={clsx(rootClassName, "pt-[5px]")}>
        <div className="px-[10px]">
          <NavButton navigation={homeNavigation} />
        </div>

        <amp-accordion expand-single-section="" animate="">
          {navigationWithChildren.map((navigationItem: NavigationItem) => (
            <section className="group px-[10px]" key={navigationItem.key}>
              <NavButton navigation={navigationItem}></NavButton>

              {navigationItem.children && (
                <ul
                  className={clsx(
                    "separator-column-primary columns-2 bg-white py-[10px] text-[--color-grey-500]",
                  )}
                >
                  {navigationItem.children.map((childItem) => (
                    <li key={childItem.key} className="py-[5px] text-center">
                      <a
                        aria-label={childItem.label}
                        {...{
                          on: `tap:AMP.navigateTo(url=${childItem.href})`,
                        }}
                        className="block cursor-pointer text-base"
                        target="_self"
                        role="button"
                        tabIndex={0}
                      >
                        {childItem.label}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </section>
          ))}
        </amp-accordion>

        {navigationWithoutChild.map((navigationItem: NavigationItem) => (
          <div key={navigationItem.key} className="mx-[10px]">
            <NavButton navigation={navigationItem} />
          </div>
        ))}
      </div>
    </>
  );
}

function NavButton({
  navigation,
}: {
  navigation: NavigationItem;
}): ReactElement {
  const baseClasses =
    "relative block w-full border-none text-white text-left text-base after:absolute after:bottom-0 after:right-[10px] after:top-0 after:my-auto after:h-max font-medium cursor-pointer";

  const childrenClasses = navigation.children
    ? "after:border-b-0 after:border-l-[8px] after:border-r-[8px] after:border-t-[8px] after:border-r-transparent after:border-l-transparent hover:after:border-[--text-brand-primary] px-[10px] py-[10px] bg-transparent after:border-white"
    : "px-[10px]";

  const hoverStateClasses = navigation.children
    ? "hover:text-[--text-brand-primary] hover:after:border-l-transparent hover:after:border-r-transparent hover:bg-white"
    : "hover:text-[--text-brand-primary] hover:bg-white";

  const activeStateClasses =
    "aria-expanded:bg-white aria-expanded:text-[--text-brand-primary] aria-expanded:after:rotate-180 aria-expanded:after:border-t-[--text-brand-primary]";

  return (
    <h3
      className={clsx(
        baseClasses,
        childrenClasses,
        hoverStateClasses,
        activeStateClasses,
      )}
      role="presentation"
    >
      {navigation.children ? (
        navigation.label
      ) : (
        <a
          aria-label={navigation.label}
          {...{ on: `tap:AMP.navigateTo(url=${navigation.href})` }}
          className="block w-full py-[10px] text-left"
          target="_self"
          role="button"
          tabIndex={0}
        >
          {navigation.label}
        </a>
      )}
    </h3>
  );
}
