import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import { ArticlePreviewType } from "@typings/Data";
import { isValidArray } from "@utils/article";
import clsx from "clsx";

interface IArticleFurtherReading {
  className?: string;
  data: ArticlePreviewType[];
}

export default function ArticleFurtherReading({
  className = "",
  data = [],
}: IArticleFurtherReading) {
  const _articles = data.slice(0, 4); // 4 article
  const firstArticle = isValidArray(_articles) ? _articles[0] : undefined;
  const leftArticles = _articles.slice(1, 4);

  return (
    <div className={className}>
      <div className="text-[18px]">
        {firstArticle ? (
          <>
            <Link
              to={firstArticle.href || "#"}
              className=" !text-link-headline-default no-underline hover:!text-link-headline-hover"
              target="_self" // within the same tab
            >
              {firstArticle.thumbnail ? (
                <div className=" relative mb-[16px]">
                  <img
                    alt=""
                    src={firstArticle.thumbnail}
                    className=" my-0 max-w-full rounded-[4px] object-contain"
                  />
                  {/* video postcast interactive */}
                  {!!firstArticle.item_type && (
                    <ArticleTypeIcon itemTypeProps={firstArticle.item_type} />
                  )}
                </div>
              ) : null}
              <div className="mb-[16px] line-clamp-2 h-[54px] leading-normal">
                {firstArticle.title}
              </div>
            </Link>

            {/* divider */}
            {leftArticles.length > 0 ? (
              <div className="h-[1px] bg-divider-primary"></div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className=" text-[18px]">
        {leftArticles.map((item, index) => {
          return (
            <div key={index}>
              <Link
                to={item.href}
                className=" flex gap-[8px] py-[16px] !text-link-headline-default no-underline hover:!text-link-headline-hover"
                target="_self" // within the same tab
              >
                <div
                  className={clsx(
                    "flex-1",
                    item.thumbnail
                      ? "line-clamp-3"
                      : "line-clamp-2 h-[54px] leading-normal",
                  )}
                >
                  {item.title}
                </div>

                {item.thumbnail ? (
                  <div className=" relative">
                    <img
                      alt=""
                      src={item.thumbnail}
                      className=" my-0 w-[113px] max-w-full rounded-[4px] object-contain"
                    />
                    {/* video postcast interactive */}
                    {!!item.item_type && (
                      <ArticleTypeIcon itemTypeProps={item.item_type} />
                    )}
                  </div>
                ) : null}
              </Link>
              {/* divider */}
              {index !== leftArticles.length - 1 && (
                <div className="h-[1px] bg-divider-primary"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
