import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import Button from "@components/Button/Button";
import NewsletterSignUpForm from "@components/NewsletterSignUpForm/NewsletterSignUpForm";
import { BFF_BASE_URL } from "@constants/bff";
import {
  faChevronLeft,
  faChevronRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePageContent from "@hooks/usePageContent";
import useParams from "@hooks/useSafeParams";
import { type NeuronTracker, useNeuron } from "@sphtech/neuron";
import userState from "@stores/user";
import viewportState from "@stores/viewport";
import { ArticlePreviewType } from "@typings/Data";
import { BFFResponseType } from "@typings/Data";
import { WebCategoryLevel2ContextType } from "@typings/Data";
import { sendPvToGa } from "@utils/ga";
import axios from "axios";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { useSnapshot } from "valtio";

export default function VerticalArticleList({
  articles,
  rootClassName = "",
}: {
  articles: Array<ArticlePreviewType>;
  rootClassName?: string;
}) {
  const { user } = useSnapshot(userState);
  const { isTouchableDevice } = useSnapshot(viewportState);

  const { level1 = "", level2 = "" } = useParams();
  const { getPageContent } = usePageContent();

  const { track } = useNeuron() as NeuronTracker;

  const [currentArticles, setCurrentArticles] = useState<
    Array<ArticlePreviewType> | undefined
  >(articles);

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [paginationDirection, setPaginationDirection] = useState<
    "left" | "right" | ""
  >("");

  const isPrevButtonSpinning = isLoading && paginationDirection === "left";
  const isNextButtonSpinning = isLoading && paginationDirection === "right";

  const paginationButtonCls = `max-md:rounder-full group py-[--spacing-6] max-md:justify-center`;
  const paginationButtonLabelCls = "text-button-tertiary-default text-button1";
  const paginationIconCls =
    "text-icon-secondary-default w-[--spacing-24] h-[--spacing-24]";
  const [showNextButton, setShowNextButton] = useState(false);
  useEffect(() => {
    if (page === 0) {
      scrollToTop(() => {
        setCurrentArticles(articles);
      });
    }

    if (page > 0) {
      track("load");
      setIsLoading(true);

      scrollToTop(() => {
        getPageContent({ level1, level2, page })
          .then((response: BFFResponseType["response"]) => {
            const { articles } = response as WebCategoryLevel2ContextType;
            setCurrentArticles(articles);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
    }

    fetchNextPageArticles(page, level1, level2)
      .then((result) => {
        setShowNextButton(result);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <Fragment>
      <ul
        className={`vertical-article-list ${rootClassName} lg:flex lg:w-full lg:flex-row lg:flex-wrap`}
      >
        {currentArticles &&
          currentArticles.map((article, index) => {
            return (
              <Fragment key={article.id}>
                {index === 6 && !user?.loginid && (
                  <li className="!mx-[20px] lg:w-full">
                    <NewsletterSignUpForm />
                  </li>
                )}
                <li className="lg:w-4/12">
                  <VerticalArticleCard
                    rootClassName={clsx({
                      responsive: level2 || level1 === "interactive-graphics",
                    })}
                    article={article}
                  />
                </li>
              </Fragment>
            );
          })}
      </ul>

      <div
        className={clsx(
          "relative mb-[16px] mt-[16px] w-full flex-row justify-between px-8 md:mx-0",
          {
            flex: currentArticles && currentArticles.length > 23,
            hidden:
              currentArticles && currentArticles.length < 24 && page === 0,
          },
        )}
      >
        <Button
          rootClassName={clsx(paginationButtonCls, {
            invisible: page === 0,
            "hover:bg-button-tertiary-hover": !isTouchableDevice,
          })}
          size={"large"}
          labelClassName={paginationButtonLabelCls}
          type="tertiary"
          label="上一页"
          icon={
            <FontAwesomeIcon
              icon={isPrevButtonSpinning ? faSpinner : faChevronLeft}
              spin={isPrevButtonSpinning}
              className={paginationIconCls}
            />
          }
          iconPosition="left"
          onClick={() => {
            if (isLoading) {
              return;
            }
            sendPvToGa();
            setPaginationDirection("left");
            setPage((page) => page - 1);
          }}
        />
        {showNextButton ? (
          <Button
            rootClassName={clsx(paginationButtonCls, {
              "hover:bg-button-tertiary-hover": !isTouchableDevice,
            })}
            size={"large"}
            labelClassName={paginationButtonLabelCls}
            type="tertiary"
            label="下一页"
            icon={
              <FontAwesomeIcon
                icon={isNextButtonSpinning ? faSpinner : faChevronRight}
                spin={isNextButtonSpinning}
                className={paginationIconCls}
              />
            }
            onClick={() => {
              if (isLoading) {
                return;
              }
              sendPvToGa();
              setPaginationDirection("right");
              setPage((page) => page + 1);
            }}
          />
        ) : null}
      </div>
    </Fragment>
  );
}

function scrollToTop(callback?: () => void) {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  if (callback) {
    setTimeout(() => {
      callback();
    }, 0);
  }
}

const fetchNextPageArticles = async (
  currentPage: number,
  level1: string,
  level2: string,
) => {
  const data = await axios
    .get<BFFResponseType>(
      level1 === "keywords" || level1 === "byline" || level1 === "publication"
        ? `${BFF_BASE_URL}/${level1}/${level2}?page=${currentPage + 1}`
        : `${BFF_BASE_URL}/page-content/${level1}/${level2}?page=${currentPage + 1}`,
    )
    .then((response) => {
      return response.data.response;
    });

  if (data && "articles" in data && data.articles?.length === 0) {
    return false;
  }

  return true;
};
