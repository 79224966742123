import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";

const MostReadArticleCard = ({
  rootClassName = "",
  article,
}: {
  rootClassName?: string;
  article: ArticlePreviewType;
}) => {
  return (
    <div
      className={`card most-read-card ${rootClassName}`}
      id={`realtime-article-card-${article.id}`}
      data-testid={`realtime-article-card-${article.id}`}
    >
      <Link className="card-content group" to={article.href}>
        <div
          className={clsx("content-header", {
            "w-full": !article.thumbnail,
            "w-[60%] pr-[8px]": article.thumbnail,
          })}
        >
          <h2 className={clsx("line-clamp-3")}>{article.title}</h2>
        </div>

        {article.thumbnail ? (
          <div className="relative aspect-[3/2] w-[40%]">
            <OptimisedImage
              rootClassName="relative aspect-[3/2] h-auto w-full overflow-hidden rounded-[4px] object-cover object-center"
              pictureRootClassName="relative aspect-[3/2] flex"
              src={article.thumbnail}
              alt={article.title}
              loading="lazy"
              suffix={<ArticleTypeIcon itemTypeProps={article.item_type} />}
            />
          </div>
        ) : null}
      </Link>
    </div>
  );
};

export default MostReadArticleCard;
