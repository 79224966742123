import fontState, { FONT_SIZE_OPTION } from "@stores/font";
import clsx from "clsx";
import { useSnapshot } from "valtio";

const fontSizeItemClassName =
  "h-[30px] min-w-[23px] rounded-[100px] p-0 text-base leading-[30px] text-secondary";
const fontSizeItemActiveClassName = "bg-secondary px-[16px] !text-primary";

export default function FontSizeSwitch() {
  const { fontSize, updateGlobalFontSize } = useSnapshot(fontState);

  return (
    <ol className="mx-[16px] flex min-w-[224px] max-w-[224px] flex-1 flex-row justify-between rounded-[100px] border border-divider-secondary px-[16px]">
      <li className="flex flex-row items-center">
        <button
          onClick={() => {
            updateGlobalFontSize(FONT_SIZE_OPTION.small);
          }}
          className={clsx(fontSizeItemClassName, {
            [fontSizeItemActiveClassName]: fontSize === FONT_SIZE_OPTION.small,
          })}
          aria-label="小"
        >
          小
        </button>
      </li>
      <li className="flex flex-row items-center">
        <button
          onClick={() => {
            updateGlobalFontSize(FONT_SIZE_OPTION.standard);
          }}
          className={clsx(fontSizeItemClassName, {
            [fontSizeItemActiveClassName]:
              fontSize === FONT_SIZE_OPTION.standard,
          })}
          aria-label="标准"
        >
          标准
        </button>
      </li>
      <li className="flex flex-row items-center">
        <button
          onClick={() => {
            updateGlobalFontSize(FONT_SIZE_OPTION.large);
          }}
          className={clsx(fontSizeItemClassName, {
            [fontSizeItemActiveClassName]: fontSize === FONT_SIZE_OPTION.large,
          })}
          aria-label="大"
        >
          大
        </button>
      </li>
      <li className="flex flex-row items-center">
        <button
          onClick={() => {
            updateGlobalFontSize(FONT_SIZE_OPTION.xlarge);
          }}
          className={clsx(fontSizeItemClassName, {
            [fontSizeItemActiveClassName]: fontSize === FONT_SIZE_OPTION.xlarge,
          })}
          aria-label="特大"
        >
          特大
        </button>
      </li>
    </ol>
  );
}
