import ArrowTimeIcon from "@assets/icons/article/arrow-time.svg";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
// dayjs.extend(relativeTime);

interface IArticleTime {
  className?: string;
}
export default function ArticleTime({ className = "" }: IArticleTime) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const [showMore, setShowMore] = useState(false);

  // const article = {
  //   create_time: (new Date().getTime() - 60 * 60 * 1000) / 1000,
  //   update_time: (new Date().getTime() - 30 * 1000) / 1000,
  // };

  const update_time = article?.update_time;
  const create_time = article?.create_time;

  const [render_update_time, setUpdateTime] = useState("");
  const [render_create_time, setCreateTime] = useState("");

  useEffect(() => {
    const render_update_time = update_time
      ? dayjs.unix(update_time).isAfter(dayjs().subtract(24, "hours"))
        ? dayjs.unix(update_time).fromNow()
        : dayjs.unix(update_time).format("YYYY年M月D日 HH:mm")
      : "";

    const render_create_time = create_time
      ? dayjs.unix(create_time).isAfter(dayjs().subtract(24, "hours"))
        ? dayjs.unix(create_time).fromNow()
        : dayjs.unix(create_time).format("YYYY年M月D日 HH:mm")
      : "";

    setUpdateTime(render_update_time);
    setCreateTime(render_create_time);
  }, [create_time, update_time]);

  return update_time || create_time ? (
    <div className={className}>
      <div className="overflow-hidden text-left text-secondary">
        {update_time ? (
          <>
            <button
              className="text-left"
              onClick={() => {
                setShowMore((v) => !v);
              }}
            >
              <div className="inline">
                更新
                <span className=" px-[4px]">/</span>
                {render_update_time}
                <img
                  src={ArrowTimeIcon}
                  alt=""
                  className={clsx(
                    "my-0 ml-2 inline h-[16px] w-[16px] transition-all duration-200",
                    showMore ? "rotate-0 transform" : "rotate-180 transform",
                  )}
                />
              </div>
            </button>
            {create_time ? (
              <div
                className={` transition-all duration-200 ${showMore ? "max-h-[60px]" : "max-h-0"}`}
              >
                <div className="inline">
                  发布
                  <span className="px-[4px]">/</span>
                  {render_create_time}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {create_time ? (
              <div className={`mr-1 inline `}>
                发布
                <span className=" px-[4px]">/</span>
                {render_create_time}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  ) : null;
}
