import { DRUPAL_ENDPOINT } from "@constants/drupal";
import { ArticlePreviewType } from "@typings/Data";
import axios from "axios";
import { startTransition, useEffect, useState } from "react";

type ResponseType = {
  code: number;
  response: ArticlePreviewType[] | undefined | null;
};

/**
 * @name useCampaignCarouselFromDrupal
 * @description this is used to fetch HomePageCampaignCarousel from drupal in client side
 * @todo similar to useHomePageSpecialTopicFromDrupal
 *
 */

export function useCampaignCarouselFromDrupal(): {
  campaignCarouselArticles: ResponseType["response"];
} {
  const [data, setData] = useState<ResponseType["response"]>([]);

  const requestPath = `${DRUPAL_ENDPOINT}/web2/homepage-campaign-carousel`;

  const getInfo = async () => {
    try {
      const res = await axios.get<ResponseType>(requestPath);
      startTransition(() => {
        setData(res.data.response || []);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    campaignCarouselArticles: data,
  };
}
