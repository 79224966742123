// import Email from "@assets/icons/email.png";
// import Epaper from "@assets/icons/epaper.png";
// import Instgram from "@assets/icons/instgram.png";
// import XmrbLogo from "@assets/icons/logo-shinmin.svg?react";
// import Phone from "@assets/icons/phone.png";
// import SocialMedia from "@assets/icons/social-media.png";
// import Tiktok from "@assets/icons/tiktok.png";
// import Wechat from "@assets/icons/wechat.png";
// import Whatsup from "@assets/icons/whatsup.png";
import VerticalArticleList from "@components/ArticleCardList/VerticalArticleList/VerticalArticleList";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import Level2Template from "@components/Templates/Level2Template";
import useParams from "@hooks/useSafeParams";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { WebCategoryLevel2ContextType } from "@typings/Data";
function Page({
  setTagName,
}: {
  setTagName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const {
    context: {
      payload: { articles, tagName },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel2ContextType;
  }> = useRouteContext();

  const { level1 = "publication", level2 = "" } = useParams();
  const levelKey = [level1, level2].filter(Boolean).join("_");

  if (tagName) {
    setTagName(tagName);
  }

  const renderSectionNavigation = () => {
    return (
      <>
        <SectionNavigation
          level={levelKey}
          tagName={tagName}
          rootClassName="!w-[unset]"
        />
      </>
    );
  };

  const renderPageContent = () => {
    return articles && articles.length !== 0 ? (
      <VerticalArticleList articles={articles} rootClassName="card-listing" />
    ) : null;
  };

  return (
    <Level2Template
      topSlots={[renderSectionNavigation]}
      mainSlots={[renderPageContent]}
    />
  );
}
export default Page;
