import languageState from "@stores/language";
import clsx from "clsx";
import Cookie from "js-cookie";
import { type ReactElement, type SyntheticEvent } from "react";
import { useSnapshot } from "valtio";

export default function LanguageSwitch(): ReactElement {
  const { isTraditionalChinese } = useSnapshot(languageState);

  return (
    <button
      className={clsx(
        "border-switch-border relative flex h-[32px] w-[56px] cursor-pointer items-center justify-around rounded-full border text-primary",
      )}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
        const newIsTraditionalChinese = !isTraditionalChinese;
        languageState.isTraditionalChinese = newIsTraditionalChinese;
        Cookie.set("configLang", newIsTraditionalChinese ? "zhtw" : "zhcn"); // to sync with current prod, but we better use zh-TW and zh-CN as more standard
      }}
    >
      <div
        className={clsx(
          "absolute left-[2px] h-full w-[24px] rounded-full bg-button-segmented-secondary-default transition",
          { "translate-x-full": isTraditionalChinese },
        )}
      ></div>
      <div
        className={clsx("relative text-base", {
          "text-secondary": isTraditionalChinese,
        })}
      >
        简
      </div>
      <div
        className={clsx("relative text-base", {
          "text-secondary": !isTraditionalChinese,
        })}
      >
        繁
      </div>
    </button>
  );
}
