import ImmediateArticleCard from "@components/ArticleCard/ImmediateArticleCard/ImmediateArticleCard";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";

export default function ImmediateArticleList({
  articles,
}: {
  articles: Array<ArticlePreviewType>;
}) {
  return (
    <ul
      className={clsx(
        "immediate-article-list flex flex-col",
        "lg:-mx-[20px] lg:flex-row lg:flex-wrap",
      )}
    >
      {articles.map((article: ArticlePreviewType, index: number) => {
        return (
          <li key={article.id} className={clsx("w-full", "lg:w-1/3")}>
            <ImmediateArticleCard
              article={article}
              rootClassName={clsx({
                "!border-b-0": index === articles.length - 1,
                "lg:!border-b-0":
                  index === articles.length - 2 ||
                  index === articles.length - 3,
              })}
            />
          </li>
        );
      })}
    </ul>
  );
}
