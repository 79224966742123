// in SSR, we directly use the params from the URL since they are blocked by WAF and are safe
// in CSR, we need to sanitize the params from the URL
import { sanitize } from "@utils/sanitize";
import { useParams } from "react-router-dom";

export default function useSafeParams() {
  const params = useParams();
  return sanitizeParams(params);
}

function sanitizeParams(
  params: Record<string, string | undefined>,
): Record<string, string> {
  return Object.entries(params).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (!value) {
        return { ...acc };
      }

      acc[key] = sanitize(value);
      return { ...acc };
    },
    {},
  );
}
