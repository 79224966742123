import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import { ReactElement, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

import AppDeepLinkMeta from "../Components/AppDeepLinkMeta";
import AuthorDefaultLayout from "./AuthorDefaultLayout";

export default function AuthorPage(): ReactElement {
  useGoogleAdsTargeting({ targeting: { key: "page", value: "listing" } });
  const { level1 = "", level2 = "" } = useParams();
  const { isMobileView, isDesktopView, isInAppBrowser } =
    useSnapshot(viewportState);

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  // Meta Tag - Title + include author's character name
  const [authorName, setAuthorName] = useState("");
  const title = `${authorName} 作者的文章 | 联合早报`;
  const description = `${authorName} 作者文章 - 《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。${authorName} 撰写的新闻与文章，尽在zaobao.com.sg。`;
  const sectionAdsName = `byline`;
  const canonical_url = `/byline/${level2}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <AppDeepLinkMeta urlPath={canonical_url} />

      <MetaTags
        type="website"
        title={title}
        description={description}
        slug={canonical_url}
      ></MetaTags>

      <GAData level2_ga={level1} title={title} adblocker={getAdBlocker()} />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <AuthorDefaultLayout setAuthorName={setAuthorName} />

      {isInAppBrowser ? null : <Footer />}

      {isMobileView ? (
        isInAppBrowser ? null : (
          <CatfishGoogleAd
            slot={GoogleAdsSlotFactory.catfish(sectionAdsName)}
          />
        )
      ) : null}
    </>
  );
}
