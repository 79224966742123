import clsx from "clsx";
import { type ReactElement, type ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

export type ModalProps = {
  isVisible: boolean;
  onClose?: () => void;
  children?: ReactNode;
};

export default function Modal({
  isVisible = false,
  onClose,
  children,
}: ModalProps): ReactElement {
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsClient(true);
    }
  }, []);
  return (
    <>
      {isClient && isVisible
        ? ReactDOM.createPortal(
            <div
              className={clsx("web2-modal-overlay", {
                "z-50 flex": isVisible,
                hidden: !isVisible,
              })}
            >
              <button className="web2-modal-backdrop" onClick={onClose} />
              <div className="web2-modal-content">
                {children}
                <button
                  className="web2-modal-close"
                  onClick={onClose}
                  aria-label="close"
                ></button>
              </div>
            </div>,
            document.body,
          )
        : null}
    </>
  );
}
