import { proxy } from "valtio";

type ViewportStateType = {
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
  isMobileOrTabletDevice: boolean;
  isTouchableDevice: boolean;
  isInAppBrowser: boolean;
  width: number;
  height: number;
  isHorizontal: boolean;
  isPortrait: boolean;
};

const viewportState = proxy<ViewportStateType>({
  isMobileView: false,
  isTabletView: false,
  isDesktopView: true,
  isMobileOrTabletDevice: false,
  isTouchableDevice: false,
  isInAppBrowser: false,
  width: 1200,
  height: 700,
  isHorizontal: true,
  isPortrait: false,
});

export default viewportState;
