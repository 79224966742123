import { ArticleDetailNS } from "@typings/Data";

import { articleAuthorName, section } from "./AmpGAData";

export function getArticleAuthorNames(authors: articleAuthorName[]) {
  const authorList = [] as string[];

  authors.map((author: articleAuthorName) => {
    authorList.push(author.name + "_byline");
  });
  return authorList.join("|");
}

export function getArticleLevel2(section: section) {
  const parts = section.name.split("_");
  return parts[0];
}

export function getArticleChapter1(section: section): string {
  const parts = section.name.split("_");
  return parts[1];
}

export function getArticleKeywords(
  keywords: ArticleDetailNS.TagType[],
  breakpoint?: string,
) {
  const keywordsList = [] as string[];

  keywords.map((keyword: ArticleDetailNS.TagType) => {
    if (keyword.name) {
      keywordsList.push(keyword.name.toLowerCase());
    }
  });

  return keywordsList.join(breakpoint ? breakpoint : ",");
}
