import Slider, { Settings } from "@ant-design/react-slick";
import Link from "@components/Link/Link";
import { useFullWidthBannerFromDrupal } from "@hooks/ClientSideFromDrupal/useFullWidthBannerFromDrupal";
import globalState from "@stores/global";
import viewportState from "@stores/viewport";
import { FullWidthBannerType } from "@typings/Data";
import clsx from "clsx";
import { Fragment, useMemo, useRef, useState } from "react";
import { useSnapshot } from "valtio";

const HomepageFullWidthBanner = ({
  additionalClasses,
  level1 = "",
  level2 = "",
}: {
  additionalClasses?: string;
  level1?: string;
  level2?: string;
}) => {
  const {
    fullWidthBanner = [],
  }: {
    fullWidthBanner: FullWidthBannerType;
  } = useFullWidthBannerFromDrupal({
    level1,
    level2,
  });

  // console.log("HomepageFullWidthBanner: ", fullWidthBanner);

  const sliderRef = useRef<Slider>(null);
  const { isClientSideMounted } = useSnapshot(globalState);
  const { isDesktopView } = useSnapshot(viewportState);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };

  const sliderSettings: Settings = useMemo(() => {
    return {
      className: "homepage-full-width-banner-slider", // define slider container className
      autoplay: true,
      autoplaySpeed: 5 * 1000,
      speed: 500,
      arrows: false,
      dots: false,
      beforeChange: (_, next) => {
        setCurrentIndex(next);
      },
    };
  }, []);

  const shouldRender = !!fullWidthBanner.length;

  return (
    <Fragment>
      {shouldRender ? (
        <section
          id="homepage-full-width-banner"
          className={clsx(
            "homepage-full-width-banner py-[16px]",
            additionalClasses,
          )}
        >
          <div className="layout-container">
            <div className="w-full">
              <div className="banner-container relative overflow-hidden">
                {isClientSideMounted ? (
                  <Slider {...sliderSettings} ref={sliderRef}>
                    {fullWidthBanner.map((banner) => {
                      return (
                        <Link
                          key={banner.desktopImageUrl || banner.mobileImageUrl}
                          to={
                            isDesktopView ? banner.desktopUrl : banner.mobileUrl
                          }
                        >
                          <img
                            loading="lazy"
                            src={
                              isDesktopView
                                ? banner.desktopImageUrl
                                : banner.mobileImageUrl
                            }
                            alt={
                              isDesktopView
                                ? banner.desktop_alt
                                : banner.mobile_alt
                            }
                            className="w-full"
                          />
                        </Link>
                      );
                    })}
                  </Slider>
                ) : null}

                {fullWidthBanner.length === 1 ? null : (
                  <div className="homepage-full-width-banner-btn absolute bottom-0 flex h-[20px] w-full items-center justify-center">
                    {fullWidthBanner.map((_, index) => {
                      return (
                        <button
                          key={_.desktopImageUrl || _.mobileImageUrl}
                          onClick={() => {
                            handleDotClick(index);
                          }}
                          className={`mx-2 h-[8px] w-[8px] rounded-full ${clsx({
                            "bg-brand-primary": index === currentIndex,
                            "bg-quaternary": index !== currentIndex,
                          })}`}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </Fragment>
  );
};

export default HomepageFullWidthBanner;
