import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import clsx from "clsx";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

interface IAmpArticleTime {
  className?: string;
}

export default function AmpArticleTime({
  className = "articleTime",
}: IAmpArticleTime) {
  const { extend } = dayjs;
  extend(timezone);
  extend(utc);

  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const updateTime = article?.update_time;
  const createTime = article?.create_time;

  const renderUpdateTime = updateTime
    ? dayjs.unix(updateTime).tz("Asia/Singapore").format("YYYY年M月D日 HH:mm")
    : "";
  const renderCreateTime = createTime
    ? dayjs.unix(createTime).tz("Asia/Singapore").format("YYYY年M月D日 HH:mm")
    : "";

  return updateTime || createTime ? (
    <div className={className}>
      <div className="overflow-hidden text-left text-secondary">
        {updateTime ? (
          <>
            <amp-accordion id="my-accordion" disable-session-states>
              <section>
                <h2
                  className={clsx(
                    "relative block border-none bg-transparent text-secondary",
                    // dropdown button
                    createTime &&
                      "after:absolute after:bottom-0 after:top-0 after:my-auto after:ml-[10px] after:h-max",
                    createTime &&
                      "after:border-b-0 after:border-l-[3px] after:border-r-[3px] after:border-t-[3px] after:border-[--text-secondary] after:border-l-transparent after:border-r-transparent",
                    createTime && "aria-expanded:after:rotate-180",
                  )}
                >
                  更新
                  <span className="px-[4px]">/</span>
                  {renderUpdateTime}
                </h2>
                {createTime ? (
                  <p className="text-secondary">
                    发布
                    <span className="px-[4px]">/</span>
                    {renderCreateTime}
                  </p>
                ) : null}
              </section>
            </amp-accordion>
          </>
        ) : (
          <>
            {createTime ? (
              <div className={`mr-1 inline`}>
                发布
                <span className="px-[4px]">/</span>
                {renderCreateTime}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  ) : null;
}
