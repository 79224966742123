/**
 * @deprecated
 * To be removed once all CUE processing moved into BFF, client side should not know anything about CUE
 * OR how to generate data from CUE
 */
import { DRUPAL_ENDPOINT } from "@constants/drupal";
import type { CaasZaobaoArticle, Source } from "@typings/OpensearchV2";

export const regexMatchArticle = /^story[0-9]+-[0-9]+$/;
export const regexMatchArticlePreview = /^article[0-9]+ece$/;

export function generateArticleLink(article: Partial<CaasZaobaoArticle>) {
  const { href, drupalUrl } = article;

  if (drupalUrl) {
    return `${DRUPAL_ENDPOINT}${drupalUrl}`;
  }

  const pieces = href?.split("/").slice(3) ?? [];
  return `${DRUPAL_ENDPOINT}/${pieces.join("/")}`;
}

export function generateTitle(article: Partial<CaasZaobaoArticle>): string {
  if (article.displaySetting && article.displaySetting.displayHeadline) {
    return article.displaySetting.displayHeadline;
  }

  if (article.title) {
    return article.title;
  }

  return "";
}

export function cueArticleHasKeywords(
  cueArticle: Source<CaasZaobaoArticle>,
  keywordsToAssert: Array<string>,
): boolean {
  if (
    !("data" in cueArticle) ||
    !("context" in cueArticle.data) ||
    !("tags" in cueArticle.data.context) ||
    !Array.isArray(cueArticle.data.context.tags)
  ) {
    return false;
  }

  return cueArticle.data.context.tags.some((keyword) => {
    return keywordsToAssert.includes(keyword.name);
  });
}

export function cueArticleHasSection(
  cueArticle: Source<CaasZaobaoArticle>,
  sectionUniqueNamesToAssert: string[],
): boolean {
  if (
    !("data" in cueArticle) ||
    !("resolution" in cueArticle.data) ||
    !cueArticle.data.resolution
  ) {
    return false;
  }

  return sectionUniqueNamesToAssert.some((sectionUniqueName) => {
    return (
      cueArticle.data.resolution &&
      (cueArticle.data.resolution.section.uniqueName === sectionUniqueName ||
        cueArticle.data.resolution.section.parent?.uniqueName ===
          sectionUniqueName)
    );
  });
}

export function cueArticleHasDisplaySetting(
  cueArticle: Source<CaasZaobaoArticle>,
  displaySettingsToAssert: Array<string>,
): boolean {
  if (
    !("data" in cueArticle) ||
    !("context" in cueArticle.data) ||
    !("displayAssetsMultiple" in cueArticle.data.context.displaySetting)
  ) {
    return false;
  }

  return cueArticle.data.context.displaySetting.displayAssetsMultiple.some(
    (assetSetting) => {
      return displaySettingsToAssert.includes(assetSetting);
    },
  );
}

export const isValidArray = (v?: unknown) => Array.isArray(v) && v.length > 0;
