import viewportState from "@stores/viewport";
import clsx from "clsx";
import { type ReactElement, useState } from "react";
import { useSnapshot } from "valtio";

type Tab = {
  key: string;
  label: string;
  value: string;
  onClick?: (tabValue: string) => void;
};

export type TabsProps = {
  rootClassName?: string;
  tabs: Tab[];
};

/**
 * Tabs
 * Description: This is our base tabs component
 * @param TabsProps
 * @returns The rendered React element
 */
export default function Tabs({
  rootClassName = "",
  tabs = [],
}: TabsProps): ReactElement {
  const [curerntActiveTab, setCurrentActiveTab] = useState(tabs[0].value);
  const { isTouchableDevice } = useSnapshot(viewportState);
  return (
    <ul
      className={clsx(
        "flex flex-row items-center overflow-hidden rounded-full border-[1px] border-divider-primary",
        rootClassName,
      )}
    >
      {tabs.map((tab, index) => {
        return (
          <li
            id={tab.key}
            key={tab.value}
            className={clsx(
              " text-secondary transition-all duration-300",
              "hover:text-primary",
              {
                "hover:bg-button-segmented-secondary-hover":
                  curerntActiveTab === tab.value && !isTouchableDevice,
              },
              {
                "after:separator-breadcrumb relative":
                  index !== tabs.length - 1,
                "bg-button-segmented-secondary-default text-button1 !text-emphasis":
                  curerntActiveTab === tab.value,
                "bg-button-segmented-primary-default text-button2 hover:bg-button-segmented-primary-hover":
                  curerntActiveTab !== tab.value,
              },
            )}
          >
            <button
              id={tab.value}
              className="px-[24px] py-[8px] "
              onClick={() => {
                setCurrentActiveTab(tab.value);
                tab.onClick && tab.onClick(tab.value);
              }}
            >
              {tab.label}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

{
  /* <Button rootClassName="vertical-article-card" label="abc" />
<Button rootClassName="vertical-article-card" label="bcd" /> */
}
