import CircleImg from "@assets/icons/gift-popover-circle.svg";
import Button from "@components/Button/Button";
import Link from "@components/Link/Link";
import viewportState from "@stores/viewport";
import { type ReactElement } from "react";
import { useSnapshot } from "valtio";

export type IProps = {
  isVisible: boolean;
  onClose?: () => void;
};

export default function GiftPopover({
  isVisible,
  onClose,
}: IProps): ReactElement {
  const { isTouchableDevice } = useSnapshot(viewportState);
  return (
    <>
      {isVisible && (
        <div className="web2-popover-wrapper">
          <div className="popover-box px-[16px] py-[8px]">
            <div className="trangle"></div>
            <div className="trangle-bg"></div>
            <div className="pb-[4px] text-center text-subheader3 text-primary">
              订户专享 赠阅文章
            </div>
            <div className="text-body2 tracking-normal text-secondary">
              早报新添“赠阅文章”功能！凡早报VIP会员，每月可赠送 5
              篇订户专享文章。
              <Link
                to="/faq?tab=gift"
                className="text-link-headline-hover underline hover:text-link-headline-hover"
                target={isTouchableDevice ? "_self" : "_blank"}
              >
                了解更多
              </Link>
            </div>
            <div className="flex justify-center pt-[8px]">
              <Button onClick={onClose}>知道了</Button>
            </div>
            <img
              src={CircleImg}
              alt=""
              className="absolute right-0 top-[10px] w-[12px]"
            />
          </div>
        </div>
      )}
    </>
  );
}
