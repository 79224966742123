import PrimaryLogo from "@assets/logos/PrimaryLogo.svg";
import Link from "@components/Link/Link";
import clsx from "clsx";
import { type ReactElement } from "react";

export type HeaderLogoProps = {
  rootClassName?: string;
  iconClassName?: string;
};

export default function AmpHeaderLogo({
  rootClassName = "",
}: HeaderLogoProps): ReactElement {
  return (
    <Link
      className={clsx(
        "flex flex-row items-center justify-center px-[15px]",
        rootClassName,
      )}
      to="/"
      target="_self"
    >
      <amp-img
        alt="联合早报"
        src={PrimaryLogo}
        width="113"
        height="36"
        layout="fixed"
      ></amp-img>
    </Link>
  );
}
