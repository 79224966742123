/* eslint-disable jsx-a11y/anchor-is-valid */
import AnonymousUserIcon from "@assets/icons/user-gray.svg";
import RegUserIcon from "@assets/icons/user-reg-gray-tick.svg";
import SubUserIcon from "@assets/icons/user-sub-gray-tick.svg";
import AnonymousUserIconWhite from "@assets/icons/user-white.svg";
import NonAnonymousUserIconWhite from "@assets/icons/user-white-tick.svg";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import clsx from "clsx";
import { type ReactElement } from "react";
import { useSnapshot } from "valtio";

export type SubscriberButtonProps = {
  rootClassName?: string;
  type?: "gray" | "white";
  hasLabel?: boolean;
  onClick?: () => void;
};

const userIconMap = {
  [OKTAUserTypeEnum.ANONYMOUS]: {
    gray: AnonymousUserIcon,
    white: AnonymousUserIconWhite,
  },
  [OKTAUserTypeEnum.GUEST]: {
    gray: AnonymousUserIcon,
    white: AnonymousUserIconWhite,
  },
  [OKTAUserTypeEnum.REGISTETERED]: {
    gray: RegUserIcon,
    white: NonAnonymousUserIconWhite,
  },
  [OKTAUserTypeEnum.SUBSCRIBER]: {
    gray: SubUserIcon,
    white: NonAnonymousUserIconWhite,
  },
};

const userDescriptionMap = {
  [OKTAUserTypeEnum.ANONYMOUS]: "登录/免费注册",
  [OKTAUserTypeEnum.GUEST]: "登录/免费注册",
  [OKTAUserTypeEnum.REGISTETERED]: "注册用户",
  [OKTAUserTypeEnum.SUBSCRIBER]: "订户",
};

export default function SubscriberButton({
  rootClassName = "",
  hasLabel = true,
  type = "gray",
  onClick,
}: SubscriberButtonProps): ReactElement {
  const { user } = useSnapshot(userState);
  const UserIcon = !user?.display_name
    ? userIconMap[OKTAUserTypeEnum.ANONYMOUS][type]
    : userIconMap[user.usertype as OKTAUserTypeEnum][type];

  return (
    <button
      aria-label="subscriber button"
      className={clsx(
        "ml-[15px] flex cursor-pointer flex-row items-center text-base text-secondary",
        "hover:text-brand",
        rootClassName,
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      <img
        className="mr-[4px]"
        width="20"
        height="20"
        src={UserIcon}
        alt="user"
      />
      {hasLabel
        ? !user?.display_name
          ? userDescriptionMap[OKTAUserTypeEnum.ANONYMOUS]
          : userDescriptionMap[user.usertype as OKTAUserTypeEnum]
        : null}
    </button>
  );
}
