import { proxy } from "valtio";

export enum OKTAUserTypeEnum {
  SUBSCRIBER = "y-sub",
  REGISTETERED = "y-reg",
  ANONYMOUS = "y-anoy",
  GUEST = "y-guest",
}

export type OKTAUserType = {
  display_name?: string;
  loginid?: string;
  mySPHSessionId?: string;
  mysphw?: string;
  reguserstatus?: string;
  seamless_login_from_app?: string;
  service_type?: string;
  service_type_value?: 1 | 0 | null;
  usertype?: OKTAUserTypeEnum;
  svc?: string;
  full_body?: string;
} | null;

interface IUserState {
  user: OKTAUserType;
  userError: unknown;
}
/**
 * User store
 * @returns {IUserState.user} - Current user information
 * @example updateUser({ display_name: "John Doe" });
 * @returns {IUserState.userError} - Current user error
 */

const userState = proxy<IUserState>({
  user: {},
  userError: "",
});

export default userState;
