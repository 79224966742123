import { useScript } from "@sphtech/web2-core/hooks";
import { useEffect } from "react";

/**
 * @name useInsInit
 * @description parse the instagram element for article page.
 */
export default function useInsInit(renderBodyContent: string) {
  //   <!-- instagram -->
  // <script id="ig-script" defer async src="/_web2/instagram-embed.js"></script>

  useScript({
    id: "ig-script",
    async: true,
    src: "/_web2/instagram-embed.js",
  });

  useEffect(() => {
    // reload instagram embed
    if (window.instgrm?.Embeds) {
      window.instgrm?.Embeds?.process?.();
    }
  }, [renderBodyContent]);
}
