import Link from "@components/Link/Link";
import { NavigationChildren } from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement, useMemo } from "react";

type CategoryProps = {
  rootClassName?: string;
  isInverse?: boolean;
  prefix?: string;
  categories?: Array<
    Omit<NavigationChildren, "key"> & Partial<Pick<NavigationChildren, "key">>
  >;
};

const SubCategoryHeader = ({
  rootClassName = "",
  prefix = "SubCategoryHeader",
  categories = [],
  isInverse = false,
}: CategoryProps): ReactElement => {
  const visibleNavItems = useMemo(() => {
    return categories.filter((it) => !it.displaySettings?.isHiddenInNavigation);
  }, [categories]);

  return (
    <div
      className={clsx(
        "relative flex h-[40px] items-center justify-end overflow-y-hidden",
        rootClassName,
      )}
    >
      <div className="no-scrollbar flex overflow-y-hidden overflow-x-scroll">
        {visibleNavItems.map((cat, index) => {
          return (
            <Link
              className={clsx(
                "whitespace-nowrap px-[12px] py-[14px] text-button2",
                {
                  "text-secondary hover:bg-tertiary hover:text-button1 hover:text-brand":
                    !isInverse,
                  "hover:text-brand-primary text-link-on-dark-default hover:bg-tertiary hover:text-button1 hover:text-brand":
                    isInverse,
                },
              )}
              key={`${prefix}_${cat.key || cat.label}_${index}`}
              to={cat.href || "/"}
              target="_self"
            >
              {cat.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SubCategoryHeader;
