import { useEffect } from "react";

/**
 * @name useDataWrapperHeightChange
 * @description add listener to change dataWrapper iframe height
 */
export default function useDataWrapperHeightChange(renderBodyContent: string) {
  useEffect(() => {
    const existDataWrapperEmbed =
      document.getElementsByClassName("bff-data-wrapper");

    if (existDataWrapperEmbed.length == 0) {
      return;
    }

    // code from drupal
    const handler = (event: MessageEvent) => {
      if (typeof event.data["datawrapper-height"] !== "undefined") {
        for (const chartId in event.data["datawrapper-height"]) {
          console.log(chartId, "window.addEventListener message");
          const iframe =
            document.getElementById("datawrapper-chart-" + chartId) ||
            document.querySelector("iframe[src*='" + chartId + "']");
          if (!iframe) {
            continue;
          }
          iframe.style.height =
            event.data["datawrapper-height"][chartId] + "px";
        }
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [renderBodyContent]);
}
