/**
 *
 * @param scriptUrl the url of the script to load
 * @param verifyScriptLoaded a custom callback function that returns true if the script has determined to be loaded (e.g. !!window._mySPHObj?.openLogin)
 * @param scriptId the id of the script
 * @returns a promise that resolves when the script is loaded
 */
export default function loadScript(
  scriptUrl: string,
  verifyScriptLoaded: () => boolean = () => true,
  scriptId?: string,
) {
  let verifyScriptLoadedTimeout: ReturnType<typeof setTimeout>;

  // console.log("scriptUrl", scriptUrl);

  return new Promise<{
    script: HTMLScriptElement;
    isVerified: boolean;
  }>(function (resolve, reject) {
    function handleScriptOnload() {
      const handleVerify = () => {
        clearTimeout(verifyScriptLoadedTimeout);
        verifyScriptLoadedTimeout = setTimeout(function () {
          const isVerified = verifyScriptLoaded();

          if (isVerified) {
            resolve({
              script: scriptElement as HTMLScriptElement,
              isVerified,
            });
          }
        }, 200);
      };

      if (document.readyState === "complete") {
        handleVerify();
        return;
      }

      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
          handleVerify();
        }
      });
    }

    const scriptElement = document.querySelector(
      `script[src="${scriptUrl}"]`,
    ) as HTMLScriptElement | undefined;

    if (scriptElement && !verifyScriptLoaded()) {
      scriptElement.onload = handleScriptOnload;
      return;
    }

    if (scriptElement && verifyScriptLoaded()) {
      resolve({
        script: scriptElement,
        isVerified: true,
      });
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptUrl;
    script.async = true;

    if (scriptId) {
      script.id = scriptId;
    }

    script.onload = handleScriptOnload;

    script.onerror = function () {
      reject(new Error("Failed to load script: " + scriptUrl));
    };

    document.body.appendChild(script);
  });
}
