/* eslint-disable jsx-a11y/anchor-is-valid */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import type { ReactElement, ReactNode } from "react";
import { useSnapshot } from "valtio";
export type ButtonProps = {
  rootClassName?: string;
  labelClassName?: string;
  children?: ReactElement | ReactNode | string;
  label?: string;
  icon?: ReactNode;
  isLoading?: boolean;
  iconPosition?: "left" | "right";
  type?: "primary" | "tertiary" | "yellow" | "subscriber";
  size?: "small" | "normal" | "large" | "login" | "small-send";
  onClick?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => void;
  clickable?: boolean;
};

/**
 * Button
 * Description: This is our base button component
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function Button({
  rootClassName,
  labelClassName,
  isLoading = false,
  type = "primary",
  size = "normal",
  label = "",
  children = "",
  icon = "",
  iconPosition = "right",
  onClick,
  clickable = true,
}: ButtonProps): ReactElement {
  // using anchor tag instead of button tag to support ios safari < 14
  const { isTouchableDevice } = useSnapshot(viewportState);

  const PreLoader: () => ReactNode = () => {
    return (
      <div
        className={clsx(
          "absolute  mx-auto flex h-[28px] w-[50px] justify-center pb-[16px] lg:h-[26px]",
          {
            "top-[-1px]": size === "normal",
            "top-[-4px]": size === "small-send",
          },
        )}
      >
        <FontAwesomeIcon
          color="#6f6f6f"
          icon={faSpinner}
          spin={true}
          size={"2x"}
          className={"mx-auto"}
        />
      </div>
    );
  };
  return (
    <button
      className={clsx(
        "relative flex flex-row items-center space-x-[8px] rounded-button-radius-full text-center text-button1",
        {
          "bg-[#c58196] text-white": isLoading,
          "text-white hover:bg-[#c58196]": isLoading,
          "border-button-tertiary bg-button-primary-default text-white ":
            type === "primary" && !isLoading,
          "hover:bg-[--color-red-550]":
            type === "primary" && !isTouchableDevice && !isLoading,
          "border border-button-tertiary-default bg-primary text-button-tertiary-default transition-all ":
            type === "tertiary",
          "hover:bg-button-tertiary-hover hover:text-button1":
            type === "tertiary" && !isTouchableDevice && !isLoading,
          "bg-subscriber-primary text-white": type === "subscriber",
          "bg-icon-flag-china text-white": type === "yellow",
          "cursor-auto": !clickable,
          // sizes
          "min-h-[24px] px-[12px]": size === "small",
          "min-h-[24px] px-[8px]": size === "small-send",
          "min-h-[30px] px-[16px]": size === "normal",
          "min-h-[40px] px-[24px]": size === "large",
          "min-h-[30px] w-[99px]  justify-center px-[12px] py-[6px]":
            size === "login",
        },
        rootClassName,
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      aria-label={typeof children === "string" ? children : label}
    >
      {icon && iconPosition === "left" ? icon : null}
      <div className={clsx("transition-all", labelClassName)}>
        {children || label}
      </div>
      {icon && iconPosition === "right" ? icon : null}
      {isLoading && <PreLoader />}
    </button>
  );
}

{
  /* <Button rootClassName="vertical-article-card" label="abc" />
<Button rootClassName="vertical-article-card" label="bcd" /> */
}
