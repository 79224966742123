import PrimaryLogo from "@assets/logos/PrimaryLogo.svg";
import Link from "@components/Link/Link";
import clsx from "clsx";
import { type ReactElement } from "react";

export type HeaderLogoProps = {
  rootClassName?: string;
  iconClassName?: string;
};

export default function HeaderLogo({
  rootClassName = "",
  iconClassName = "",
}: HeaderLogoProps): ReactElement {
  return (
    <Link
      className={clsx(
        "flex flex-row items-center justify-center px-[15px]",
        rootClassName,
      )}
      to="/"
      target="_self"
    >
      <img
        className={clsx(iconClassName, "lg:h-[54px]")}
        src={PrimaryLogo}
        alt="Zaobao"
      />
    </Link>
  );
}
