import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import viewportState from "@stores/viewport";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

import HomeDefaultLayout from "./HomeDefaultLayout";

export default function HomePage(): ReactElement {
  useGoogleAdsTargeting({ targeting: { key: "page", value: "home" } });
  const title = "首页 | 联合早报 - 享誉新加坡与国际的新闻媒体";
  const description =
    "《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。从财经、体育、生活娱乐资讯到评论分析，帮助你掌握全球趋势。";
  const { isDesktopView, isInAppBrowser } = useSnapshot(viewportState);
  const sectionAdsName = "homepage";

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  // console.log("HomePage");

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <MetaTags
        type="website"
        title={title}
        description={description}
        slug="/"
      ></MetaTags>

      <GAData level2_ga="home" title={title} adblocker={getAdBlocker()} />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="abm-google-ad masthead-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}
      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <HomeDefaultLayout />

      {isInAppBrowser ? null : <Footer />}

      {!isInAppBrowser ? (
        <CatfishGoogleAd slot={GoogleAdsSlotFactory.catfish(sectionAdsName)} />
      ) : null}
    </>
  );
}
