import { Helmet } from "react-helmet-async";

const httpDomain = import.meta.env.VITE_BFF_ENDPOINT;

export default function ArticleAppLinkMeta({ urlPath }: { urlPath: string }) {
  const zaobaoSchemeDomain = httpDomain.replace("https", "zaobao");
  const url = `${zaobaoSchemeDomain}${urlPath}`;

  return urlPath ? (
    <Helmet>
      <meta property="fb:admins" content="100000128787376" />
      <meta property="fb:pages" content="160823460655497" />
      <meta property="fb:app_id" content="398742343506351" />

      <meta property="al:ios:url" content={url} />
      <meta property="al:ios:app_store_id" content="654946831" />
      <meta property="al:ios:app_name" content="早报" />

      <meta property="al:android:url" content={url} />
      <meta
        property="al:android:package"
        content="com.zb.sph.zaobaosingapore"
      />
      <meta property="al:android:app_name" content="早报" />

      <meta property="twitter:app:name:iphone" content="早报" />
      <meta property="twitter:app:id:iphone" content="654946831" />
      <meta property="twitter:app:url:iphone" content={url} />
    </Helmet>
  ) : null;
}
