import Slider, { Settings } from "@ant-design/react-slick";
import ArrowNext from "@assets/icons/arrow-forward-icon.svg?react";
import ArrowPrev from "@assets/icons/arrow-prev-icon.svg?react";
import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import globalState from "@stores/global";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";
import { useRef } from "react";
import { useSnapshot } from "valtio";

const MultiCarousel = ({
  rootClassName = "",
  articles,
}: {
  rootClassName?: string;
  articles: Array<ArticlePreviewType> | undefined;
}) => {
  const customSlider = useRef<Slider>(null);
  const { isClientSideMounted } = useSnapshot(globalState);
  const settings: Settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={`multi-carousel relative w-full ${rootClassName}`}>
      <button
        aria-label="previous"
        className={clsx(
          "text-button1-label-inverse absolute left-[8px] top-[calc(50%-55px)] z-[2] mr-1 h-[30px] w-[30px] rounded-[50%]  bg-button-secondary-default  hover:bg-button-secondary-hover lg:left-[28px]",
        )}
        onClick={() => customSlider.current?.slickPrev()}
      >
        <div className="mx-auto h-[15px] w-[15px] text-icon-on-dark-default ">
          <ArrowPrev />
        </div>
      </button>
      <button
        aria-label="next"
        className="text-button1-label-inverse absolute right-[8px] top-[calc(50%-55px)] z-[2] h-[30px] w-[30px] rounded-[50%] bg-button-secondary-default hover:bg-button-secondary-hover lg:right-[28px]"
        onClick={() => customSlider.current?.slickNext()}
      >
        <div className="mx-auto h-[15px] w-[15px] text-icon-on-dark-default">
          <ArrowNext />
        </div>
      </button>

      {isClientSideMounted && (
        <Slider ref={customSlider} {...settings}>
          {articles?.map((article, i) => {
            return (
              <VerticalArticleCard
                article={article}
                rootClassName="inverse"
                hideTimestamp={true}
                key={i}
              />
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default MultiCarousel;
