import { DRUPAL_ENDPOINT } from "@constants/drupal";
import { HomepageSpecialTopicType } from "@typings/Data";
import axios from "axios";
import { startTransition, useEffect, useState } from "react";

type ResponseType = {
  code: number;
  response: Partial<HomepageSpecialTopicType> | undefined | null;
};

/**
 * @name useSpecialTopicFromDrupal
 * @description this is used to fetch HomePageSpecialTopic from drupal in client side
 * @todo wait BFF intergrate the route for this, can rename to useHomePageSpecialTopic,
 * @todo remove this hook in hooks directory, only place it where used, because this hook only used for homepage layout.
 *
 */

export function useSpecialTopicFromDrupal(): {
  specialTopic: ResponseType["response"];
} {
  const [data, setData] = useState<ResponseType["response"]>({});

  const requestPath = `${DRUPAL_ENDPOINT}/web2/homepage-special-topic`;

  const getInfo = async () => {
    try {
      const res = await axios.get<ResponseType>(requestPath);
      startTransition(() => {
        setData(res.data.response || {});
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    specialTopic: data,
  };
}
