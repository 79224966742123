import TickerArticleList from "@components/ArticleCardList/TickerArticleList/TickerArticleList";
import Card from "@components/Card/Card";
import { navigationDictionary } from "@constants/navigation";
import { useTickerArticles } from "@hooks/useTickerArticles";

export default function HomepageTickerArticles() {
  const { tickerArticles = [] } = useTickerArticles();
  // console.log("HomepageTickerArticles: ", tickerArticles);

  return (
    <>
      {tickerArticles.length ? (
        <Card
          isBreakingNews
          heading="即时新闻"
          headingLink={navigationDictionary.realtime.href}
        >
          <TickerArticleList articles={tickerArticles} />
        </Card>
      ) : null}
    </>
  );
}
