import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";

interface IArticleKicker {
  className?: string;
}
export default function ArticleKicker({ className = "" }: IArticleKicker) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  return (
    <div className={className}>
      <div className=" flex flex-wrap">
        {article?.kicker ? (
          <div className=" mb-[8px] mr-[8px] rounded-full bg-secondary px-[8px] text-center text-secondary">
            {article.kicker}
          </div>
        ) : null}

        {isValidArray(article?.story_threads)
          ? (article?.story_threads || []).map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.urlPath || "#"}
                  target="_self" // within the same tab
                >
                  <div className=" mb-[8px] mr-[8px] rounded-full bg-secondary px-[8px] text-center text-brand">
                    {item.name}
                  </div>
                </Link>
              );
            })
          : null}
      </div>
    </div>
  );
}
