/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import languageState from "@stores/language";
import loadScript from "@utils/loadScript";
import Cookie from "js-cookie";
import { startTransition, useEffect } from "react";
import { useSnapshot } from "valtio";
/**
 * @see https://www.w3.org/International/articles/bcp47/#macro
 * Convert Chinese characters between Simplified and Traditional Chinese.
 * @returns {convert: () => void, restore: () => void}
 */
export default function useChineseConverter() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      loadScript(
        // "https://cdn.jsdelivr.net/npm/opencc-js@1.0.5/dist/umd/full.js",
        "/_web2/opencc.min.js",
        () =>
          typeof window !== "undefined" && typeof window.OpenCC !== "undefined",
        "opencc",
      )
        .then(() => {
          const converter = window.OpenCC.Converter({
            from: "cn",
            to: "tw",
          });

          const rootNode = document.documentElement;

          const HTMLConvertHandler = window.OpenCC.HTMLConverter(
            converter,
            rootNode,
            "zh-Hans",
            "zh-TW",
          );

          const defaultIsTraditionalChinese =
            Cookie.get("configLang") === "zhtw";

          startTransition(() => {
            languageState.translationHandler = HTMLConvertHandler;
            languageState.isTraditionalChinese = defaultIsTraditionalChinese;
          });
        })
        .catch(() => {});
    }
  }, []);
}

export function useLanguageListener() {
  const { isTraditionalChinese, translationHandler } =
    useSnapshot(languageState);

  useEffect(() => {
    if (translationHandler && typeof window !== "undefined") {
      if (isTraditionalChinese) {
        translationHandler.restore();
        translationHandler.convert();
      } else {
        translationHandler.convert();
        translationHandler.restore();
      }
    }
  }, [isTraditionalChinese, translationHandler]);
}
