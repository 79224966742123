import { DRUPAL_ENDPOINT } from "@constants/drupal";
import { HomepageNewstickerType } from "@typings/Data";
import axios from "axios";
import { useEffect, useState } from "react";

type ResponseType = {
  code: number;
  response: Partial<HomepageNewstickerType> | undefined | null;
};

/**
 * @name useNewstickerFromDrupal
 * @description this is used to fetch Homepage Newsticker from drupal in client side
 * @todo wait BFF intergrate the route for this, can rename to useHomepageNewsticker,
 * @todo remove this hook in hooks directory, only place it where used, because this hook only used for homepage layout.
 *
 */

export function useNewstickerFromDrupal(): {
  newsticker: ResponseType["response"];
} {
  const [data, setData] = useState<ResponseType["response"]>({});
  const requestPath = `${DRUPAL_ENDPOINT}/web2/homepage-newsticker`;

  const getNewstickerData = async () => {
    try {
      const res = await axios.get<ResponseType>(requestPath);
      setData(res.data.response || {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getNewstickerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    newsticker: data,
  };
}
