import Link from "@components/Link/Link";
import {
  appDownloadLinks,
  footerSocialLinks,
  telephones,
} from "@constants/externalNavigation";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { type ReactElement } from "react";

export default function FooterFollowUsLinks(): ReactElement {
  return (
    <div className="flex flex-row flex-wrap">
      <div className={clsx("lg:w-12/12 mt-2 w-full")}>
        <div
          className={clsx("mb-[30px] flex flex-row flex-wrap justify-between")}
        >
          {footerSocialLinks.map((item, index) => {
            return (
              <div
                key={`social-meidia-${item.alt}`}
                className={clsx(
                  "flex flex-row items-center md:mb-[10px] lg:pr-[50px] xl:pr-6 ",
                  "basis-0",
                  "sm:w-auto sm:max-w-full sm:shrink-0 sm:grow-0 sm:basis-auto",
                )}
              >
                <Link
                  className="h-auto w-[24px] hover:text-inherit"
                  to={item.link}
                  key={index}
                  aria-label={item.alt}
                  target="_blank"
                >
                  {item.src ? (
                    <amp-img
                      alt={item.alt}
                      src={item.src}
                      width="24"
                      height="24"
                      layout="fixed"
                    ></amp-img>
                  ) : (
                    <FontAwesomeIcon
                      size="lg"
                      icon={item.faIcon as IconProp}
                      aria-label={item.alt}
                    />
                  )}
                </Link>
              </div>
            );
          })}
        </div>
        <FooterDownloadLinks />
      </div>
      <div className={clsx("lg:w-12/12 mt-2 w-full")}>
        <FooterTelphones />
      </div>
    </div>
  );
}

export function FooterTelphones(): ReactElement {
  return (
    <>
      <div className="mb-4 text-base font-medium text-white">
        {telephones.title}{" "}
      </div>
      <div className="text-control-default-secondary mb-4 text-label2 font-medium">
        {telephones.central_tel_label}
        <Link
          to={`tel:${telephones.central_tel}`}
          className={clsx(
            "notranslate text-white",
            "hover:text-white",
            "hover:underline-offset-2",
            "hover:underline",
          )}
          target="_self"
        >
          {telephones.central_tel}
        </Link>
      </div>
      <div className="text-control-default-secondary mb-4 text-label2 font-medium">
        {telephones.hotline_label}
        <Link
          to={`tel:${telephones.hotline_tel}`}
          className={clsx(
            "notranslate text-white",
            "hover:text-white",
            "hover:underline-offset-2",
            "hover:underline",
          )}
          target="_self"
        >
          {telephones.hotline_tel}
        </Link>
      </div>
      <div className="text-control-default-secondary mb-4 text-label2 font-medium">
        {telephones.hotline_ads_label}
        <Link
          to={`tel:${telephones.hotline_ads_tel}`}
          className={clsx(
            "notranslate text-white",
            "hover:text-white",
            "hover:underline-offset-2",
            "hover:underline",
          )}
          target="_self"
        >
          {telephones.hotline_ads_tel}
        </Link>
      </div>
      <div className="text-control-default-secondary mb-4 text-label2 font-medium">
        {telephones.news_hotline_label}{" "}
        <span className={clsx("xxl:whitespace-nowrap")}>
          <Link
            to={`tel:${telephones.news_hotline_tel1}`}
            target="_self"
            className={clsx(
              "notranslate text-white",
              "hover:text-white",
              "hover:underline-offset-2",
              "hover:underline",
            )}
          >
            <span className="whitespace-nowrap">
              {telephones.news_hotline_tel1}
            </span>
          </Link>
          {telephones.news_hotline_label2}
          <Link
            to={`tel:${telephones.news_hotline_tel2}`}
            target="_self"
            className={clsx(
              "notranslate text-white",
              "hover:text-white",
              "hover:underline-offset-2",
              "hover:underline",
            )}
          >
            {telephones.news_hotline_tel2}
          </Link>
        </span>
      </div>
    </>
  );
}

export function FooterDownloadLinks(): ReactElement {
  return (
    <div
      className={clsx(
        "footer-main-apps my-4",
        "lg:pr-6",
        "flex justify-between",
      )}
    >
      {appDownloadLinks.map((item, index) => (
        <Link
          className={clsx(
            "md:w-[calc(135px * 1.8)] mb-4 inline-block w-[135px] rounded border-[1px] border-[#a6a6a6] lg:w-full",
            "sm:px-0 lg:max-w-[180px]",
          )}
          to={item.link}
          key={index}
          rel=" noopener noreferrer"
          target="_blank"
        >
          <amp-img
            alt={item.alt}
            src={item.src}
            width="135"
            height="39"
            layout="responsive"
            sizes="(min-width: 400px) 178px, 135px"
          ></amp-img>
        </Link>
      ))}
    </div>
  );
}
