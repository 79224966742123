export enum AdvertisementTypeEnum {
  ABM = "abm",
  IMU1 = "imu1",
  IMU2 = "imu2",
  IMU3 = "imu3",
  LB1 = "lb1",
  LB2 = "lb2",
  CATFISH = "catfish",
  MIDARTICLE = "midarticlespecial",
  PRESTITIAL = "prestitial",
  TOPOVERLAY = "topoverlay",
}

/**
 * Configuration for Google Publisher Tag (GPT) slot sizes
 * Update new sizes here when needed e.g. midarticlespecial
 * @see https://docs.google.com/spreadsheets/d/1G0vL9aitTVpPSfy18w8EexNCk1lFrSuKbm2g58K8c-E/edit#gid=0
 */
export const adSlotSizes: Record<string, googletag.GeneralSize> = {
  abm: [[1, 1]],
  catfish: [[1, 1]],
  midarticlespecial: [
    [1, 1],
    ["fluid"],
    [640, 480],
    [300, 225],
    [480, 270],
    [480, 360],
    [640, 360],
    [300, 250],
  ],
  prestitial: [[1, 1]],
  imu1: [[1, 1], ["fluid"], [300, 250]],
  imu2: [[300, 250], [300, 600], ["fluid"]],
  imu3: [[300, 250], ["fluid"]],
  lb1: [
    [970, 90],
    [728, 90],
    [970, 250],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
  lb2: [
    [970, 90],
    [728, 90],
    [970, 250],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
};

export const adSizeMappings: Partial<
  Record<AdvertisementTypeEnum, googletag.SizeMappingArray>
> = {
  lb1: [
    [
      [1024, 0],
      [[970, 90], [728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  lb2: [
    [
      [1024, 0],
      [[970, 90], [728, 90], [970, 250], "fluid"],
    ],
    [
      [740, 0],
      [[728, 90], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
};

export type AdTargetingType = {
  key: string;
  value: string | string[];
};

export const adTargetings: Partial<
  Record<AdvertisementTypeEnum, AdTargetingType[]>
> = {
  lb1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  lb2: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  imu1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "5" },
  ],
  imu2: [
    { key: "pos", value: "2" },
    { key: "weight", value: "6" },
  ],
  imu3: [
    { key: "pos", value: "3" },
    { key: "weight", value: "7" },
  ],
  abm: [{ key: "pos", value: "abm" }],
  midarticlespecial: [
    { key: "pos", value: "midarticlespecial" },
    { key: "weight", value: "4" },
  ],
  prestitial: [
    { key: "pos", value: "prestitial" },
    { key: "weight", value: "3" },
  ],
  topoverlay: [
    { key: "pos", value: "topoverlay" },
    { key: "referrer", value: "tarref" },
    { key: "weight", value: "3" },
  ],
  catfish: [
    { key: "pos", value: "catfish" },
    { key: "weight", value: "3" },
  ],
};

/**
 * Generate the Google Ads slot id based on the parent section unique name.
 * In-article ads is having a different slot prefix with `midarticlespecial`
 */
export const GoogleAdsSlotFactory = {
  prefix: "/5908/ZB_SG",
  abm: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/abm/${sectionAdsName}`;
  },
  midarticlespecial: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/midarticlespecial/${sectionAdsName}`;
  },
  catfish: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/catfish/${sectionAdsName}`;
  },
  imu1: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu1/${sectionAdsName}`;
  },
  imu2: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu2/${sectionAdsName}`;
  },
  imu3: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu3/${sectionAdsName}`;
  },
  lb1: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/lb1/${sectionAdsName}`;
  },
  lb2: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/lb2/${sectionAdsName}`;
  },
  prestitial: (sectionAdsName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/prestitial/${sectionAdsName}`;
  },
};
