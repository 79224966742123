import Link from "@components/Link/Link";
import { ampFooterContactItems } from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement } from "react";

export default function FooterContactUs(): ReactElement {
  return (
    <div
      className={clsx(
        "mb-[30px] flex flex-wrap",
        "sm:mb-[30px] sm:mt-[30px] md:mt-0 lg:mt-[30px] xl:mt-0",
        "xl:mx-0",
      )}
    >
      {ampFooterContactItems.map((item, index) => (
        <div key={`contact-${item.key}`} className="mb-[20px] basis-6/12">
          <Link
            className={clsx(
              "text-base font-medium text-white hover:text-white",
              "hover:underline-offset-2",
              "hover:underline",
            )}
            to={item.href}
            key={index}
            target={item.label !== "加入我们" ? "_self" : "_blank"}
          >
            {item.label}
          </Link>
        </div>
      ))}
    </div>
  );
}
