export const BFF_ENDPOINT =
  import.meta.env.MODE === "development"
    ? "http://localhost:3000"
    : import.meta.env.VITE_BFF_ENDPOINT;

export const BFF_PATH = "/_plat/api/v2";

export const BFF_BASE_URL = `${BFF_ENDPOINT}${BFF_PATH}`;

console.log("Client running in", import.meta.env.MODE);
console.log("Client BFF_BASE_URL", BFF_BASE_URL);
