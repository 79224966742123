import useFontSize from "@hooks/useFontSize";
import {
  FONT_SIZE_OPTION,
  fontSizeClassNameOptions_1,
  fontSizeClassNameOptions_4,
  fontSizeClassNameOptions_7,
} from "@stores/font";
import { useEffect } from "react";

/**
 * @name useFontSizeChange
 * @description actions for article page when fontSize change
 */
export default function useFontSizeChange(renderBodyContent: string) {
  const { fontSize } = useFontSize();

  // fotsize change
  useEffect(() => {
    const articleBodyElements = document.getElementsByClassName("articleBody");
    if (articleBodyElements.length <= 0) {
      return;
    }
    const articleBody = articleBodyElements[0];

    // blockquote fontsize
    const blockquoteElements = articleBody.getElementsByTagName("blockquote");
    for (const ele of blockquoteElements) {
      Object.keys(fontSizeClassNameOptions_4).forEach((key) => {
        ele.classList.remove(
          fontSizeClassNameOptions_4[key as FONT_SIZE_OPTION],
        );
      });
      ele.classList.add(fontSizeClassNameOptions_4[fontSize]);
    }

    // figcation fontsize
    const figcaptionElements = articleBody.getElementsByTagName("figcaption");
    for (const ele of figcaptionElements) {
      Object.keys(fontSizeClassNameOptions_1).forEach((key) => {
        ele.classList.remove(
          fontSizeClassNameOptions_1[key as FONT_SIZE_OPTION],
        );
      });
      ele.classList.add(fontSizeClassNameOptions_1[fontSize]);
    }

    // sub heading fontsize
    const subHeadElements =
      articleBody.getElementsByClassName("bff-sub-heading");
    for (const ele of subHeadElements) {
      Object.keys(fontSizeClassNameOptions_7).forEach((key) => {
        ele.classList.remove(
          fontSizeClassNameOptions_7[key as FONT_SIZE_OPTION],
        );
      });
      ele.classList.add(fontSizeClassNameOptions_7[fontSize]);
    }
  }, [fontSize, renderBodyContent]);

  return {};
}
