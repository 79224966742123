import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

import AmpHeaderLogo from "./AmpHeaderComponents/AmpHeaderLogo";
import AmpNavigation from "./AmpHeaderComponents/AmpHeaderNavigation";
import AmpSocialShare from "./AmpHeaderComponents/AmpHeaderSocialShare";

export type HeaderProps = {
  rootClassName?: string;
};

export default function AmpHeader({
  rootClassName = "",
}: HeaderProps): ReactElement {
  return (
    <>
      <amp-sidebar
        id="sidebar"
        class={clsx("w-[68vw] bg-[--color-red-500]")}
        layout="nodisplay"
        side="left"
      >
        <button
          className="w-full bg-white py-[15px] pl-[20px] text-left"
          {...{ on: "tap:sidebar.close" as string }}
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faClose} color="#9e9e9e" size="2x" />
        </button>
        <AmpNavigation />
      </amp-sidebar>

      <header
        className={clsx(
          "amp-header",
          "sticky top-0 z-50 border-b border-solid border-[--color-red-500] bg-white py-[6px]",
          rootClassName,
        )}
        role="banner"
        id="header"
      >
        <nav className="relative flex min-h-[38px] items-center">
          <div
            className="hamburger absolute z-20 px-[15px] text-4xl text-[--color-grey-300]"
            role="button"
            {...{ on: "tap:sidebar.open" as string }}
            tabIndex={0}
          >
            ☰
          </div>
          <AmpHeaderLogo rootClassName="absolute left-0 right-0 z-10 mx-auto " />
          <div className="social-btns absolute right-0 z-20 flex items-center">
            <Link
              aria-label="订阅"
              to="https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header"
              className="border-button-tertiary flex min-h-[30px] flex-row items-center space-x-[8px] rounded-button-radius-full bg-button-primary-default !px-[16px] px-[16px] text-center text-button1 text-white hover:bg-[--color-red-550] hover:text-white"
              target="_self"
            >
              订阅
            </Link>
            <AmpSocialShare />
          </div>
        </nav>
      </header>
    </>
  );
}
