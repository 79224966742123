import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/index.css";

import WavePaywall from "@components/WavePaywall/WavePaywall";
import useCasPaywall from "@hooks/useCasPaywall";
import useChineseConverter, {
  useLanguageListener,
} from "@hooks/useChineseConverter";
import useDevice from "@hooks/useDevice";
import useGoogleAds from "@hooks/useGoogleAds";
import { fetchEnvelope, useLiveRampV2 } from "@hooks/useLiverampV2";
import useMySPH from "@hooks/useMySPH";
import { RenderContext } from "@sphtech/web2-core/ssr";
import globalState from "@stores/global";
import HeadScriptProvider from "@web2/providers/HeadScriptProvider";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import React, { type ReactElement, useContext, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function App(): ReactElement {
  useDevice();
  useCasPaywall();
  useMySPH();
  useGoogleAds();
  useChineseConverter();
  useLanguageListener();
  useLiveRampV2({ fetchEnvelope });

  const renderContext = useContext(RenderContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // console.log(
      //   "hydrated!",
      //   (performance.now() - window.startHydration).toFixed(2) + "ms",
      // );
      globalState.isClientSideMounted = true;
    }
  }, []);

  return (
    <React.StrictMode>
      <HeadScriptProvider />
      <ScrollRestoration />
      <HelmetProvider context={renderContext.helmet}>
        <NewRelic />
        <NeuronProvider>
          <Outlet />
          <WavePaywall />
          <span
            data-testid="package-version-tag"
            data-version={__APP_VERSION__}
            className="hidden"
          >
            {__APP_VERSION__}10.29.4:40pm
          </span>
        </NeuronProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}
