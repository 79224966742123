import fontSizeIcon from "@assets/icons/font-size.svg";
import Button from "@components/Button/Button";
import Link from "@components/Link/Link";
import SubscriptionPrompt from "@components/SubscriptionPrompt/SubscriptionPrompt";
import { useLockBodyScroll } from "@hooks/useBodyScroll";
import { useLanguageListener } from "@hooks/useChineseConverter";
import useSticky from "@hooks/useSticky";
import fontState from "@stores/font";
import globalState from "@stores/global";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { type ReactElement, useEffect, useState } from "react";
import { useSnapshot } from "valtio";

import ExtraNavigationItems from "./ExtraNavigationItems";
import FontSizeSwitch from "./FontSizeSwitch";
import HamburgerMenu from "./HamburgerMenu";
import HeaderLogo from "./HeaderLogo";
import LanguageSwitch from "./LanguageSwitch";
import LoggedInPopover from "./LoggedInPopover";
import Logo from "./Logo";
import MobileWebsiteDropdown from "./MobileWebsiteDropdown";
import NonLoggedInPopover from "./NonLoggedInPopover";
import SearchInput from "./SearchInput";
import SideNavigation from "./SideNavigation";
import SubscriberButton from "./SubscriberButton";

export type HeaderMobileProps = {
  rootClassName?: string;
};

export default function HeaderMobile({
  rootClassName = "",
}: HeaderMobileProps): ReactElement {
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
  const [isUserPanelVisible, setIsUserPanelVisible] = useState<boolean>(false);
  const { user } = useSnapshot(userState);
  const { isHeaderStuck } = useSnapshot(globalState);
  const { refPassthrough, sticky } = useSticky({
    threshold: 0.01,
  });
  //   !!user?.display_name && user.usertype === OKTAUserTypeEnum.REGISTETERED;
  const isSubscriber =
    !!user?.display_name && user.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  useEffect(() => {
    globalState.isHeaderStuck = sticky;
  }, [sticky]);

  return (
    <>
      <div id="sticky_mobile_header_ref" ref={refPassthrough}></div>
      <header
        className={clsx(
          "border-b-secondary layout-container z-[100] flex h-[45px] max-w-full items-center justify-between border-b bg-primary",
          { relative: !isHeaderStuck, "fixed left-0 top-0": isHeaderStuck },
          rootClassName,
        )}
        id="mobile-header"
      >
        <HamburgerMenu
          isExpanded={isNavVisible}
          onExpand={() => {
            setIsNavVisible(!isNavVisible);
            !isNavVisible && setIsUserPanelVisible(false);
          }}
        />

        <Logo
          rootClassName={clsx({
            hidden: !isHeaderStuck,
          })}
        />

        <HeaderLogo
          rootClassName={clsx(
            "absolute left-[50%] top-[50%] w-[220px] -translate-x-2/4 -translate-y-2/4 transform transition-all duration-[400ms] ease-in-out",
            {
              "invisible -translate-x-[150px] opacity-0": isHeaderStuck,
            },
          )}
          iconClassName="h-[30px]"
        />

        {/* header stuck subscribe button */}
        <Link
          className={clsx(
            "border-control-default-primary ml-auto flex h-[24px] flex-row items-center rounded-button-radius-full bg-brand-primary px-[8px] text-mobile-button tracking-[2px] text-white hover:text-white",
            {
              invisible: !isHeaderStuck || isSubscriber,
            },
          )}
          aria-label="订阅"
          target="_blank"
          to="https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header"
        >
          订阅
        </Link>

        <SubscriberButton
          rootClassName="ml-[16px]"
          hasLabel={false}
          onClick={() => {
            setIsUserPanelVisible(!isUserPanelVisible);
            !isUserPanelVisible && setIsNavVisible(false);
          }}
        />

        {isNavVisible ? (
          <NavigationPanel
            isNavVisible={isNavVisible}
            setIsNavVisible={setIsNavVisible}
          />
        ) : null}
        {isUserPanelVisible ? <UserPanel /> : null}
      </header>
    </>
  );
}

function NavigationPanel(props: {
  isNavVisible: boolean;
  setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  useLockBodyScroll();
  useLanguageListener();
  const { isNavVisible, setIsNavVisible } = props;
  const { height, isHorizontal, isPortrait } = useSnapshot(viewportState);
  const { fontSize } = useSnapshot(fontState);
  const { user } = useSnapshot(userState);
  const { isHeaderStuck } = useSnapshot(globalState);
  const [rootClassName, setRootClassName] = useState("");
  const [rootStyle, setRootStyle] = useState({});

  const handleOpenMySPHLightbox = () => {
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openLogin();
    }
  };

  useEffect(() => {
    const getHeaderOffSet =
      document.getElementById("mobile-header")?.getBoundingClientRect().top ||
      0;
    // const lb1AdHeight =
    //   document.getElementById("dfp-ad-lb1")?.clientHeight || 0;

    const rootHeight = !isHeaderStuck
      ? `${(height || 0) - getHeaderOffSet - 45}px`
      : `${(height || 0) - 45}px`;

    setRootStyle({
      height: rootHeight,
    });
  }, [height, isHeaderStuck, isHorizontal, isPortrait]);

  useEffect(() => {
    setRootClassName("overflow-scroll overscroll-contain");

    return () => {
      setRootClassName("");
    };
  }, []);

  return (
    <div
      className={clsx(
        "no-scrollbar absolute left-0 right-0 top-[45px] z-10 flex h-0 flex-row justify-center overflow-hidden bg-tertiary text-base transition-all duration-[400ms]",
        rootClassName,
      )}
      style={rootStyle}
      aria-labelledby="topnavbar-toggler-mobile"
      id="topnavbar-toggler-mobile"
      data-testid="topnavbar-toggler-mobile"
    >
      <div className="layout-container pb-[50px]">
        <SubscriptionPrompt
          type="brand"
          rootClassName={clsx({
            hidden: user?.usertype === OKTAUserTypeEnum.SUBSCRIBER,
          })}
        />

        <div className="relative my-[16px] flex justify-between">
          <MobileWebsiteDropdown />

          <Button
            type="tertiary"
            rootClassName={clsx("absolute -top-[3px] right-0 z-10 h-[30px]", {
              hidden: !!user?.display_name,
            })}
            onClick={handleOpenMySPHLightbox}
          >
            登录/免费注册
          </Button>
        </div>

        <div className="separator-vertical"></div>

        <ExtraNavigationItems
          rootClassName="mt-[16px]"
          navigationItemClassName={fontSize}
        />

        <div
          className="mb-[16px] mt-[24px] flex flex-row justify-between"
          id="fontsize-switch"
          data-testid="fontsize-switch"
        >
          <div className="flex flex-row">
            <img src={fontSizeIcon} alt="fontsizing" />
            <FontSizeSwitch />
          </div>

          <LanguageSwitch />
        </div>

        <div className="separator-vertical-brand"></div>

        <SearchInput
          rootClassName="bg-tertiary !px-0 pb-0 pt-[10px]"
          fontClassName="text-base"
          iconPosition="right"
          showButton={false}
          onSubmit={() => {
            setIsNavVisible(!isNavVisible);
          }}
        />

        <SideNavigation />
      </div>
    </div>
  );
}

function UserPanel() {
  useLockBodyScroll();
  useLanguageListener();
  const { height, isHorizontal, isPortrait } = useSnapshot(viewportState);
  const { user } = useSnapshot(userState);
  const { isHeaderStuck } = useSnapshot(globalState);
  const [rootClassName, setRootClassName] = useState("");
  const [rootStyle, setRootStyle] = useState({});

  useEffect(() => {
    const getHeaderOffSet =
      document.getElementById("mobile-header")?.getBoundingClientRect().top ||
      0;
    // const lb1AdHeight =
    //   document.getElementById("dfp-ad-lb1")?.clientHeight || 0;

    const rootHeight = !isHeaderStuck
      ? `${(height || 0) - getHeaderOffSet - 45}px`
      : `${(height || 0) - 45}px`;

    setRootStyle({
      height: rootHeight,
    });
  }, [height, isHeaderStuck, isHorizontal, isPortrait]);

  useEffect(() => {
    setRootClassName("overflow-scroll overscroll-contain");

    return () => {
      setRootClassName("");
    };
  }, []);

  return (
    <div
      className={clsx(
        "no-scrollbar absolute left-0 right-0 top-[45px] z-10 flex h-0 w-[100vw] flex-row justify-center overflow-y-scroll transition-[height] duration-[400ms]",
        rootClassName,
      )}
      style={rootStyle}
      aria-labelledby="mobile-user-dropdown"
      id="mobile-user-not-login"
      data-testid="mobile-user-not-login"
    >
      <div className="layout-container h-full px-0">
        {!user?.display_name ? (
          <NonLoggedInPopover rootClassName="h-full w-full shadow-none" />
        ) : (
          <LoggedInPopover rootClassName="h-full w-full shadow-none" />
        )}
      </div>
    </div>
  );
}
