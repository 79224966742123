import { DropdownMenu } from "@components/Dropdown/Dropdown";
import { navigationDictionary } from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement, useState } from "react";

export default function WebsiteDropdownMobile(): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div id="mobile-version-toggle" data-testid="mobile-version-toggle">
      <button
        className={clsx(
          "label-clickable relative flex flex-row items-center text-base tracking-[2px] ",
          "after:content-dropdown-triangle after:border-y-separator after:text-secondary after:transition-transform after:duration-500",
          {
            "text-brand after:!text-brand after:[transform:rotate3d(1,0,0,180deg)]":
              isExpanded,
            "text-secondary": !isExpanded,
          },
        )}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        {navigationDictionary.site_sg.label}
      </button>

      <div
        className={clsx(
          "max-h-0 overflow-hidden transition-[max-height] duration-[500ms] ease-in-out",
          {
            "max-h-[999px]": isExpanded,
          },
        )}
        aria-labelledby="mobile-version-toggle"
      >
        <DropdownMenu
          rootClassName="!relative !top-0 !block !columns-1 !text-left text-secondary shadow-none"
          subMenuItems={[
            navigationDictionary.site_global,
            navigationDictionary.site_cn,
          ]}
        />
      </div>
    </div>
  );
}
