// Modify from menu-config.yaml
// then run `npm run gen-menu`
import { NavigationItem } from "@constants/navigation";
export const siteMenu: Array<NavigationItem> = [
  {
    label: "首页",
    href: "/",
    key: "home",
  },
  {
    label: "新闻",
    href: "/news",
    key: "news",
    children: [
      {
        label: "即时",
        href: "/realtime",
        key: "realtime",
      },
      {
        label: "新加坡",
        href: "/news/singapore",
        key: "news_singapore",
      },
      {
        label: "国际",
        href: "/news/world",
        key: "news_world",
      },
      {
        label: "中国",
        href: "/news/china",
        key: "news_china",
      },
      {
        label: "东南亚",
        href: "/news/sea",
        key: "news_sea",
      },
    ],
  },
  {
    label: "财经",
    href: "/finance",
    key: "finance",
    children: [
      {
        label: "新加坡股市",
        href: "https://stock.zaobao.com.sg/",
        key: "finance_stock",
      },
      {
        label: "新加坡财经",
        href: "/finance/singapore",
        key: "finance_singapore",
      },
      {
        label: "全球财经",
        href: "/finance/world",
        key: "finance_world",
      },
      {
        label: "中国财经",
        href: "/finance/china",
        key: "finance_china",
      },
      {
        label: "投资理财",
        href: "/finance/invest",
        key: "finance_invest",
      },
      {
        label: "房产",
        href: "/finance/real-estate",
        key: "finance_real-estate",
      },
      {
        label: "美国股市",
        href: "/finance/usstock",
        key: "finance_usstock",
      },
      {
        label: "中小企业",
        href: "/finance/sme",
        key: "finance_sme",
      },
      {
        label: "起步创新",
        href: "/finance/startup",
        key: "finance_startup",
      },
      {
        label: "财经人物",
        href: "/finance/personalities",
        key: "finance_personalities",
      },
    ],
  },
  {
    label: "言论",
    href: "/forum",
    key: "forum",
    children: [
      {
        label: "社论",
        href: "/forum/editorial",
        key: "forum_editorial",
      },
      {
        label: "评论",
        href: "/forum/views",
        key: "forum_views",
      },
      {
        label: "交流站",
        href: "/forum/talk",
        key: "forum_talk",
      },
      {
        label: "漫画",
        href: "/forum/comic",
        key: "forum_comic",
      },
    ],
  },
  {
    label: "娱乐",
    href: "/entertainment",
    key: "entertainment",
    children: [
      {
        label: "明星",
        href: "/entertainment/celebs",
        key: "entertainment_celebs",
      },
      {
        label: "影视",
        href: "/entertainment/movies-and-tv",
        key: "entertainment_movies-and-tv",
      },
      {
        label: "音乐",
        href: "/entertainment/music",
        key: "entertainment_music",
      },
      {
        label: "韩流",
        href: "/entertainment/k-pop",
        key: "entertainment_k-pop",
      },
      {
        label: "送礼",
        href: "/entertainment/giveaway",
        key: "entertainment_giveaway",
      },
    ],
  },
  {
    label: "生活",
    href: "/lifestyle",
    key: "lifestyle",
    children: [
      {
        key: "specials_active-aging",
        label: "壮龄go!",
        href: "/specials/active-aging",
      },
      {
        label: "特写",
        href: "/lifestyle/feature",
        key: "lifestyle_feature",
      },
      {
        label: "美食",
        href: "/lifestyle/food",
        key: "lifestyle_food",
      },
      {
        label: "旅行",
        href: "/lifestyle/travel",
        key: "lifestyle_travel",
      },
      {
        label: "文化艺术",
        href: "/lifestyle/culture",
        key: "lifestyle_culture",
      },
      {
        label: "人文史地",
        href: "/lifestyle/history-heritage",
        key: "lifestyle_history-heritage",
      },
      {
        label: "专栏",
        href: "/lifestyle/columns",
        key: "lifestyle_columns",
      },
      {
        label: "@世代",
        href: "/lifestyle/gen",
        key: "lifestyle_gen",
      },
      {
        label: "生态与环保",
        href: "/lifestyle/eco",
        key: "lifestyle_eco",
      },
      {
        label: "时尚与美容",
        href: "/lifestyle/fashion-beauty",
        key: "lifestyle_fashion-beauty",
      },
      {
        label: "设计与家居",
        href: "/lifestyle/design-decor",
        key: "lifestyle_design-decor",
      },
      {
        label: "光影",
        href: "/lifestyle/photography",
        key: "lifestyle_photography",
      },
      {
        label: "科玩",
        href: "/lifestyle/gadget",
        key: "lifestyle_gadget",
      },
      {
        label: "科普",
        href: "/lifestyle/tech-science",
        key: "lifestyle_tech-science",
      },
      {
        label: "汽车",
        href: "/lifestyle/motoring",
        key: "lifestyle_motoring",
      },
      {
        label: "心事家事",
        href: "/lifestyle/familynlove",
        key: "lifestyle_familynlove",
      },
      {
        label: "精选",
        href: "/lifestyle/others",
        key: "lifestyle_others",
      },
      {
        label: "特辑",
        href: "/lifestyle/supplement",
        key: "lifestyle_supplement",
      },
      {
        label: "早报校园",
        href: "/lifestyle/campus",
        key: "lifestyle_campus",
      },
      {
        label: "热门",
        href: "/lifestyle/trending",
        key: "lifestyle_trending",
      },
      {
        label: "生活贴士",
        href: "/lifestyle/life-hacks",
        key: "lifestyle_life-hacks",
      },
      {
        label: "星座与生肖",
        href: "/horoscope",
        key: "lifestyle_horoscope",
      },
    ],
  },
  {
    label: "保健",
    href: "/lifestyle/health",
    key: "lifestyle_health",
  },
  {
    label: "体育",
    href: "/news/sports",
    key: "news_sports",
  },
  {
    label: "视频",
    href: "/videos",
    key: "videos",
    children: [
      {
        label: "新闻",
        href: "/videos/news",
        key: "videos_news",
      },
      {
        label: "娱乐",
        href: "/videos/entertainment",
        key: "videos_entertainment",
      },
      {
        label: "生活",
        href: "/videos/lifestyle",
        key: "videos_lifestyle",
      },
      {
        label: "系列节目",
        href: "/video-series",
        key: "video-series",
      },
      {
        label: "直播",
        href: "/livestream",
        key: "livestream",
      },
    ],
  },
  {
    label: "早报播客",
    href: "/podcast",
    key: "podcast",
  },
  {
    label: "互动新闻",
    href: "/interactive-graphics",
    key: "interactive-graphics",
  },
  {
    label: "专题",
    href: "/special",
    key: "special",
  },
];
