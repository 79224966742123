import Link from "@components/Link/Link";
import Timestamp from "@components/Timestamp/Timestamp";
import { ArticlePreviewType } from "@typings/Data";
import { ReactElement } from "react";

const ImmediateArticleCard = ({
  rootClassName = "",
  article,
}: {
  rootClassName?: string;
  article: ArticlePreviewType;
}): ReactElement => {
  return (
    <div
      className={`card immediate-article-card ${rootClassName}`}
      id={`test-realtime-article-card-${article.id}`}
      data-testid={`test-realtime-article-card-${article.id}`}
    >
      <div className="card-content">
        <Link to={article.href}>
          <h2 className="card-header">{article.title}</h2>
        </Link>

        <div className="text-label4 text-secondary">
          <Timestamp parsable={article.timestamp} format="relative" />
        </div>
      </div>
    </div>
  );
};

export default ImmediateArticleCard;
