import { proxy } from "valtio";

type GlobalStateType = {
  isGAReady: boolean;
  isHeaderStuck: boolean;
  isGoogleAdsReady: boolean;
  isClientSideMounted: boolean;
};

const globalState = proxy<GlobalStateType>({
  isGAReady: false,
  isHeaderStuck: false,
  isGoogleAdsReady: false,
  isClientSideMounted: false,
});

export default globalState;
