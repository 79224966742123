import ErrorPage from "@pages/Others/Error/Error";
import PrerenderPage from "@pages/Prerender/Prerender";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import App from "./App";

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        path: "/:level1?/:level2?/:level3?/:level4?/:level5?/:level6?",
        element: <PrerenderPage />,
        loader: fetchContext,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
