import XmrbLogo from "@assets/icons/logo-shinmin.svg?react";
import Tick from "@assets/icons/tick.svg?react";
import ZbLogo from "@assets/logos/PrimaryLogo.svg?react";
import Button from "@components/Button/Button";
import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import Link from "@components/Link/Link";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import LandingPageTemplate from "@components/Templates/LandingPageTemplate";
import Timestamp from "@components/Timestamp/Timestamp";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useParams from "@hooks/useSafeParams";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import axios from "axios";
import {
  Fragment,
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from "react";
import { useSnapshot } from "valtio";

export type epaperObject = {
  id: number;
  publishedDate: string;
  coverImageUri: string;
  title?: string;
  description?: string[];
  pub?: string;
  msg?: string;
};
export type epaperContext = {
  issues: [epaperObject];
  errorCode: number;
  errorMessage: string;
};

const url: string | undefined = import.meta.env.VITE_EPAPER_URL as
  | string
  | undefined;

console.log(url, "epaper url");

const EpapersDefaultLayout = (): ReactElement => {
  const { user } = useSnapshot(userState);
  console.log(JSON.stringify(user), "user");
  const { level1 = "", level2 = "" } = useParams();
  const levelKey = [level1, level2].filter(Boolean).join("_");
  const [zbObject, setZbObject] = useState<Partial<epaperObject>>({
    title: "《联合早报》印刷 + 数码配套",
    description: [
      "每日发行的印刷版《联合早报》",
      "可通过早报应用和网站无限畅读订户专享内容",
      "可同时在四台智能设备登录早报应用",
      "可阅读长达两周的早报电子报",
    ],
    pub: "zb",
  });
  const [smObject, setSmObject] = useState<Partial<epaperObject>>({
    title: "《新明日报》多合一综合配套",
    description: [
      "此配套包含新明印刷版及数码版",
      "可登录 shinmin.sg 网站阅读新明电子报",
      "可同时在四台智能设备登录早报应用阅读新明电子报",
      "可阅读长达两周的电子报",
    ],
    pub: "sm",
  });

  useEffect(() => {
    epaperApi("zb")
      .then((result) => {
        if (result.msg) {
          setZbObject({});
        } else {
          setZbObject({
            ...result,
            title: "《联合早报》印刷 + 数码配套",
            description: [
              "每日发行的印刷版《联合早报》",
              "可通过早报应用和网站无限畅读订户专享内容",
              "可同时在四台智能设备登录早报应用",
              "可阅读长达两周的早报电子报",
            ],
            pub: "zb",
          });
        }
      })
      .catch((err) => {
        if (err) {
          return "something went wrong";
        }
      });

    epaperApi("sm")
      .then((result) => {
        if (result.msg) {
          setSmObject({});
        } else {
          setSmObject({
            ...result,
            title: "《新明日报》多合一综合配套",
            description: [
              "此配套包含新明印刷版及数码版",
              "可登录 shinmin.sg 网站阅读新明电子报",
              "可同时在四台智能设备登录早报应用阅读新明电子报",
              "可阅读长达两周的电子报",
            ],
            pub: "sm",
          });
        }
      })
      .catch((err) => {
        if (err) {
          return "something went wrong";
        }
      });
  }, []);

  const renderFullWidthBanner = () => {
    return <HomepageFullWidthBanner additionalClasses="!mb-0" />;
  };

  const renderSectionNavigation: () => ReactNode = () => {
    return <SectionNavigation level={levelKey} />;
  };

  const PreLoader: () => ReactNode = () => {
    return (
      <div className="mx-auto flex h-[348px] w-[50px] justify-center pb-[16px] lg:h-[506px]">
        <FontAwesomeIcon
          icon={faSpinner}
          spin={true}
          size={"4x"}
          className={"my-auto"}
        />
      </div>
    );
  };

  const renderPageContent: () => ReactNode = () => {
    return (
      <Fragment>
        <div className="layout-container min-h-[40vh]">
          <div className="row">
            <div className="w-full px-8 py-[16px]">
              <div id="epaper-header" className="">
                <h3 className="text-left text-body1 text-primary">
                  选择最适合您的订阅配套，享受多媒体读报体验
                </h3>
                <div className="text-left">
                  <Link
                    to="https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-forsubs-epapersection&_nei=e54f2dfc-26a9-48f3-8a68-13205db2e1a8"
                    className="border-b-[1px] border-link-in-component-brand-secondary pt-[8px] text-body1 text-link-in-component-brand-secondary hover:border-link-in-component-brand-secondary hover:text-link-in-component-brand-secondary"
                    target="_blank"
                  >
                    查看更多《联合早报》订阅配套
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row relative overflow-auto px-8 pt-[16px]">
            <div className="relative flex w-full snap-x snap-mandatory space-x-[16px] overflow-x-auto lg:overflow-visible">
              {[zbObject, smObject].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative w-[260px] shrink-0 snap-center bg-tertiary px-8 py-[16px] md:w-[70%] lg:w-1/2"
                  >
                    <div id="epaper-header" className="pb-[16px] text-center">
                      <h4 className="text-header4">
                        <Timestamp
                          parsable={
                            item.publishedDate
                              ? item.publishedDate
                              : new Date().toString()
                          }
                          format="weekday"
                        />
                      </h4>
                      <p className="text-label2 text-secondary">
                        <Timestamp
                          parsable={
                            item.publishedDate
                              ? item.publishedDate
                              : new Date().toString()
                          }
                          format="epaper"
                        />
                      </p>
                    </div>

                    <button
                      id="epaper-image-container"
                      className="mx-auto flex justify-center pb-[16px]"
                      onClick={() => {
                        goToEpaper(item.pub as string);
                      }}
                    >
                      <div className="h-[348px] w-auto lg:h-[506px]">
                        {item.coverImageUri ? (
                          <Suspense fallback={<PreLoader />}>
                            <img
                              alt={item.id?.toString()}
                              src={item.coverImageUri}
                              className="h-[348px] w-auto lg:h-[506px]"
                              rel="preload"
                            />
                          </Suspense>
                        ) : (
                          <Fragment>
                            {item.pub === "zb" ? (
                              <ZbLogo height={"auto"} width={"161"} />
                            ) : item.pub === "sm" ? (
                              <XmrbLogo height={"auto"} width={"161"} />
                            ) : null}
                          </Fragment>
                        )}
                      </div>
                    </button>

                    <div
                      id="content-container"
                      className="flex flex-col justify-center"
                    >
                      <h2 className="pb-[16px] text-center text-header2 text-primary">
                        {item.title}
                      </h2>
                      <ul className="mx-auto pb-[16px] text-body2">
                        {item.description?.map((des, index) => {
                          return (
                            <li key={index} className="flex text-brand">
                              <Tick />
                              <p className="pl-[8px] text-secondary">{des}</p>
                            </li>
                          );
                        })}
                      </ul>
                      <Button
                        type="primary"
                        size="large"
                        rootClassName="mx-auto max-w-max"
                        onClick={() => {
                          goToEpaper(item.pub as string);
                        }}
                      >
                        {user?.usertype === OKTAUserTypeEnum.SUBSCRIBER
                          ? "马上阅读"
                          : "立即订阅"}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <LandingPageTemplate
      topSlots={[renderSectionNavigation, renderFullWidthBanner]}
      mainSlots={[renderPageContent]}
    />
  );
};

export default EpapersDefaultLayout;

const epaperApi = async (pub: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = await axios
    .get(`https://e-paper.sph.com.sg/_plat/api/v1/${pub}/archives`)
    .then(({ data }: { data: epaperContext }) => {
      console.log(data, "data");
      return data;
    });

  if (data.errorCode) {
    return { msg: data.errorMessage };
  }

  return data.issues[0];
};

const goToEpaper = (pub: string) => {
  window.open(url + pub);
};
