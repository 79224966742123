import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";

interface IArticlePagination {
  className?: string;
}
export default function ArticlePagination({
  className = "",
}: IArticlePagination) {
  const {
    context: {
      payload: { prevNextArticles = [] },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const preArticle = prevNextArticles[1];
  const nextArticle = prevNextArticles[0];

  return (
    <div className={className}>
      {/* prevNextArticles */}
      {isValidArray(prevNextArticles) && (
        <div className="relative flex items-stretch justify-between overflow-hidden rounded-lg bg-tertiary">
          {preArticle ? (
            <Link
              to={preArticle.href || "#"}
              className=" relative w-full flex-1 pt-[24px] text-link-headline-default no-underline hover:text-brand-secondary"
              target="_self" // within the same tab
            >
              <div className=" absolute left-0 top-0">
                <button
                  className="h-[24px] cursor-default rounded-br-[16px] bg-success-secondary px-[16px] text-center text-[16px] font-medium text-brand-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  上一篇
                </button>
              </div>

              <div className=" my-[16px] line-clamp-3 w-full px-[20px] text-[18px] font-normal">
                {preArticle.title}
              </div>
            </Link>
          ) : null}

          {nextArticle && preArticle ? (
            <div className="mb-[16px] mt-[40px] w-[1px] bg-divider-primary"></div>
          ) : null}

          {nextArticle ? (
            <Link
              to={nextArticle.href || "#"}
              className=" relative w-full flex-1 pt-[24px] text-link-headline-default no-underline hover:text-brand-secondary"
              target="_self" // within the same tab
            >
              <div className=" absolute right-0 top-0">
                <button
                  className="h-[24px] cursor-default rounded-bl-[16px] bg-success-secondary px-[16px] text-center text-[16px] font-medium text-brand-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  下一篇
                </button>
              </div>

              <div className=" my-[16px] line-clamp-3 w-full px-[20px] text-[18px] font-normal">
                {nextArticle.title}
              </div>
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
}
