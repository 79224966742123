import { DRUPAL_ENDPOINT } from "@constants/drupal";
import useParams from "@hooks/useSafeParams";
import { FullWidthBannerType } from "@typings/Data";
import { getRequestFieldName } from "@utils/drupal-apis";
import axios from "axios";
import { useEffect, useState } from "react";

type ResponseType = {
  code: number;
  response: FullWidthBannerType | undefined | null;
};

/**
 * @description higher priority than state level1/level2
 */
type Props = {
  level1?: string;
  level2?: string;
};

export function useFullWidthBannerFromDrupal(props?: Props): {
  fullWidthBanner: FullWidthBannerType;
} {
  const [data, setData] = useState<FullWidthBannerType>([]);

  const { level1: level1Prop, level2: level2Prop } = props || {};
  const { level1: level1Param, level2: level2Param } = useParams();
  const level1 = level1Prop || level1Param || "";
  const level2 = level2Prop || level2Param || "";
  const field = getRequestFieldName(level1, level2);
  const query = [level1, level2].filter(Boolean).join("_");

  const requestPath = `${DRUPAL_ENDPOINT}/web2/fullwidth-banner?${field}=${query}`;

  const getInfo = async () => {
    try {
      const res = await axios.get<ResponseType>(requestPath);
      setData(res.data.response || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fullWidthBanner: data,
  };
}
