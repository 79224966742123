import CancelIcon from "@assets/icons/x-icon.svg?react";
import PrimaryLogo from "@assets/logos/PrimaryLogo.svg";
import Button from "@components/Button/Button";
import useEmarsys from "@hooks/useEmarsys";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";

export default function NewsletterSignUpForm() {
  const { message, status, handleVerifyEmail } = useEmarsys();
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const { isMobileView } = useSnapshot(viewportState);

  const { level1 = "" } = useParams();

  return (
    <>
      {isMobileView ? <div className="pt-[16px]"></div> : null}
      <div
        id="newsletter"
        className={clsx(
          "my-[20px]",
          "lg:mb-[16px] lg:mt-[48px]",
          "px-[20px] py-[24px]",
          "lg:p-[40px]",
          "relative rounded-[--radius-sm] border-[1px] border-divider-highlight-secondary",
          "flex flex-col",
          "lg:flex-row lg:items-center",
          { "lg:mb-[32px]": level1 === "" },
        )}
      >
        <div className="absolute -top-[17.5px] left-0 flex w-full flex-row items-center justify-center">
          <Button
            rootClassName="h-auto py-[2px]"
            labelClassName="text-header3"
            type="yellow"
            clickable={false}
          >
            新闻速递
          </Button>
        </div>

        <div className="zb-logo mb-8 mr-16 w-[103px] lg:mb-[unset] lg:min-w-[130px]">
          <img src={PrimaryLogo} alt="Zaobao" className="w-full" />
        </div>

        {/* <div className="w-[1px] border-r-[1px] border-divider-primary"></div> */}

        <div className="newsletter-content flex w-full flex-col gap-[8px] lg:border-l-[1px] lg:border-divider-primary lg:pl-16">
          <p className="text-header1 text-primary">
            每日新闻电邮速递，带给你最即时新闻报道。
          </p>

          <div className="newsletter-form">
            <div className="relative flex flex-col lg:flex-row">
              <div className="relative mb-[8px] mr-[8px] w-full rounded-button-radius-full lg:mb-[unset] lg:w-[calc(100%-124px)]">
                <input
                  className={clsx(
                    "h-[40px] w-full rounded-button-radius-full border border-input-border-default pl-[16px] pr-[40px] text-body2 caret-input-text-input focus:border-input-border-active focus:text-input-text-input",
                    "placeholder:text-body2 placeholder:text-input-text-label",
                    "lg:flex-1",
                    {
                      "border-input-border-error focus:border-input-border-error":
                        status === "FAILED" && showMsg,
                    },
                  )}
                  type="text"
                  aria-required="true"
                  maxLength={254}
                  placeholder={"输入您的电子邮件"}
                  value={email}
                  name="email_address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      handleVerifyEmail(email);
                    }
                  }}
                />
                {showMsg && message ? (
                  <div
                    data-testid="response"
                    id="response"
                    className={clsx(
                      "newsletter-form-response px-[16px] pt-[4px] text-label5",
                      {
                        "text-input-text-error": status == "FAILED" && showMsg,
                        "text-input-text-success":
                          status == "SUCCESS" && showMsg,
                      },
                    )}
                  >
                    {message}
                  </div>
                ) : null}
                {email !== "" ? (
                  <button
                    className="absolute right-[16px] top-4 h-[20px] w-[20px] text-icon-secondary-default"
                    type="button"
                    onClick={() => {
                      setEmail("");
                      setShowMsg(false);
                    }}
                  >
                    <CancelIcon />
                  </button>
                ) : null}
              </div>
              <Button
                rootClassName={clsx(
                  "h-[40px] w-full justify-center text-button1 lg:w-max",
                )}
                size={"large"}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  handleVerifyEmail(email);
                  setShowMsg(true);
                }}
              >
                立即注册
              </Button>
            </div>
          </div>

          <div className="newsletter-description">
            <p className="text-body2 text-primary">
              当您提交以上信息，即表示您已同意我们的
              <Link
                className="underline hover:text-primary"
                to="https://www.sph.com.sg/tnc/privacy"
              >
                隐私政策
              </Link>
              以及
              <Link
                className="underline hover:text-primary"
                to="https://www.sph.com.sg/legal/website_tnc/"
              >
                条款与条件
              </Link>
              。
            </p>
            <a
              className="text-body2 text-brand-secondary underline hover:text-brand-secondary"
              href="https://www.zaobao.com.sg/newsletter-signup"
              target="_blank"
              rel="noreferrer"
            >
              更多精彩新闻速递服务
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
