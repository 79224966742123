const permutiveSphl = `
  // this file content is included in index.html file
  
/**
 * Permutive sphl
 */
if (typeof window !== "undefined") {
  var per_articleid = "";
  var per_chapter1 = "";
  var per_chapter2 = "";
  var per_chapter3 = "";
  var per_content_category = "";
  var per_content_type = "";
  var per_gsKeywords = [];
  var per_keyword = [];
  var per_pagination = 1;
  var per_printcat = "";
  var per_sph_bs = [];
  var per_sph_iab = [];
  var per_sph_seg = [];
  var per_story_threads = "";
  var per_visitor_category = "anonymous";
  var per_userid = "";
  var per_ptid = "";

  if (typeof _data != "undefined") {
    if (
      typeof per_getcookie("mysphw") != "undefined" &&
      per_getcookie("mysphw") != ""
    ) {
      per_userid = per_getcookie("mysphw");
    }
    if (
      typeof per_getcookie("mysph_lr") != "undefined" &&
      per_getcookie("mysph_lr") != ""
    ) {
      per_ptid = per_getcookie("mysph_lr");
    }
    if (typeof _data.articleid != "undefined" && _data.articleid != "") {
      per_articleid = _data.articleid;
    }
    if (typeof _data.chapter1_en != "undefined" && _data.chapter1_en != "") {
      per_chapter1 = _data.chapter1_en;
    }
    if (typeof _data.chapter2_en != "undefined" && _data.chapter2_en != "") {
      per_chapter2 = _data.chapter2_en;
    }
    if (typeof _data.chapter3 != "undefined" && _data.chapter3 != "") {
      per_chapter3 = _data.chapter3;
    }
    if (typeof _data.contentcat != "undefined" && _data.contentcat != "") {
      switch (_data.contentcat) {
        case "1":
          per_content_category = "free";
          break;
        case "2":
          per_content_category = "premium";
          break;
        default:
          per_content_category = "free";
      }
    }
    if (typeof _data.contenttype != "undefined" && _data.contenttype != "") {
      per_content_type = _data.contenttype;
    }
    if (typeof _data.keyword != "undefined" && _data.keyword != "") {
      per_keyword = _data.keyword.toLowerCase().split(",");
    }
    if (typeof _data.pagination != "undefined" && _data.pagination != "") {
      per_pagination = parseInt(_data.pagination);
    }
    if (typeof _data.printcat != "undefined" && _data.printcat != "") {
      per_printcat = _data.printcat;
    }
    if (typeof _data.sph_bs != "undefined" && _data.sph_bs != "") {
      per_sph_bs = _data.sph_bs.toLowerCase().split(",");
    }
    if (typeof _data.sph_iab != "undefined" && _data.sph_iab != "") {
      per_sph_iab = _data.sph_iab.toLowerCase().split(",");
    }
    if (
      typeof per_getcookie("sph_seg") != "undefined" &&
      per_getcookie("sph_seg") != ""
    ) {
      per_sph_seg = per_getcookie("sph_seg").toLowerCase().split(",");
    }
    if (
      typeof _data.story_threads != "undefined" &&
      _data.story_threads != ""
    ) {
      per_story_threads = _data.story_threads;
    }
    if (
      typeof per_getcookie("visitorcat") != "undefined" &&
      per_getcookie("visitorcat") != ""
    ) {
      switch (per_getcookie("visitorcat")) {
        case "1":
          per_visitor_category = "anonymous";
          break;
        case "2":
          per_visitor_category = "subscriber";
          break;
        case "3":
          per_visitor_category = "registered";
          break;
        case "4":
          per_visitor_category = "sphanonymous";
          break;
        case "5":
          per_visitor_category = "lite";
          break;
        case "6":
          per_visitor_category = "freetrial";
          break;
        case "7":
          per_visitor_category = "inapppurchase";
          break;
        case "8":
          per_visitor_category = "corpmoejc";
          break;
        case "9":
          per_visitor_category = "corpmoepoly";
          break;
        case "10":
          per_visitor_category = "corpmoeite";
          break;
        case "12":
          per_visitor_category = "verifiedreg";
          break;
        case "13":
          per_visitor_category = "nonverifiedreg";
          break;
        default:
          per_visitor_category = "anonymous";
      }
    }
  }
  console.log(123321, "ssr permutive.ts");
  if (typeof gs_channels != "undefined" && gs_channels != "") {
    per_gsKeywords = gs_channels.toLowerCase().split(",");
  }

  function per_getcookie(cname) {
    if (document.cookie.length > 0) {
      c_start = document.cookie.indexOf(cname + "=");
      if (c_start != -1) {
        c_start = c_start + cname.length + 1;
        c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  var per_suid = per_getcookie("suid");

  !(function (e, o, n, i) {
    if (!e) {
      (e = e || {}), (window.permutive = e), (e.q = []);
      var t = function () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          function (e) {
            return (
              e ^
              ((window.crypto || window.msCrypto).getRandomValues(
                new Uint8Array(1),
              )[0] &
                (15 >> (e / 4)))
            ).toString(16);
          },
        );
      };
      (e.config = i || {}),
        (e.config.apiKey = o),
        (e.config.workspaceId = n),
        (e.config.environment = e.config.environment || "production"),
        (window.crypto || window.msCrypto) && (e.config.viewId = t());
      for (
        var g = [
            "addon",
            "identify",
            "track",
            "trigger",
            "query",
            "segment",
            "segments",
            "ready",
            "on",
            "once",
            "user",
            "consent",
          ],
          r = 0;
        r < g.length;
        r++
      ) {
        var w = g[r];
        e[w] = (function (o) {
          return function () {
            var n = Array.prototype.slice.call(arguments, 0);
            e.q.push({ functionName: o, arguments: n });
          };
        })(w);
      }
    }
  })(
    window.permutive,
    "ab403253-b305-47fa-a31b-3efb2473166f",
    "5f876161-9740-4cc8-9b64-4585990b2690",
    {},
  );
  (window.googletag = window.googletag || {}),
    (window.googletag.cmd = window.googletag.cmd || []),
    window.googletag.cmd.push(function () {
      if (0 === window.googletag.pubads().getTargeting("permutive").length) {
        // console.log("load permutive");
        var e = window.localStorage.getItem("_pdfps");
        window.googletag
          .pubads()
          .setTargeting("permutive", e ? JSON.parse(e) : []);
        var o = window.localStorage.getItem("permutive-id");
        window.googletag.pubads().setTargeting("puid", o ? o : "");
        window.googletag.pubads().setTargeting("ptime", Date.now().toString());
        window.permutive.config.viewId &&
          window.googletag
            .pubads()
            .setTargeting("prmtvvid", window.permutive.config.viewId);
        window.permutive.config.workspaceId &&
          window.googletag
            .pubads()
            .setTargeting("prmtvwid", window.permutive.config.workspaceId);
      }
    });

  permutive.identify([
    {
      id: per_userid,
      tag: "userid",
      priority: 0,
    },
    {
      id: per_ptid,
      tag: "ptid",
      priority: 1,
    },
    {
      id: per_suid,
      tag: "suid",
      priority: 2,
    },
  ]);

  permutive.addon("web", {
    page: {
      articleid: per_articleid,
      chapter1: per_chapter1,
      chapter2: per_chapter2,
      chapter3: per_chapter3,
      contentcat: per_content_category,
      contenttype: per_content_type,
      gsKeywords: per_gsKeywords,
      keywords: per_keyword,
      pagination: per_pagination,
      printcat: per_printcat,
      sph_bs_list: per_sph_bs,
      sph_iab_list: per_sph_iab,
      sph_seg: per_sph_seg,
      story_threads: per_story_threads,
      visitorcat: per_visitor_category,
    },
  });
}
`;

const permutiveScript =
  "https://5f876161-9740-4cc8-9b64-4585990b2690.edge.permutive.app/5f876161-9740-4cc8-9b64-4585990b2690-web.js";

export { permutiveSphl, permutiveScript };
