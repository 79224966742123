export function sendPvToGa() {
  if (typeof window === "undefined") {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "zb_virtual_pv",
  });
}
