import ExpandIcon from "@assets/icons/article/expand-icon.svg";
import GalleryIcon from "@assets/icons/article/gallery-icon.svg";
import { getArticleKeywords } from "@components/AmpGAData/helper";
import useFontSize from "@hooks/useFontSize";
import ArticleAuthor from "@pages/Article/components/ArticleAuthor";
// import useAmpHtmlConverter from "@hooks/useAmpHtmlConverter";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import {
  fontSizeClassNameOptions,
  fontSizeClassNameOptions_1,
} from "@stores/font";
import { ArticleDetailContextType } from "@typings/Data";
import clsx from "clsx";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

import AmpArticleTime from "./AmpArticleTime";
import { isValidArray } from "./utils";

export default function AMPArticleDefaultLayout(): ReactElement {
  const { fontSize } = useFontSize();
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const { pathname } = useLocation();
  // const [ampHtml] = useAmpHtmlConverter(article?.body_cn, article);

  // Ads Config
  const json_amp = JSON.stringify({
    targeting: {
      pos: ["1"],
      zbtags: getArticleKeywords(article?.tags ? article.tags : []),
      zbarticleid: [article?.document_id],
      channel: ["amp"],
    },
  });

  const permutive_ad = JSON.stringify({
    urls: ["amp-script:permutiveCachedTargeting.ct"],
    timeoutMillis: 1000,
  });

  const showVideo = isValidArray(article?.videos);
  const showImage =
    (article?.videos?.length || 0) <= 0 && isValidArray(article?.images);
  const firstImage = article?.images?.[0];

  return (
    <>
      <div>
        <main className="amp-main pb-12 pt-12" role="main">
          <article className={clsx("max-w-full")}>
            <div className="layout-container lg:max-w-[768px]">
              {/* Article Heading */}
              <h1
                id="article-title"
                className={clsx(
                  "articleTitle",
                  "mb-[16px] font-medium leading-[150%] text-primary",
                )}
              >
                {article?.headline}
              </h1>

              {/* Author & Time */}
              <div key="author-section">
                {/* 作者信息 */}
                <ArticleAuthor
                  className={fontSizeClassNameOptions_1[fontSize]}
                />

                {/* 发布更新时间 */}
                <AmpArticleTime
                  className={clsx(
                    "pb-[16px]",
                    fontSizeClassNameOptions_1[fontSize],
                  )}
                />
              </div>

              {/* Stand first */}
              {article?.stand_first ? (
                <div
                  key="stand-first"
                  className={clsx(
                    "mb-[16px] rounded-[4px] border border-solid border-divider-primary p-[16px] font-medium text-secondary",
                    fontSizeClassNameOptions[fontSize],
                  )}
                >
                  {article.stand_first}
                </div>
              ) : null}

              {/* Main Videos */}
              {showVideo ? (
                <div
                  key="main-video"
                  className="amp-brightcove-container mt-[30px]"
                >
                  <amp-brightcove
                    data-account="4802324430001"
                    data-video-id={article?.videos?.[0]?.id}
                    layout="responsive"
                    width="480"
                    height="270"
                    data-param-playsinline="true"
                    data-param-muted="true"
                    noloading=""
                  ></amp-brightcove>
                </div>
              ) : null}

              {/* Main Images */}
              {showImage && firstImage ? (
                <div
                  key="first-image"
                  className="amp-carousel-container relative"
                >
                  <div
                    className="absolute bottom-0 left-0 right-0 top-0 z-10"
                    role="button"
                    {...{
                      on: "tap:amp-lightbox-gallery.open(id='slide-1')" as string,
                    }}
                    tabIndex={0}
                  >
                    <amp-img
                      src={`${firstImage.url}?o=zbimg&oloc=se&w=480&q=80&dpr=3`} // watermark
                      layout="responsive"
                      width={`${firstImage.width}`}
                      height={`${firstImage.height}`}
                      alt={firstImage.caption}
                      class="bg-[#f9f9f9]"
                    ></amp-img>
                  </div>
                  <amp-carousel
                    lightbox
                    width={firstImage.width}
                    height={firstImage.height}
                    type="slides"
                    layout="responsive"
                    id="amp-main-img-carousel"
                    controls="false"
                  >
                    {(article.images || []).map((item, index: number) => {
                      return (
                        <figure key={index}>
                          <div>
                            <amp-img
                              id={`slide-${index + 1}`}
                              lightbox="main-images"
                              src={`${item.url}?o=zbimg&oloc=se&w=480&q=80&dpr=3`} // watermark
                              layout="responsive"
                              width={`${item.width}`}
                              height={`${item.height}`}
                              alt={item.caption}
                              class="bg-[#f9f9f9]"
                            ></amp-img>
                          </div>
                          <figcaption
                            className={clsx(
                              "image text-center text-secondary",
                              fontSizeClassNameOptions_1[fontSize],
                            )}
                          >
                            <span className={clsx("indent")}>
                              {item.caption}
                            </span>
                          </figcaption>
                        </figure>
                      );
                    })}
                  </amp-carousel>
                  <p
                    className={clsx(
                      "mt-[16px] text-center text-secondary",
                      fontSizeClassNameOptions_1[fontSize],
                    )}
                  >
                    {article.images && article.images[0].caption}
                  </p>
                  <button
                    className={clsx(
                      "amp-carousel-expand-button pointer-events-none absolute right-3 top-3 z-20 flex max-h-max cursor-pointer items-center justify-between gap-2 rounded-full bg-[#00000066] py-2",
                      article.images && article.images.length > 1
                        ? "px-3"
                        : "px-1",
                    )}
                  >
                    <amp-img
                      width={
                        article.images && article.images.length > 1
                          ? "18"
                          : "22"
                      }
                      height={
                        article.images && article.images.length > 1
                          ? "18"
                          : "22"
                      }
                      src={ExpandIcon}
                      alt=""
                      layout="fixed"
                      class="mx-1"
                    />
                    {article.images && article.images.length > 1 ? (
                      <amp-img
                        src={GalleryIcon}
                        alt=""
                        width="18"
                        height="18"
                        layout="fixed"
                        class="mx-1"
                      />
                    ) : null}
                  </button>
                </div>
              ) : null}

              {/* Article Body */}
              <div
                key="article-body"
                className={clsx(
                  "articleBody",
                  fontSizeClassNameOptions[fontSize],
                )}
                dangerouslySetInnerHTML={{
                  __html: article?.body_cn || "",
                }}
              />

              {/* Outbrain */}
              <div
                key="outbrain"
                className="outbrain-widget-frame mt-[30px]"
                id="OutbrainWidget"
              >
                <amp-iframe
                  width="300"
                  height="300"
                  layout="responsive"
                  sandbox="allow-top-navigation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                  resizable=""
                  src={`https://widgets.outbrain.com/hub/amp.html#htmlURL=www.zaobao.com.sg${pathname}&ampURL=www.zaobao.com.sg${pathname}?amp&widgetIds=AMP_1&index=0&originTarget=*`}
                >
                  <div
                    {...{ overflow: "" as string }}
                    {...{ tabIndex: 0 as number }}
                    role="button"
                    aria-label="Outbrain Recommendations"
                  >
                    Outbrain
                  </div>
                </amp-iframe>
              </div>

              {/* Sticky Ads */}
              <amp-sticky-ad layout="nodisplay">
                <amp-ad
                  width="320"
                  height="50"
                  type="doubleclick"
                  data-slot={`/5908/ZB_SG/lb1${"/" + article?.sections[0].name.replace("_", "/")}`}
                  json={json_amp}
                  rtc-config={permutive_ad}
                ></amp-ad>
              </amp-sticky-ad>
            </div>
          </article>
        </main>
      </div>
    </>
  );
}
