import clsx from "clsx";

export type HamburgerMenuProps = {
  onExpand?: () => void;
  isExpanded?: boolean;
};

export default function HamburgerMenu({
  isExpanded = false,
  onExpand,
}: HamburgerMenuProps) {
  const hamburgerSeparatorClassName =
    "absolute left-[unset] mx-[2px] my-0 h-[2px] w-full rounded-r-[1.5px] bg-quaternary transition-transform duration-[400ms]";

  return (
    <div className="relative flex flex-row items-center">
      <button
        className="relative h-[20px] w-[20px]"
        aria-label="hamburger menu"
        onClick={() => {
          onExpand && onExpand();
        }}
      >
        <div
          className={clsx(hamburgerSeparatorClassName, "top-[3px]", {
            "bg-transparent": isExpanded,
          })}
        ></div>
        <div
          className={clsx(
            hamburgerSeparatorClassName,
            "top-[50%] -mt-[1px]",
            "before:content-hamburger before:bg-transparent before:transition-all before:duration-300",
            "after:content-hamburger after:bg-transparent after:transition-all after:duration-300",
            {
              "bg-transparent": isExpanded,
              "before:rotate-45 before:!bg-quaternary": isExpanded,
              "after:-rotate-45 after:!bg-quaternary": isExpanded,
            },
          )}
        ></div>
        <div
          className={clsx(hamburgerSeparatorClassName, "bottom-[3px]", {
            "bg-transparent": isExpanded,
          })}
        ></div>
      </button>
    </div>
  );
}
