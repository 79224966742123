import { BFF_BASE_URL } from "@constants/bff";
import { BFFResponseType } from "@typings/Data";
import axios from "axios";
import { stringify } from "qs";

export default function usePageContent() {
  const getPageContent = async ({
    level1,
    level2,
    page,
  }: {
    level1?: string;
    level2?: string;
    author?: string;
    tagName?: string;
    page?: number;
  }): Promise<BFFResponseType["response"]> => {
    const pageRoute = [level1, level2].filter(Boolean).join("/");

    return axios
      .get<BFFResponseType>(
        level1 === "keywords" || level1 === "byline" || level1 === "publication"
          ? `${BFF_BASE_URL}/${level1}/${level2}?${stringify(
              page !== 0 ? { page } : {},
            )}`
          : `${BFF_BASE_URL}/page-content/${pageRoute}?${stringify(
              page !== 0 ? { page } : {},
            )}`,
      )
      .then((response) => {
        return response.data.response;
      });
  };

  return {
    getPageContent,
  };
}
