import {
  CAS_API_KEY,
  CAS_DOMAIN,
  CAS_EXIT_INTERVENTION_ID,
  CAS_SCRIPT,
} from "@constants/cas";
import scriptState from "@stores/script";
import loadScript from "@utils/loadScript";
import loadScriptContent from "@utils/loadScriptContent";
import { useEffect } from "react";

export default function useCasPaywall() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      loadJquery();
    }
  }, []);
}

function loadJquery() {
  loadScript(
    "https://code.jquery.com/jquery-3.7.1.min.js",
    () => typeof window.jQuery !== "undefined",
    "jquery",
  )
    .then(() => {
      scriptState.jquery = true;
      loadBootstrap();
    })
    .catch(() => {});
}

function loadBootstrap() {
  loadScript(
    "https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js",
    () =>
      typeof window.jQuery !== "undefined" &&
      typeof window.jQuery.fn.modal !== "undefined",
    "bootstrap",
  )
    .then(() => {
      scriptState.bootstrap = true;
      loadCasScript();
    })
    .catch(() => {});
}

function loadCasScript() {
  loadScript(
    CAS_SCRIPT,
    () => {
      return typeof window.SPH_CDP !== "undefined";
    },
    "cas",
  )
    .then(() => {
      // scriptState.cas = true;
      // paywallScriptId?: "exitIntervention" | "articlePaywall";
      loadScriptContent(
        "exitIntervention",
        `window.addEventListener("SPHWaveCampaignEvent", 
                function(evt) 
                {
                  if (typeof SPH_CDP !== "undefined") {
                    SPH_CDP().init("${CAS_EXIT_INTERVENTION_ID}", "${CAS_DOMAIN}", "${CAS_API_KEY}").run();
                  }
                }, false);
                
                var event = document.createEvent('Event');
                event.initEvent('SPHWaveCampaignEvent', true, true);
                document.dispatchEvent(event);

                var isLdapLoginDetailsReceived = false;
                var sphWavePaywallInitiated = false;

                function activateCDP() {
                  console.log("isLdapLoginDetailsReceived "+isLdapLoginDetailsReceived);
                  if(isLdapLoginDetailsReceived == true && sphWavePaywallInitiated==false) {
                    console.log("login received => activating CDP");
                    sphWavePaywallInitiated = true;
                    SPH_CDP().init('${CAS_EXIT_INTERVENTION_ID}','${CAS_DOMAIN}', '${CAS_API_KEY}').run();
                  }
                }

                document.addEventListener('ldapLoginDetailsReceived', function(e) {
                  console.log("ldapLoginDetailsReceived => activating CDP");
                  isLdapLoginDetailsReceived = true;
                  activateCDP();
                },false);

                document.addEventListener('ldapLoginDetailsReceived', function(e) {
                  window.ldapLoginDetailsReceived = true;
                }, false);
                `,
      );
    })
    .catch(() => {});
}
