import { BFF_ENDPOINT } from "@constants/bff";
import type { ImageProps } from "@typings/OpensearchV2";
import { Helmet } from "react-helmet-async";
console.log("MetaTags BFF_ENDPOINT", BFF_ENDPOINT);

const TWITTER_CARD = "summary_large_image";

export type MetaTagsProps = {
  title: string;
  description: string;
  type: "website" | "article";
  slug: string;
  keywords?: string;
  image?: ImageProps;
  isArticlePage?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  ogImageUrl?: string;
};

export default function MetaTags({
  isArticlePage = false,
  title,
  description,
  type,
  slug,
  image,
  keywords = "联合早报网, 联合早报, lianhe zaobao, 早报, 今日观点, 新加坡新闻, 国际新闻, 中国新闻, singapore, 狮城新闻, news, 中国财经, zaobao, 新闻, 新闻,时事,评论,时政,财经,海外,国际,新加坡,东南亚,聚焦,文化,教育,深度,网评,专题,环球,传播,论坛,图片,焦点,法治,台海,中美,中日",
  ogTitle = "",
  ogDescription = "",
  ogImageUrl = "",
}: MetaTagsProps) {
  const canonicalUrl: string = BFF_ENDPOINT + slug;
  const baseUrl = import.meta.env.VITE_DRUPAL_ENDPOINT;
  const defaultMetaImageUrl = baseUrl + "/_web2/assets/social-share.png";

  // Default Meta Image
  const defaultMetaImage = {
    url: defaultMetaImageUrl,
    width: "1200",
    height: "627",
    alt: "联合早报 Lianhe Zaobao",
  };
  const metaImg = image ? image : defaultMetaImage;

  return (
    <Helmet>
      <title>{title}</title>

      {/* Search Engine tags */}
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph tags */}
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={ogImageUrl || metaImg.url} />
      <meta property="og:image:alt" content={metaImg.alt} />
      <meta property="og:url" content={canonicalUrl} />

      {/* Twitter tags */}
      <meta property="twitter:card" content={TWITTER_CARD} />

      {/* Article page */}
      {isArticlePage ? (
        <meta name="robots" content="max-image-preview:large" />
      ) : null}
    </Helmet>
  );
}
