import iconForward from "@assets/util/icon-arrow-forward.svg";
import Button from "@components/Button/Button";
import clsx from "clsx";

type SubscriptionPromptPropsType = {
  rootClassName?: string;
  type?: "standard" | "brand";
};

export default function SubscriptionPrompt({
  rootClassName = "",
  type = "standard",
}: SubscriptionPromptPropsType) {
  return (
    <div
      className={clsx("border-b-[1px] border-divider-primary", rootClassName)}
    >
      {type === "standard" ? (
        <div className="flex flex-row items-center justify-between bg-white p-[16px]">
          <div className="text-subheader3">想要解锁所有内容？</div>
          <Button
            type="tertiary"
            onClick={() => {
              window.open(
                "https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header",
                "_blank",
              );
            }}
          >
            立即订阅
          </Button>
        </div>
      ) : null}

      {type === "brand" ? (
        <div className="shadow-around-brand mb-0 mt-[15px] flex flex-row items-center justify-between rounded-[5px] p-[16px] text-white">
          <div className="text-subheader3">想要解锁所有内容？</div>
          <Button
            rootClassName="!bg-transparent !p-0"
            labelClassName="flex flex-row items-center"
            onClick={() => {
              window.open(
                "https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header",
                "_self",
              );
            }}
          >
            <div className="text-time">立即订阅</div>
            <img src={iconForward} alt="forward" className="ml-[10px]" />
          </Button>
        </div>
      ) : null}
    </div>
  );
}
