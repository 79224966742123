const CommonRequestFieldsMapping = {
  home: "static_page",
  global: "static_page",
  podcast: "static_page",
  "video-series": "static_page",
  videos: "static_page",
  livestream: "static_page",
  entertainment_celebs: "static_page",
  entertainment_news: "static_page",
  "entertainment_movies-and-tv": "static_page",
  entertainment_music: "static_page",
  "entertainment_k-pop": "static_page",
  entertainment_giveaway: "static_page",
  finance_invest: "static_page",
  "finance_real-estate": "static_page",
  finance_usstock: "static_page",
  finance_sme: "static_page",
  finance_startup: "static_page",
  finance_personalities: "static_page",
  realtime_finance: "static_page",
  lifestyle_others: "static_page",
  "interactive-graphics": "static_page",
  keywords: "keyword",
  specials: "story_thread",
  special: "story_thread",
  microsite: "story_thread",
  forum_zaodian: "story_thread",
  forum_expert: "story_thread",
  interactive: "story_thread",
  publication: "publication",
};

export function getRequestFieldName(
  level1: string | undefined,
  level2: string | undefined,
) {
  return (
    CommonRequestFieldsMapping[
      [level1, level2]
        .filter(Boolean)
        .join("_") as keyof typeof CommonRequestFieldsMapping
    ] ||
    CommonRequestFieldsMapping[
      level1 as keyof typeof CommonRequestFieldsMapping
    ] ||
    "section"
  );
}
