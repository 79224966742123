import FooterLogo from "@assets/logos/FooterLogo.svg";
import Link from "@components/Link/Link";
import { copyright, footerHotlinks } from "@constants/externalNavigation";
import clsx from "clsx";
import { type ReactElement } from "react";

export default function FooterCopyright(): ReactElement {
  return (
    <div className="layout-container">
      <ul className="footer-sub-list mb-0 list-none pb-0">
        {footerHotlinks.map((item, index) => (
          <li key={`copyright-${item.key}`} className="mb-4 mr-8 inline-block">
            <Link
              className={clsx(
                "relative flex flex-row items-center text-label2 font-medium text-white",
                "hover:text-white",
                "hover:underline-offset-2",
                "hover:underline",
              )}
              to={item.href}
              target="_blank"
              key={index}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
      <div
        className={clsx(
          "text-content-inactive pt-12 text-label2 leading-[26px] lg:flex lg:flex-row",
        )}
      >
        <div className={clsx("w-full lg:w-8/12 lg:px-[15px] lg:pl-0")}>
          <FooterLogoDisplay />
          <FooterMci />
        </div>
        <div className={clsx("w-full lg:w-4/12 lg:px-[15px] lg:pr-0 ")}>
          <FooterReminder />
          <FooterWarning />
        </div>
      </div>
    </div>
  );
}

export function FooterLogoDisplay(): ReactElement {
  return (
    <div className="logo-footer mb-8 flex">
      <img src={FooterLogo} width="110" height="35" alt="联合早报" />
    </div>
  );
}

export function FooterMci(): ReactElement {
  return (
    <p className="text-primary-inverse">
      {copyright.mci}
      <br />
      {copyright.name}
      <br />
      {copyright.cn_vistor}&nbsp;
      <Link
        className={clsx(
          "text-white",
          "hover:text-white",
          "hover:underline-offset-2",
          "hover:underline",
        )}
        target="_blank"
        to={copyright.link}
        aria-label={copyright.link_label}
      >
        <u>{copyright.link_label}</u>
      </Link>
    </p>
  );
}

export function FooterReminder(): ReactElement {
  return <p className="text-primary-inverse">{copyright.reminder}</p>;
}

export function FooterWarning(): ReactElement {
  return <p className="text-primary-inverse">{copyright.warning}</p>;
}
