import ArrowDownDropDown from "@assets/icons/dropdown-down-filled.svg?react";
import ArrowUpDropDown from "@assets/icons/dropdown-up-filled.svg?react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { MouseEvent, ReactElement, useEffect, useRef, useState } from "react";
const RealtimeDropDown = ({
  setDateProps,
}: {
  setDateProps: (dateProps: string) => void;
}): ReactElement => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const today = new Date();
    const dates = Array.from({ length: 9 }, (_, index) => {
      const date = new Date();
      date.setDate(today.getDate() - index);
      return date.toISOString().slice(0, 10);
    });
    setSelectedDate(dates[0]);
    console.log(selectedDate, "onload");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent<Element, MouseEvent>): void => {
      if (
        !dropRef.current?.contains(e.target as HTMLElement) &&
        !listRef.current?.contains(e.target as HTMLElement)
      ) {
        if (isOpen) {
          setIsOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate, listRef, dropRef, isOpen]);

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    if (year) {
      return `${year}年${month}月${day}日`;
    }
    return <FontAwesomeIcon icon={faSpinner} spin={true} />;
  };

  const handleSelectChange = (event: string) => {
    const selectedValue = event;
    setSelectedDate(selectedValue);
    setIsOpen(false);
    setDateProps(selectedValue.replace(/-/g, ""));
    // window.location.href =
    //   pathname + "?date=" + selectedValue.replace(/-/g, "");
    console.log(selectedValue.replace(/-/g, ""), "selectvalue");
  };

  return (
    <div
      className="relative"
      ref={listRef}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <button
        className={clsx(
          "flex h-[40px] items-center justify-between px-[12px] py-[14px] text-button1 leading-none",
          {
            "bg-tertiary text-brand": isOpen,
            "text-secondary": !isOpen,
          },
        )}
        ref={dropRef}
      >
        {formatDate(selectedDate)}
        <div className="flex h-[24px] w-[24px] items-center justify-center text-center hover:text-brand">
          {isOpen ? <ArrowUpDropDown /> : <ArrowDownDropDown />}
        </div>
      </button>
      {isOpen ? (
        <ul
          id="dateDropdown"
          className="absolute left-0 top-[40px] z-10 w-full bg-tertiary"
        >
          {Array.from({ length: 9 }, (_, index) => {
            const date = new Date();
            date.setDate(date.getDate() - index);
            const formattedDate = date.toISOString().slice(0, 10);
            return (
              <li
                className={clsx(
                  "relative h-[40px] w-full py-[14px] pr-[12px] text-secondary [&>label]:text-button2 [&>label]:hover:text-button1 [&>label]:hover:text-brand",
                  {
                    // "text-brand": selectedDate === formattedDate,
                    // "text-secondary": selectedDate !== formattedDate,
                  },
                )}
                key={index}
              >
                <input
                  className="absolute top-0 z-[1] h-full w-full appearance-none opacity-0"
                  value={formattedDate}
                  type="button"
                  name={"select-Dropdown-" + index}
                  onClick={(e) => {
                    handleSelectChange((e.target as HTMLInputElement).value);
                  }}
                />
                <label
                  htmlFor={"select-Dropdown-" + index}
                  className={clsx(
                    "absolute top-[7px] z-0 inline-block w-full pl-[12px]",
                  )}
                >
                  {formatDate(formattedDate)}
                </label>
              </li>
            );
          })}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RealtimeDropDown;
