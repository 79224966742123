import { proxy } from "valtio";

const scriptState = proxy<Record<string, boolean>>({
  // brightcove: false,
  // brightcove_video_slick: false,
  jquery: false,
  // cas: false,
  // opencc: false,
  // googleOneTap: false,
  bootstrap: false,
});

export default scriptState;
