import { NeuronProvider as Provider, SPAConfig } from "@sphtech/neuron";
import { ReactElement, ReactNode } from "react";

import TrackerProvider from "./TrackerProvider";

type Props = {
  children: ReactNode;
};

export default function NeuronProvider({ children }: Props): ReactElement {
  const neuronConfig: SPAConfig = {
    trackedSPAEvents: [
      {
        eventType: "elementVisible",
        selector: "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
      },
      {
        eventType: "click",
        selector: "a, button",
      },
    ],
    sessionApiUrl: import.meta.env.VITE_NEURON_API_URL,
    sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN,
  };

  return (
    <Provider config={neuronConfig}>
      <TrackerProvider>{children}</TrackerProvider>
    </Provider>
  );
}
