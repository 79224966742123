import VerticalArticleList from "@components/ArticleCardList/VerticalArticleList/VerticalArticleList";
import AuthorCard from "@components/AuthorCard/AuthorCard";
import Level2Template from "@components/Templates/Level2Template";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { WebCategoryLevel2ContextType } from "@typings/Data";

export default function AuthorDefaultLayout({
  setAuthorName,
}: {
  setAuthorName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const {
    context: {
      payload: { articles, authorProfile },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel2ContextType;
  }> = useRouteContext();

  // Set Meta Tag title to include author's character name
  if (authorProfile) {
    setAuthorName(authorProfile.name);
  }

  const renderAurthorCard = () => {
    return authorProfile ? <AuthorCard authorProfile={authorProfile} /> : null;
  };

  const renderPageContent = () => {
    return articles && articles.length !== 0 ? (
      <VerticalArticleList articles={articles} rootClassName="card-listing" />
    ) : null;
  };

  return <Level2Template mainSlots={[renderAurthorCard, renderPageContent]} />;
}
