import Link from "@components/Link/Link";
import Timestamp from "@components/Timestamp/Timestamp";
import { ArticlePreviewType } from "@typings/Data";

const TickerArticleCard = ({
  rootClassName = "",
  article,
}: {
  rootClassName?: string;
  article: ArticlePreviewType;
}) => {
  return (
    <div
      className={`card ticker-card ${rootClassName}`}
      id={`test-realtime-article-card-${article.id}`}
      data-testid={`test-realtime-article-card-${article.id}`}
    >
      <div className="card-content group">
        <div className="ticker flex items-start justify-start text-secondary">
          <div className="z-[1] mt-[5px] h-[10px] w-[10px] rounded-full border-[2px] border-divider-brand bg-primary"></div>
          <span className="mb-[8px] pl-[16px] text-label4 font-medium text-secondary">
            <Timestamp parsable={article.timestamp} format="relative" />
          </span>
        </div>
        <div className="content-header">
          <Link to={article.href} referrer="sidebar-realtime">
            <h2 className="line-clamp-2 text-header3 text-link-headline-default group-hover:text-link-headline-hover">
              {article.title}
            </h2>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TickerArticleCard;
