/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import TimestampArticleCard from "@components/ArticleCard/TimestampArticleCard/TimestampArticleCard";
import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import ImmediateArticleList from "@components/ArticleCardList/ImmediateArticleList/ImmediateArticleList";
import VerticalArticleList from "@components/ArticleCardList/VerticalArticleList/VerticalArticleList";
import BrightcoveVideoCarousel from "@components/BrightcoveVideoCarousel/BrightcoveVideoCarousel";
import Card from "@components/Card/Card";
import RealtimeDropDown from "@components/Dropdown/RealtimeDropDown";
import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import MultiCarousel from "@components/MultiCarousel/MultiCarousel";
import NewsletterSignUpForm from "@components/NewsletterSignUpForm/NewsletterSignUpForm";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import SingleCarousel from "@components/SingleCarousel/SingleCarousel";
import Level1Template from "@components/Templates/Level1Template";
import { BFF_BASE_URL } from "@constants/bff";
import { useBrightcovePlaylistFromDrupal } from "@hooks/ClientSideFromDrupal/useBrightcovePlaylistFromDrupal";
import useParams from "@hooks/useSafeParams";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import userState from "@stores/user";
import {
  PageContentListContextType,
  WebCategoryLevel1ContextType,
  WebCategoryLevel2ContextType,
} from "@typings/Data";
// import { ArticlePreviewType } from "@typings/Data";
import axios from "axios";
import clsx from "clsx";
import { Fragment, type ReactNode, useEffect, useState } from "react";
import { useSnapshot } from "valtio";

export default function Level1WebCategory() {
  const {
    context: {
      payload: { singleCarouselArticles = [], articles, pageContentList = [] },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel1ContextType &
      WebCategoryLevel2ContextType &
      PageContentListContextType; // use 2 interface type
  }> = useRouteContext();

  const { user } = useSnapshot(userState);

  const { level1 = "", level2 = "" } = useParams();
  const levelKey = [level1, level2].filter(Boolean).join("_");
  const categoryUniqueKey = [level1, level2].filter(Boolean).join("/");

  // Brightcove playlist
  const { brightcovePlaylist } = useBrightcovePlaylistFromDrupal({
    level1: level1,
    level2: level2,
  });
  const playlistId = brightcovePlaylist[0]?.playlistId;

  // /realtime page dropdown
  const [dropDownDate, setDropDownDate] = useState<string>("");
  const [articlesOnRealtimePage, setArticlesOnRealtimePage] =
    useState<PageContentListContextType[]>();
  useEffect(() => {
    if (levelKey == "realtime") {
      const callApi = async () => {
        const date = new Date();
        date.setDate(date.getDate());
        const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "");

        let res;
        switch (true) {
          case dropDownDate !== "" && dropDownDate !== formattedDate:
            res = await axios.get(
              `${BFF_BASE_URL}/page-content/${categoryUniqueKey}?date=${dropDownDate}`,
            );
            break;
          case dropDownDate == formattedDate:
            res = await axios.get(
              `${BFF_BASE_URL}/page-content/${categoryUniqueKey}`,
            );
            break;
          default:
            return "";
        }

        const result = res.data.response.pageContentList;
        setArticlesOnRealtimePage(result as PageContentListContextType[]);
        setDropDownDate("");
      };

      callApi().catch((err) => {
        console.log(err);
      });

      // setArticlesOnRealtimePage(pageContentList)
    }
  }, [
    levelKey,
    categoryUniqueKey,
    dropDownDate,
    articlesOnRealtimePage,
    pageContentList,
  ]);

  // newsletterIndex position
  let newsletterIndex: number;
  if (pageContentList.length) {
    newsletterIndex = getNewsletterIndex(pageContentList, levelKey);
  }

  function renderFullWidthBanner() {
    return <HomepageFullWidthBanner additionalClasses="!mb-0" />;
  }

  const renderSectionNavigation: () => ReactNode = () => {
    return <SectionNavigation level={levelKey} />;
  };

  const renderPageContent: () => ReactNode = () => {
    return (
      <Fragment>
        {playlistId && playlistId !== "" ? (
          <BrightcoveVideoCarousel playlistId={playlistId} />
        ) : null}

        {singleCarouselArticles.length > 0 ? (
          <SingleCarousel articles={singleCarouselArticles} />
        ) : null}

        {levelKey == "realtime" && (
          <section className="my-[16px] flex w-full justify-end pr-8">
            <RealtimeDropDown setDateProps={setDropDownDate} />
          </section>
        )}

        <div
          id="realtime-articles-by-web-category"
          className={clsx(`layout-container`, {
            // "lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:[&>*:nth-child(even)]:pl-[1.6rem] lg:[&>*:nth-child(odd)]:pr-[1.6rem]":
            //   level1 === "realtime",
          })}
        >
          <div className="row">
            {(levelKey === "realtime" && articlesOnRealtimePage
              ? articlesOnRealtimePage
              : pageContentList
            )
              .filter((children) => {
                return !children.displaySettings?.isHiddenInLayout;
              })
              .map((children, index) => {
                console.log(index, newsletterIndex, "newsletter");
                if (children.key === "finance_stock") {
                  return (
                    <div key={children.key} className="w-full">
                      <Card
                        isLink={true}
                        heading={children.label}
                        headingLink={children.href}
                        contentClassName="finance-widget-list"
                      >
                        {[
                          "iframe_market_summary",
                          "iframe_top_5_active",
                          "iframe_top_5_volume",
                        ].map((item) => {
                          return (
                            <div
                              className="flex w-full min-w-max justify-center py-[16px] lg:w-1/3"
                              key={item}
                            >
                              <iframe
                                title={item}
                                scrolling="no"
                                frameBorder="0"
                                height={
                                  item !== "iframe_market_summary"
                                    ? "362"
                                    : "245"
                                }
                                allowFullScreen={true}
                                className={clsx(
                                  "no-scrollbar block h-[245px]",
                                  {
                                    "h-[362px]":
                                      item !== "iframe_market_summary",
                                  },
                                )}
                                src={
                                  "https://stock.zaobao.com.sg/stock_prices/" +
                                  item
                                }
                              ></iframe>
                            </div>
                          );
                        })}
                      </Card>
                    </div>
                  );
                }

                switch (children.displaySettings?.cardDisplayMode) {
                  case "darkCard":
                    return Array.isArray(children.articles) &&
                      children.articles.length > 0 ? (
                      <Card
                        key={children.key || index}
                        heading={children.label}
                        isInverse
                        isMultiCarousel={true}
                        rootClassName="-mx-8 mx-[unset] bg-cinema px-8 lg:px-[unset]"
                        headingClassName="text-white "
                        isLink={false}
                      >
                        <MultiCarousel articles={children.articles} />
                      </Card>
                    ) : null;

                  case "ImmediateArticleList":
                    return Array.isArray(children.articles) &&
                      children.articles.length > 0 ? (
                      <Card
                        key={children.key || index}
                        heading={children.label}
                        headingLink={children.href}
                      >
                        {children.articles.length && (
                          <div className="row">
                            <div className="w-full lg:px-8">
                              <ImmediateArticleList
                                articles={children.articles}
                              />
                            </div>
                          </div>
                        )}
                      </Card>
                    ) : null;

                  case "TimestampArticleCard":
                    return Array.isArray(children.articles) &&
                      children.articles.length > 0 ? (
                      <div
                        className="w-full lg:w-1/2"
                        key={children.key || index}
                      >
                        <Card
                          key={children.key || index}
                          heading={children.label}
                          headingLink={children.href}
                        >
                          <ul className="card-listing">
                            {children.articles.map((article, index) => {
                              return (
                                <li key={article.id || index}>
                                  <TimestampArticleCard article={article} />
                                </li>
                              );
                            })}
                          </ul>
                        </Card>
                      </div>
                    ) : null;

                  default:
                    return Array.isArray(children.articles) &&
                      children.articles.length > 0 ? (
                      <Fragment key={children.key || index}>
                        <Card
                          heading={children.label}
                          headingLink={children.href}
                          // rootClassName="first:pt-0"
                        >
                          <div className="row">
                            <ul
                              className={clsx("card-listing lg:flex")}
                              id={children.key}
                            >
                              {children.articles.map((article, index) => {
                                return (
                                  <Fragment key={article.id || index}>
                                    <li className="lg:w-1/3">
                                      <VerticalArticleCard
                                        rootClassName="responsive"
                                        article={article}
                                      />
                                    </li>
                                  </Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </Card>
                        {index === newsletterIndex && !user?.loginid && (
                          <div className="w-full px-8">
                            <NewsletterSignUpForm />
                          </div>
                        )}
                      </Fragment>
                    ) : null;
                }
              })}

            {levelKey === "interactive-graphics" && articles?.length ? (
              <VerticalArticleList
                articles={articles}
                rootClassName={"card-listing"}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Level1Template
      topSlots={[renderSectionNavigation, renderFullWidthBanner]}
      mainSlots={[renderPageContent]}
    />
  );
}

const getNewsletterIndex = (
  pageContentList: PageContentListContextType[],
  levelKey: string,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // Get pageContent length
  const contentLength = pageContentList.length;
  // if (contentLength <= 4) {
  //   return contentLength - 1;
  // }

  // Filter out unwanted children first - immediate articles, multi carousel finance stock
  const filtered = pageContentList.filter((item) => {
    // console.log(item.articles, item.label, "item");
    return (
      item.articles?.length !== 0 &&
      item.displaySettings?.cardDisplayMode !== "ImmediateArticleList" &&
      item.key !== "finance_stock" &&
      item.key !== "realtime_finance" &&
      item.key !== "entertainment_has_video" &&
      item.key !== "lifestyle_has_video"
    );
  });

  // Get Difference (pageContent length - filtered length)
  const difference = contentLength - filtered.length;
  let x;
  switch (levelKey) {
    case "special":
      x = 3;
      break;
    default:
      x = 4;
  }
  console.log(
    "filtered: ",
    filtered,
    "difference: ",
    difference,
    "return: ",
    difference + 4 - 1,
  );
  return difference + x - 1;
};
