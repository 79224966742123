import ArticleEmailIcon from "@assets/icons/article/email-icon.svg";
import ArticleFacebookIcon from "@assets/icons/article/facebook-icon.svg";
import ShareIcon from "@assets/icons/article/share-icon.svg";
import ArticleTelegramIcon from "@assets/icons/article/telegram-icon.svg";
import ArticleTwitterIcon from "@assets/icons/article/twitter-icon.svg";
import ArticleWechatIcon from "@assets/icons/article/wechat-icon.svg";
import ArticleWhatsappIcon from "@assets/icons/article/whatsapp-icon.svg";
import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import viewportState from "@stores/viewport";
import { ArticleDetailContextType } from "@typings/Data";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

interface IArticleShare {
  className?: string;
}

export default function ArticleShare({ className = "" }: IArticleShare) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const [showShare, setShowShare] = useState(false);
  const { isDesktopView } = useSnapshot(viewportState);

  const [articleSocialLinks, setArticleSocialLinks] = useState<
    { src: string; alt: string; link: string }[]
  >([]);
  useEffect(() => {
    const href = window.location.origin + window.location.pathname;
    const articleSocialLinks = [
      {
        src: ArticleFacebookIcon,
        alt: "facebook",
        link: `https://www.facebook.com/sharer/sharer.php?u=${href}`,
      },
      {
        src: ArticleTwitterIcon,
        alt: "twitter",
        link: `https://twitter.com/intent/tweet?text=${article?.headline}&url=${href}`,
      },
      {
        src: ArticleWechatIcon,
        alt: "wechat",
        link: `https://www.addtoany.com/ext/wechat/share/#url=${href}&title=${article?.headline}`,
      },
      {
        src: ArticleWhatsappIcon,
        alt: "whatsapp",
        link: `https://api.whatsapp.com/send?text=${article?.headline}%20${href}`,
      },
      {
        src: ArticleTelegramIcon,
        alt: "telegram",
        link: `https://t.me/share/url?url=${href}&text=${article?.headline}`,
      },
      {
        src: ArticleEmailIcon,
        alt: "email",
        link: `mailto:?subject=${article?.headline}&body=${href}`,
      },
      // {
      //   src: ArticleNewPostIcon,
      //   alt: "new post",
      //   link: "#",
      // },
    ];
    setArticleSocialLinks(articleSocialLinks);
  }, [article?.headline]);

  return (
    <div className={className}>
      <div className="flex w-full flex-row-reverse text-[16px]">
        <div className="relative h-max w-max cursor-pointer bg-white">
          <button
            className="flex w-[81px] justify-center py-[8px]"
            onClick={() => {
              setShowShare((v) => !v);
            }}
          >
            <div
              className={clsx(
                "flex h-[40px] w-[40px] items-center justify-center rounded-full transition-all duration-200",
                isDesktopView && "hover:bg-button-ghost-hover",
              )}
            >
              <img
                src={ShareIcon}
                alt="share icon"
                className="m-0 h-[24px] w-[24px]"
              />
            </div>
          </button>
          <div
            className={`box-border w-[81px] overflow-hidden rounded-[4px] border border-divider-primary transition-all duration-300
              ${showShare ? "max-h-[300px]" : "max-h-0 border-0"}
              ${!isDesktopView ? "absolute left-0 top-full z-[43]" : "block"}
              `}
          >
            {articleSocialLinks.map((item, index) => {
              const isLastIndex = index == articleSocialLinks.length - 1;
              return (
                <Link
                  key={index}
                  to={item.link}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className={clsx(
                      `flex w-full justify-center border-solid border-divider-primary bg-button-segmented-primary-default py-[8px] transition-all duration-200`,
                      isDesktopView &&
                        "hover:bg-button-segmented-primary-hover",
                      !isLastIndex && "border-b",
                    )}
                  >
                    <img
                      className="m-0 h-[24px] w-[24px]"
                      src={item.src}
                      alt={item.alt}
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
