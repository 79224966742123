import Accordion from "@components/Accordion/Accordion";
import Link from "@components/Link/Link";
import {
  headerNavigation,
  navigationDictionary,
  NavigationItem,
} from "@constants/navigation";
import useParams from "@hooks/useSafeParams";
import fontState from "@stores/font";
import clsx from "clsx";
import { useState } from "react";
import { useSnapshot } from "valtio";

export default function SideNavigation() {
  const { fontSize } = useSnapshot(fontState);
  const { level1 = "", level2 = "" } = useParams();

  const [expandedAccordionId, setExpandedAccordionId] = useState<string>();

  return (
    <nav className="divide flex flex-col divide-y bg-tertiary">
      {headerNavigation.map((sideNavigationItem: NavigationItem) => {
        const { key, href, label, children } = sideNavigationItem;
        const isCurrentItemExpanded = expandedAccordionId === key;
        let isCurrentItemMatchUrl;
        if (level1) {
          isCurrentItemMatchUrl =
            level1 in navigationDictionary &&
            key === navigationDictionary[level1].key;
        } else {
          isCurrentItemMatchUrl = key === navigationDictionary["home"].key;
        }

        const isCurrentItemChildMatchUrl = children
          ?.map((it) => it.key)
          .includes([level1, level2].filter(Boolean).join("_"));

        return (
          <Accordion
            key={key}
            id={key}
            label={label}
            link={href}
            onToggle={(_, accordionId) => {
              setExpandedAccordionId(isCurrentItemExpanded ? "" : accordionId);
            }}
            isExpanded={isCurrentItemExpanded}
            isMatchUrl={isCurrentItemMatchUrl}
            isChildMatchUrl={isCurrentItemChildMatchUrl}
          >
            {children
              ? children.map((child) => {
                  return (
                    <Link
                      key={child.key}
                      to={child.href || ""}
                      className={clsx(
                        "label-clickable relative flex flex-row items-center truncate text-base leading-[42px] tracking-[2px] text-primary",
                        fontSize,
                      )}
                    >
                      {child.label}
                    </Link>
                  );
                })
              : null}
          </Accordion>
        );
      })}
    </nav>
  );
}
