import useFontSize from "@hooks/useFontSize";
import {
  fontSizeClassNameOptions_1,
  fontSizeClassNameOptions_2,
} from "@stores/font";
import clsx from "clsx";

import ArticleAuthor from "../components/ArticleAuthor";
import ArticleFont from "../components/ArticleFont";
import ArticleKicker from "../components/ArticleKicker";
import ArticleReleated from "../components/ArticleReleated";
import ArticleShare from "../components/ArticleShare";
import ArticleTime from "../components/ArticleTime";

interface IContentLeft {
  className?: string;
}

export default function ContentLeft({ className = "" }: IContentLeft) {
  const { fontSize } = useFontSize();

  return (
    <aside className={className} id="aside-left">
      <div>
        <ArticleKicker className={fontSizeClassNameOptions_2[fontSize]} />

        <div>
          {/* 作者信息 */}
          <ArticleAuthor className={fontSizeClassNameOptions_1[fontSize]} />

          {/* 发布更新时间 */}
          <ArticleTime
            className={clsx("pb-[16px]", fontSizeClassNameOptions_1[fontSize])}
          />
        </div>
      </div>

      <div className="sticky top-[50px] w-full">
        {/* 分享 */}
        <ArticleShare className="mb-[16px]" />

        {/* 字体 */}
        <ArticleFont className="mb-[16px]" />

        {/* 延伸阅读 */}
        <ArticleReleated className=" hidden xxl:block" />
      </div>
    </aside>
  );
}
