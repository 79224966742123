import Link from "@components/Link/Link";
import { footerContactItems } from "@constants/navigation";
import clsx from "clsx";
import { type ReactElement } from "react";

export default function FooterContactUs(): ReactElement {
  return (
    <div
      className={clsx(
        "mx-[-15px] flex flex-wrap",
        "mb-[10px] sm:mb-[30px] sm:mt-[30px] md:mt-0 lg:mb-[30px] lg:mt-[30px] xl:my-0",
        "lg:mb-0 xl:mx-0 ",
      )}
    >
      {footerContactItems.map((item, index) => (
        <div
          key={`contact-${item.key}`}
          className={clsx(
            "mb-[20px] w-4/12 px-6",
            "md:mb-[10px] md:w-auto md:max-w-full md:shrink-0 md:grow-0 md:basis-[content] xl:px-0",
            "xl:max-w-5/12 lx:grow-0 xl:mb-[10px] xl:shrink-0 xl:pr-[15px] xxl:basis-[41.66667%]",
          )}
        >
          <Link
            className={clsx(
              "text-base font-medium text-white hover:text-white",
              "hover:underline-offset-2",
              "hover:underline",
            )}
            to={item.href}
            key={index}
            target={item.label !== "加入我们" ? "_self" : "_blank"}
          >
            {item.label}
          </Link>
        </div>
      ))}
    </div>
  );
}
