import sanitizeHtml from "sanitize-html";

export function sanitize(possibleDirtyString: string): string {
  // We can replace sanitizeHtml with any other sanitization library
  return hasDirtyParams([possibleDirtyString])
    ? ""
    : sanitizeHtml(possibleDirtyString);
}

export function hasDirtyParams(params: string[]): boolean {
  return params
    .filter(Boolean)
    .some(
      (param) =>
        encodeURIComponent(param) !== param ||
        decodeURIComponent(param) !== param,
    ); // if sanitized param is different from original param, then it is dirty
}
