import { CAS_API_KEY, CAS_DOMAIN, CAS_PAYWALL_ID } from "@constants/cas";
import loadScriptContent from "@utils/loadScriptContent";
import { useEffect } from "react";

export default function useCasPaywallArticle() {
  useEffect(() => {
    console.log("useCasPaywallArticle start");
    if (typeof window !== "undefined") {
      load();
    }
  }, []);
}

function load() {
  console.log("loadJquery");
  // paywallScriptId?: "exitIntervention" | "articlePaywall";
  loadScriptContent(
    "articlePaywall",
    `window.addEventListener("SPHWaveCampaignEvent", 
                function(evt) 
                {
                  if (typeof SPH_CDP !== "undefined") {
                    SPH_CDP().init("${CAS_PAYWALL_ID}", "${CAS_DOMAIN}", "${CAS_API_KEY}").run();
                  }
                }, false);
                `,
  );
}
