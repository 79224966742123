import VerticalArticleList from "@components/ArticleCardList/VerticalArticleList/VerticalArticleList";
import BrightcoveVideoCarousel from "@components/BrightcoveVideoCarousel/BrightcoveVideoCarousel";
import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import MetaTags from "@components/MetaTags/MetaTags";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import Level2Template from "@components/Templates/Level2Template";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import { useBrightcovePlaylistFromDrupal } from "@hooks/ClientSideFromDrupal/useBrightcovePlaylistFromDrupal";
import { useLiveBlogFromDrupal } from "@hooks/ClientSideFromDrupal/useLiveBlogFromDrupal";
import useParams from "@hooks/useSafeParams";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import viewportState from "@stores/viewport";
import { WebCategoryLevel2ContextType } from "@typings/Data";
import { useSnapshot } from "valtio";

import AppDeepLinkMeta from "../Components/AppDeepLinkMeta";

/** 关键词列表页面 */
export default function KeywordsPage() {
  const { isMobileView, isDesktopView, isInAppBrowser } =
    useSnapshot(viewportState);

  const {
    context: {
      payload: { articles, tagName = "" },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel2ContextType;
  }> = useRouteContext();

  const { tagName: tagNameParam = "", level2 = "" } = useParams();
  const { brightcovePlaylist } = useBrightcovePlaylistFromDrupal({
    level1: "keywords",
    level2: tagNameParam,
  });
  const { liveBlog } = useLiveBlogFromDrupal({
    level1: "keywords",
    level2: tagNameParam,
  });

  const liveBlogContent = liveBlog[0]?.content;
  const playlistId = brightcovePlaylist[0]?.playlistId;

  // meta tag
  const title = `${tagName} | 联合早报`;
  const description = `${tagName}新闻 - 《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。获取有关${tagName}的更多信息，尽在 zaobao.com.sg。`;
  const sectionAdsName = `keywords`;
  const canonical_url = `/keywords/${level2}`;

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  return (
    <div>
      {/* MetaTags */}

      <AppDeepLinkMeta urlPath={canonical_url} />

      <MetaTags
        type="website"
        title={title}
        description={description}
        slug={canonical_url}
      />

      {/* GA */}
      <GAData level2_ga={"keywords"} title={title} adblocker={getAdBlocker()} />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {/* Header */}
      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <Level2Template
        topSlots={[
          () => <SectionNavigation level="" tagName={tagName} />,
          () => (
            <div className="w-full">
              <HomepageFullWidthBanner
                additionalClasses="!mb-[20px]"
                level1="keywords"
                level2={tagNameParam}
              />
            </div>
          ),
        ]}
        mainSlots={[
          () => (
            <>
              {playlistId && (
                <BrightcoveVideoCarousel playlistId={playlistId} />
              )}

              {liveBlogContent && (
                <div
                  className="w-full px-[8px] py-[16px]"
                  dangerouslySetInnerHTML={{ __html: liveBlogContent }}
                />
              )}

              <div className="card-listing">
                {articles && <VerticalArticleList articles={articles} />}
              </div>
            </>
          ),
        ]}
      />

      {/* Footer */}
      {isInAppBrowser ? null : <Footer />}

      {/* CatfishAd */}
      {isMobileView ? (
        isInAppBrowser ? null : (
          <CatfishGoogleAd
            slot={GoogleAdsSlotFactory.catfish(sectionAdsName)}
          />
        )
      ) : null}
    </div>
  );
}
