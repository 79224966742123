import Slider, { Settings } from "@ant-design/react-slick";
import ArrowNext from "@assets/icons/arrow-forward-icon.svg?react";
import ArrowPrev from "@assets/icons/arrow-prev-icon.svg?react";
import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Link from "@components/Link/Link";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import { navigationDictionary } from "@constants/navigation";
import globalState from "@stores/global";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";
import { Fragment, ReactElement, useRef } from "react";
import { useSnapshot } from "valtio";

const SingleCarousel = ({
  rootClassName = "",
  articles,
}: {
  rootClassName?: string;
  articles: Array<ArticlePreviewType> | undefined;
}) => {
  const singleSlider = useRef<Slider>(null);
  const { isClientSideMounted } = useSnapshot(globalState);

  const singleSettings: Settings = {
    dots: true,
    // prevArrow: <PreArrw />,
    // nextArrow: <NextArrw />,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: ReactElement) => <ul> {dots} </ul>,
    beforeChange: (_, next) => {
      console.log(next, "single carousel");
      const dotList = Array.from(
        document.querySelectorAll(".slick-dots")[0].children.length > 0
          ? document.querySelectorAll(".slick-dots")[0].children
          : [],
      );
      if (dotList.length > 0) {
        dotList.forEach((item, i) => {
          if (i === Math.ceil(next)) {
            item.classList.add("slick-active");
          } else {
            item.classList.remove("slick-active");
          }
        });
      }
    },
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          infinite: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`single-carousel ${rootClassName}`}>
      {isClientSideMounted && (
        <Fragment>
          <button
            aria-label="previous"
            className={clsx(
              "slick-arrow slick-prev hover:bg-button-secondary-hover",
            )}
            onClick={() => singleSlider.current?.slickPrev()}
          >
            <div className="mx-auto h-[20px] w-[20px] text-icon-on-dark-default ">
              <ArrowPrev />
            </div>
          </button>
          <button
            aria-label="next"
            className="slick-arrow slick-next hover:bg-button-secondary-hover"
            onClick={() => singleSlider.current?.slickNext()}
          >
            <div className="mx-auto h-[20px] w-[20px] text-icon-on-dark-default">
              <ArrowNext />
            </div>
          </button>

          <Slider ref={singleSlider} {...singleSettings}>
            {articles?.map((article, index) => {
              return <SingleCarouselCard key={index} article={article} />;
            })}
          </Slider>
        </Fragment>
      )}
    </div>
  );
};

export default SingleCarousel;

const SingleCarouselCard = ({
  article,
}: {
  article: ArticlePreviewType;
  subcategory?: string;
}): ReactElement => {
  const { category_label } = article;
  const catLink = category_label?.replace("_", "/");
  // console.log(category_label, href, "link");
  return (
    <div key={article.id} className={`flex w-full flex-col lg:flex-row`}>
      <div className="order-2 flex w-full flex-col px-0 pt-[20px] lg:order-1 lg:w-1/2 lg:pr-[16px] lg:pt-0">
        <Link
          className="text-label2 text-secondary hover:text-secondary"
          to={`/${catLink}`}
          target="_self"
        >
          <h4 className="text-label4 text-secondary">
            {category_label &&
              category_label in navigationDictionary &&
              navigationDictionary[category_label].label}
          </h4>
        </Link>

        <Link to={article.href}>
          <h2 className="line-clamp-2 text-header1 text-link-headline-default hover:text-link-headline-hover lg:line-clamp-3">
            {article.title}
          </h2>
          <p className="hidden text-body1 text-secondary xl:line-clamp-5">
            {article.summary}
          </p>
        </Link>
      </div>

      <div className="relative order-1 aspect-[3/2] w-full lg:order-2 lg:w-1/2">
        <OptimisedImage
          src={article.thumbnail}
          alt={article.title}
          rootClassName={
            "aspect-[3/2] overflow-hidden rounded-[4px] object-cover object-center"
          }
          loading={"lazy"}
          filters={{
            sm: { w: 480, q: 80, dpr: 3 },
            md: { w: 500, q: 80, dpr: 2 },
            lg: { w: 680, q: 80, dpr: 2 },
            xl: { w: 600, q: 80, dpr: 2 },
          }}
        />
        <ArticleTypeIcon itemTypeProps={article.item_type} />
      </div>
    </div>
  );
};
