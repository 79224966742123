import TagIcon from "@assets/icons/article/tag-icon.svg";
import Link from "@components/Link/Link";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";

interface IArticleTags {
  className?: string;
}
export default function ArticleTags({ className = "" }: IArticleTags) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const tags = article?.tags || [];

  return (
    isValidArray(tags) && (
      <div className={className}>
        <div className="flex">
          <div className="mb-[8px] mr-[8px] h-max py-[4px]">
            <img
              className="!m-0 h-[24px] min-h-[24px] w-[24px] min-w-[24px]"
              src={TagIcon}
              alt="tag icon"
            />
          </div>
          <div className=" flex max-h-max flex-wrap">
            {tags.map((tag, tagIndex) => {
              return (
                <Link
                  key={tagIndex}
                  className=" mb-[8px] mr-[8px] box-border rounded-full bg-secondary px-[16px] py-[4px] font-normal !text-primary no-underline transition-all duration-200 hover:bg-quinary"
                  to={tag.urlPath ? tag.urlPath || "" : "#"}
                  target="_self" // within the same tab
                >
                  {tag.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
}
