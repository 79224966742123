import logoMarkOriginal from "@assets/logos/logo-mark-original.svg";
import Link from "@components/Link/Link";
import clsx from "clsx";

export default function Logo({
  width = 24,
  height = 24,
  rootClassName = "",
}: {
  width?: number;
  height?: number;
  rootClassName?: string;
}) {
  return (
    <Link
      className={clsx("ml-[16px] inline-flex items-center", rootClassName)}
      to="/"
      aria-label="zaobao singapore"
    >
      <img
        src={logoMarkOriginal}
        width={width}
        height={height}
        alt="zaobao singapore"
      ></img>
    </Link>
  );
}
