import { BFF_ENDPOINT } from "@constants/bff";
import { ArticleDetailNS } from "@typings/Data";
import { Helmet } from "react-helmet-async";

export default function ArticlePageSEO({
  article,
}: {
  article: ArticleDetailNS.Article | undefined;
}) {
  if (article === undefined) {
    return null;
  }

  // console.log("ArticlePageSEO: ", article);

  const headline = article.headline;
  const imageURL =
    article.assets[0] === "has_main_image" && article.images
      ? article.images[0].url
      : "";
  const datePublished =
    article.create_time !== undefined
      ? new Date(article.create_time * 1000).toISOString()
      : "";
  const dateModified =
    article.update_time !== undefined
      ? new Date(article.update_time * 1000).toISOString()
      : article.create_time !== undefined
        ? new Date(article.create_time * 1000).toISOString()
        : "";
  const paid_mode = article.paid_mode;
  const authorName = article.author[0]?.name;
  const authorURL = article.author[0]?.url_path;
  const mainEntity = `${BFF_ENDPOINT}${article.url_path}`;

  const SEOString = `
    {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "NewsArticle",
          "headline": "${headline}",
          "name": "${headline}",
          "image": {
            "@type": "ImageObject",
            "representativeOfPage": "True",
            "url": "${imageURL ? imageURL : ""}"
          },
          "datePublished": "${datePublished}",
          "dateModified": "${dateModified}",
          "isAccessibleForFree": ${paid_mode === "free" ? true : false},
          "author": {
            "@type": "Person",
            "name": "${authorName ? authorName : `联合早报`}",
            "url": "${authorName ? (authorURL ? authorURL : "") : ""}"
          },
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.zaobao.com.sg",
            "name": "联合早报",
            "url": "https://www.zaobao.com.sg",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.zaobao.com.sg/themes/custom/zbsg2020/images/apple-touch-icon-57x57.png",
              "width": "57",
              "height": "57"
            }
          },
          "mainEntityOfPage": "${mainEntity}"
        },
        {
          "thumbnailUrl": "${imageURL}"
        }
      ]
    }
  `;

  return (
    <Helmet>
      <script id="seo-article-page" type="application/ld+json">
        {SEOString}
      </script>
    </Helmet>
  );
}
