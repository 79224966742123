import "dayjs/locale/zh-cn";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import { type ReactElement, useEffect, useState } from "react";

const thresholds = [
  { l: "s", r: 1 },
  { l: "m", r: 1 },
  { l: "mm", r: 59, d: "minute" },
  { l: "h", r: 1 },
  { l: "hh", r: 23, d: "hour" },
  { l: "d", r: 1 },
  { l: "dd", r: 29, d: "day" },
  { l: "M", r: 1 },
  { l: "MM", r: 11, d: "month" },
  { l: "y", r: 1 },
  { l: "yy", d: "year" },
];

const rounding = Math.floor;

const config = {
  thresholds: thresholds,
  rounding: rounding,
};

const { extend } = dayjs;
extend(updateLocale);
extend(relativeTime, config);
extend(timezone);
extend(utc);
extend(localizedFormat);
dayjs.locale("zh-cn");
// dayjs.updateLocale("cn-sg", {
//   weekdays: ["日", "一", "二", "三", "四", "五", "六"],
// });

type TimestampFormat =
  | "relative"
  | "article"
  | "epaper"
  | "weekday"
  | "default";

export type TimeStampProps = {
  parsable?: number | string; // Accepts timestamp as string or number
  format?: TimestampFormat;
  formatString?: string;
};

const timestampFormatMap = {
  relative: "DD/MM/YYYY",
  article: "ddd, MMM D, YYYY - h:mm A",
  epaper: "YYYY年 M月 D日",
  weekday: "dddd",
  default: "DD/MM/YYYY",
};

export default function Timestamp({
  format = "default",
  parsable = 0,
  formatString = "",
}: TimeStampProps): ReactElement {
  const [relativeFormatted, setRelativeFormatted] = useState("");
  // Fixed format date is always same server and client side
  // We can safely render it on the server side
  // This will help with SEO
  const dayjsTime =
    typeof parsable === "number" ? dayjs.unix(parsable) : dayjs(parsable);

  const formatted = dayjsTime
    .tz("Asia/Singapore")
    .locale("cn-sg")
    .format(formatString || timestampFormatMap[format]);

  useEffect(() => {
    if (format === "relative") {
      setRelativeFormatted(
        dayjsTime.isAfter(dayjs().subtract(24, "hours"))
          ? dayjsTime.fromNow()
          : "",
      );
    }
  }, [format, parsable, dayjsTime]);

  return <>{relativeFormatted || formatted}</>;
}
