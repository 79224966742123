import { useEffect } from "react";

type Props = {
  id: string;
  value: string;
  portion?: "head" | "body";
  noscript?: boolean;
  src?: string;
  async?: boolean;
};

export default function useScriptFunction({
  id,
  value,
  portion = "head",
  noscript = false,
  src,
  async,
}: Props) {
  useEffect(() => {
    if (document.getElementById(id)) {
      return;
    }

    let script;

    if (value || src) {
      if (!noscript) {
        script = document.createElement("script");
        script.type = "text/javascript";
      } else {
        script = document.createElement("noscript");
      }

      if (src) {
        script.setAttribute("src", src);
      } else {
        script.innerHTML = value;
      }

      if (async) {
        script.setAttribute("async", "");
      }

      script.id = id;
      document[portion].appendChild(script);
    }
  }, [id, value, portion, noscript, src, async]);
}
