/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DRUPAL_ENDPOINT } from "@constants/drupal";
import userState, { OKTAUserType } from "@stores/user";
import { getErrorMessage } from "@utils/error";
import loadScript from "@utils/loadScript";
import loadScriptContent from "@utils/loadScriptContent";
import axios, { type AxiosResponse } from "axios";
import Cookie from "js-cookie";
import { useEffect } from "react";
import { useSnapshot } from "valtio";

const GOOGLE_ONE_TAP_SCRIPT = import.meta.env.VITE_GOOGLE_ONE_TAP_SCRIPT;

/**
 * @description This function is used to load the MySPH script.
 * @see https://docs.google.com/document/d/1Lr7g7tSQeSmla__3k-eI_CN3sUJjpHhOgMUfRWGnphg
 */
export default function useMySPH() {
  const { user } = useSnapshot(userState);

  useEffect(() => {
    if (typeof window !== "undefined") {
      postMySPHSession(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.loginid]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleFetchLoginDetails();

      void loadScript(
        import.meta.env.VITE_MYSPH_LIGHTBOX_URL,
        () => !!window._mySPHObj,
        "mysph_lightbox",
      );

      void loadScript(
        GOOGLE_ONE_TAP_SCRIPT,
        () => {
          return true;
        },
        "googleOneTap",
      );

      loadScriptContent(
        "mySPHSignUp",
        `
var userObj={firstname:"",lastname:"",email:"",password:"",phone:""}
    `,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function fetchLoginDetails(
  successCallback: (res: AxiosResponse) => void,
  errorCallback: (error: unknown) => void,
) {
  axios
    .post(`${DRUPAL_ENDPOINT}/login_details.php?${new Date().valueOf()}`)
    .then((res) => {
      if (res.status === 200) {
        successCallback(res);
      }
    })
    .catch((error: unknown) => {
      errorCallback(error);
    });
}

function handleFetchLoginDetails() {
  // load user information
  fetchLoginDetails(
    (res) => {
      if (res.status === 200) {
        const newUserData = res.data as OKTAUserType;

        if (!newUserData?.loginid) {
          trySingleSignOnLogin();
        }

        // if (typeof window._data !== "undefined") {
        //   window._data.visitorcat = newUserData?.service_type_value;
        //   if (typeof newUserData?.mysphw !== "undefined") {
        //     window._data.at = newUserData.mysphw;
        //   }
        //   if (typeof newUserData?.svc !== "undefined") {
        //     window._data.svc = newUserData.svc;
        //   }
        // }

        // Set the user state
        // console.log("user: assigning new user data", newUserData);
        Cookie.set("loginid", newUserData?.loginid || "", {
          expires: 60,
        });
        windowDataLayer();
        userState.user = newUserData;

        // Dispatch the event
        const event = document.createEvent("Event");
        event.initEvent("ldapLoginDetailsReceived", true, true);
        document.dispatchEvent(event);

        //
      }
    },
    (error) => {
      const errorThrown = getErrorMessage(error);
      trySingleSignOnLogin();
      windowDataLayer();
      // console.log("Mysph Login page: " + errorThrown);
      userState.userError = errorThrown;
    },
  );
}

// TODO logic to be added when develop article page
//dispatch event in sso login
//adding visitor information to tm datalayer
function windowDataLayer() {
  if (typeof window === "undefined") {
    return;
  }

  const userType = Cookie.get("mySPHUserType");
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  // Set Targeting based on userType
  window.googletag.cmd.push(function () {
    let user_status = "N";
    if (userType != undefined) {
      if (userType == "y-sub") {
        user_status = "Y";
      } else if (userType == "y-reg") {
        user_status = "R";
      }
      googletag.pubads().setTargeting("subscriber", user_status);
    } else {
      //adding visitor information to tm datalayer
      googletag.pubads().setTargeting("subscriber", user_status);
    }
  });

  const visitorcat = Cookie.get("visitorcat");
  const mysphw = Cookie.get("mysphw");
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    visitorcat: visitorcat,
    at: mysphw,
  });
}

//MYSPH singleSignon Integration
function trySingleSignOnLogin() {
  // console.log("trying trySingleSignOnLogin");
  window.loadSubscriberContent = function () {
    handleFetchLoginDetails();
  };

  const f = document.createElement("iframe");
  f.src = `${DRUPAL_ENDPOINT}/mysph/login.php`;
  f.style.width = "0px";
  f.style.height = "0px";
  f.style.border = "none";
  f.style.display = "none";
  document.body.append(f);
}

//Post MySPHSession
function postMySPHSession(data: OKTAUserType) {
  const inter = window.setInterval(function () {
    if (null != window._mySPHObj) {
      window.clearInterval(inter);
      window._mySPHObj.postSessionInformation(data?.mySPHSessionId as string);
    }
  }, 300);
}

// TODO logic to be added when develop article page
// window.loadSubscriberContent = function () {
//   console.log("SubscriberContent SSO");
//   if (window._data.articleid !== "") {
//     $.ajax({
//       type: "POST",
//       url: "/mysph/details?article_id=" + window._data.articleid,
//       dataType: "json",
//       cache: true,
//       success: function (data: OKTAUserType) {
//         if (data?.loginid) {
//           Cookie.set("loginid", data.loginid, { expires: 60 });
//           postMySPHSession(data);
//           ssoDispatchEvent(data);
//           const fullBodyContainer = document.querySelector(
//             "body .article-content-rawhtml",
//           );
//           if (data.full_body && fullBodyContainer) {
//             fullBodyContainer.innerHTML = data.full_body;
//           }
//         }
//       },
//     });
//   } else {
//     fetchLoginDetails();
//   }
// };
