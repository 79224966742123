/* eslint-disable */
import FeatureNewsIcon from "@assets/icons/feature-news-icon.svg?react";
import StandardArticleCard from "@components/ArticleCard/StandardArticleCard/StandardArticleCard";
import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import SideMostReadArticleList from "@components/ArticleCardList/SideMostReadArticleList/SideMostReadArticleList";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import Card from "@components/Card/Card";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Link from "@components/Link/Link";
import SubCategoryHeader from "@components/SubCategoryHeader/SubCategoryHeader";
import Tabs from "@components/Tabs/Tabs";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import AriaIframe from "@components/Iframe/Iframe";
import type {
  ArticlePreviewType,
  WebCategoryLevel1ContextType,
  PageContentListContextType,
  // HomepageSpecialTopicType,
} from "@typings/Data";
import clsx from "clsx";
import { useState, Fragment, useMemo } from "react";
import fallbackImage from "@assets/desktop-default.png";
import NewsletterSignUpForm from "@components/NewsletterSignUpForm/NewsletterSignUpForm";
import { useSnapshot } from "valtio";
import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import userState from "@stores/user";
import HomepageCampaignCarousel from "./HomepageCampaignCarousel";
import HomepageNewsticker from "./HomepageNewsticker";
import HomepageSpecialTopic from "./HomepageSpecialTopic";
import HomepageRealtimeArticles from "./HomepageTickerArticles";
import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";

export default function HomeDefaultLayout() {
  const {
    context: {
      payload: {
        podcastUrl = "",
        dailyTopArticles = [],
        weeklyTopArticles = [],
        pageContentList = [],
      },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel1ContextType & PageContentListContextType;
  }> = useRouteContext();

  const sectionAdsName = "homepage";
  const { user } = useSnapshot(userState);

  // console.log("podcastUrl:", podcastUrl);
  // console.log("dailyTopArticles: ", dailyTopArticles);
  // console.log("weeklyTopArticles: ", weeklyTopArticles);
  // console.log("pageContentList: ", pageContentList);

  const [computedTopArticles, setComputedTopArticles] =
    useState<ArticlePreviewType[]>(dailyTopArticles);

  const keyedPageContentList = useMemo(() => {
    return pageContentList.reduce(
      (acc, item) => {
        return {
          ...acc,
          [item.key as string]: item,
        };
      },
      {} as Record<string, PageContentListContextType>,
    );
  }, [pageContentList]);

  const videosSectionAndBelow = useMemo(() => {
    return pageContentList.filter((_, index) => {
      const startIndex = pageContentList.findIndex(
        (item) => item.key === "videos",
      );
      return index >= startIndex;
    });
  }, [pageContentList]);

  return (
    <main>
      {/* client side homepage full width banner */}
      <HomepageFullWidthBanner level1="home" />

      {/* client side newsticker */}
      <HomepageNewsticker />

      <section id="today-recommended-news-finance">
        <div className="layout-container">
          <div className="row">
            <div className="inline-block w-full">
              {[
                keyedPageContentList?.["today_recommend_article"],
                keyedPageContentList?.["news"],
                keyedPageContentList?.["finance"],
              ].map((children, index) => {
                if (!children.articles || !children.articles.length) {
                  return null;
                }

                if (children.key === "today_recommend_article") {
                  const href = children.articles[0].href || "#";
                  const title = children.articles[0].title || "";
                  const summary = children.articles[0].summary || "";
                  const thumbnail =
                    children.articles[0].thumbnail || fallbackImage;
                  const itemType = children.articles[0].item_type;
                  return (
                    <Fragment key={children.key || index}>
                      <div className="w-full lg:float-left lg:w-8/12 xl:w-9/12">
                        <div className="card news-feature-card">
                          <div className="card-header">
                            <FeatureNewsIcon />
                            <h1>{children.label}</h1>
                          </div>
                          <div className="card-content">
                            <Link
                              className="card-content-container"
                              referrer="today-news-section-card-1"
                              to={href}
                            >
                              <h2>{title}</h2>
                              <p>{summary}</p>
                            </Link>
                          </div>
                          <Link
                            className="card-image-container relative"
                            referrer="today-news-section-card-1"
                            to={href}
                            target="_blank"
                          >
                            <OptimisedImage
                              alt={title}
                              src={thumbnail}
                              filters={{
                                xxl: { f: "avif", w: 600, q: 80, dpr: 3 },
                                lg: { f: "avif", w: 800, q: 80, dpr: 3 },
                                md: { f: "avif", w: 600, q: 100, dpr: 3 },
                                sm: { f: "avif", w: 500, q: 100, dpr: 3 },
                              }}
                            />
                            <ArticleTypeIcon itemTypeProps={itemType} />
                          </Link>
                        </div>
                      </div>

                      <div className="relative w-full lg:float-right lg:w-4/12 xl:w-3/12">
                        <ul className="homepage-today-recommended-1-col-layout">
                          {children.articles?.length &&
                            children.articles
                              .slice(1, 4)
                              .map((article, index) => {
                                const id = `today-news-section-card-${
                                  index + 2
                                }`;
                                return (
                                  <li id={id} key={article.id || index}>
                                    <StandardArticleCard
                                      id={id}
                                      article={article}
                                      hideTimestamp={true}
                                    />
                                  </li>
                                );
                              })}
                        </ul>
                      </div>

                      <div className="w-full lg:float-left lg:w-8/12 lg:px-0 xl:w-9/12">
                        <ul className="homepage-today-recommended-3-col-layout flex w-full">
                          {children.articles?.length &&
                            children.articles
                              .slice(4, 13)
                              .map((article, index) => {
                                const id = `today-news-section-card-${
                                  index + 5
                                }`;

                                return (
                                  <li id={id} key={article.id || index}>
                                    <StandardArticleCard
                                      id={id}
                                      article={article}
                                      hideTimestamp={true}
                                      rootClassName={clsx({
                                        "no-image": index > 5,
                                      })}
                                    />
                                  </li>
                                );
                              })}
                        </ul>
                      </div>

                      <div className="w-full lg:float-right lg:w-4/12 xl:w-3/12">
                        <div className="layout-container">
                          <div className="row">
                            <GoogleAd
                              type={AdvertisementTypeEnum.IMU1}
                              slot={GoogleAdsSlotFactory.imu1(sectionAdsName)}
                              rootClassName="px-8 py-[16px]"
                            />
                            <HomepageRealtimeArticles />
                            <GoogleAd
                              type={AdvertisementTypeEnum.IMU2}
                              slot={GoogleAdsSlotFactory.imu2(sectionAdsName)}
                              rootClassName="px-8 py-[16px]"
                            />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                }

                return (
                  <div
                    key={children.key || index}
                    className="homepage_news_finance_layout"
                  >
                    <Card
                      heading={children.label}
                      headingLink={children.href}
                      isLink={true}
                      headingComponent={
                        <SubCategoryHeader categories={children.children} />
                      }
                    >
                      <div className="layout-content">
                        <VerticalArticleCard
                          article={children.articles[0]}
                          imageOptimise={{
                            md: { w: 480, q: 80, dpr: 3 },
                            lg: { w: 600, q: 80, dpr: 3 },
                          }}
                        />
                        <ul>
                          {children.articles?.length &&
                            children.articles.slice(1).map((article, index) => {
                              return (
                                <li key={article.id || index}>
                                  <StandardArticleCard
                                    article={article}
                                    rootClassName="no-image"
                                  />
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* client side special topic */}
      <HomepageSpecialTopic />

      {/* client side homepage campaign carousel */}
      <HomepageCampaignCarousel />

      {/* ssr  & entertainment section */}
      <section className="homepage-forum-entertainment-section">
        <div className="layout-container">
          <div className="row">
            <div className="w-full">
              {[
                keyedPageContentList?.["forum"],
                keyedPageContentList?.["entertainment"],
              ].map((children, index) => {
                if (
                  children &&
                  children?.articles &&
                  children?.articles.length
                ) {
                  return (
                    <div key={index}>
                      <div className={`w-full ${children.key}-div`}>
                        <Card
                          key={children.key}
                          heading={children.label}
                          headingLink={children.href}
                          isLink={true}
                          headingComponent={
                            <SubCategoryHeader categories={children.children} />
                          }
                        >
                          {children.key === "forum" ? (
                            <div className="forum-content -mx-[20px]">
                              <VerticalArticleCard
                                hideTimestamp={true}
                                article={
                                  children.articles && children.articles[0]
                                }
                                rootClassName="quote-card lg:w-1/3"
                                imageOptimise={{
                                  md: { w: 480, q: 80, dpr: 3 },
                                  lg: { w: 600, q: 80, dpr: 3 },
                                }}
                              />
                              <ul>
                                {children.articles?.length &&
                                  children.articles
                                    .slice(1)
                                    .map((article, index) => {
                                      return (
                                        <li key={article.id || index}>
                                          <StandardArticleCard
                                            article={article}
                                            hideTimestamp={true}
                                            rootClassName="quote-card"
                                          />
                                        </li>
                                      );
                                    })}
                              </ul>
                            </div>
                          ) : null}

                          {children.key === "entertainment" ? (
                            <div className="entertainment-content -mx-[20px]">
                              <ul>
                                {children.articles?.length &&
                                  children.articles.map((article, index) => {
                                    return (
                                      <li key={article.id || index}>
                                        <VerticalArticleCard
                                          article={article}
                                          imageOptimise={{
                                            md: { w: 480, q: 80, dpr: 3 },
                                            lg: { w: 600, q: 80, dpr: 3 },
                                          }}
                                        />
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          ) : null}
                        </Card>
                      </div>
                      {children.key === "forum" ? (
                        <div className="podcast-sidebar">
                          {podcastUrl ? (
                            <Card
                              key="podcast"
                              heading="收听播客"
                              headingLink={"podcast"}
                              isLink={true}
                            >
                              <div className="iframe-div py-[16px]">
                                <AriaIframe
                                  src={`${podcastUrl}?style=artwork&image=1&share=1&download=0&description=1&subscribe=0&foreground=464646&background=f5f5f5&highlight=a80034`}
                                  title="podcast"
                                  className="h-[180px] w-full"
                                />
                              </div>
                            </Card>
                          ) : null}
                          <Card
                            heading="热门"
                            isLink={false}
                            headingComponent={
                              <Tabs
                                tabs={[
                                  {
                                    label: "单日",
                                    key: "popular-desktopday-tab",
                                    value: "daily",
                                    onClick: () => {
                                      setComputedTopArticles(dailyTopArticles);
                                    },
                                  },
                                  {
                                    label: "一周",
                                    key: "popular-desktopweek-tab",
                                    value: "weekly",
                                    onClick: () => {
                                      setComputedTopArticles(weeklyTopArticles);
                                    },
                                  },
                                ]}
                              />
                            }
                          >
                            <SideMostReadArticleList
                              articles={computedTopArticles}
                            />
                          </Card>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>

      {/* ssr videos section and below */}
      {videosSectionAndBelow.map((children, index) => {
        if (children.articles && children.articles.length) {
          return (
            <section
              key={children.key || index}
              className={clsx(`${children.key}-section`, {
                "bg-cinema": children.key === "videos",
              })}
            >
              <div className="layout-container">
                <div className="row">
                  <Card
                    heading={children.label}
                    headingLink={children.href}
                    isLink={true}
                    headingComponent={
                      <SubCategoryHeader
                        categories={children.children}
                        isInverse={children.key === "videos" ? true : false}
                      />
                    }
                    isInverse={children.key === "videos" ? true : false}
                  >
                    <ul className="-mx-[20px] lg:flex">
                      {children.articles?.length &&
                        children.articles.map((article, index) => {
                          return (
                            <li key={article.id || index} className="lg:w-1/4">
                              <VerticalArticleCard
                                article={article}
                                rootClassName={clsx({
                                  inverse: children.key === "videos",
                                })}
                                imageOptimise={{
                                  md: { w: 480, q: 80, dpr: 3 },
                                  lg: { w: 600, q: 80, dpr: 3 },
                                }}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </Card>
                </div>
              </div>
            </section>
          );
        }
      })}

      {/* Newsletter */}
      {!user?.loginid && (
        <section>
          <div className="layout-container">
            <div className="mx-auto lg:w-full">
              <NewsletterSignUpForm />
            </div>
          </div>
        </section>
      )}
      <div id="spacer" className="h-[40px] w-full"></div>
    </main>
  );
}
