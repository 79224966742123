import fontState, { FONT_SIZE_OPTION } from "@stores/font";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

/**
 * @name useFontSize
 * @description to solve the hydration mismatch problem
 */
export default function useFontSize() {
  const { fontSize: globalFontSize, updateGlobalFontSize } =
    useSnapshot(fontState);

  const [fontSize, setFontSize] = useState(FONT_SIZE_OPTION.standard);

  useEffect(() => {
    if (globalFontSize != fontSize) {
      setFontSize(globalFontSize);
    }
  }, [globalFontSize, fontSize]);

  return {
    fontSize,
    setFontSize: (v: FONT_SIZE_OPTION) => {
      setFontSize(v);
      updateGlobalFontSize(v);
    },
  };
}
