/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { type AdTargetingType } from "@components/GoogleAd/GoogleAd";
import { adTargetings } from "@constants/ads";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type CatfishGoogleAdProps = {
  slot: string;
  id?: string;
};

/**
 * This component renders an IMU ad.
 * @param rootClassName - The root class name.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export default function CatfishGoogleAd({ slot = "" }: CatfishGoogleAdProps) {
  const [isAdLoaded, setIsAdLoaded] = useState(false);
  const targetNodeRef = useRef<HTMLElement | null>(null);
  const [slotInstance, setSlotInstance] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const id: string = "dfp-ad-catfish";
  const adSlotSize = useMemo(() => [[1, 1]] as googletag.GeneralSize, []);
  const adTarget = adTargetings.catfish;

  const isDfpPreviewIdsValid = () => {
    return (
      window.dfp_preview_ids != "" &&
      window.dfp_preview_ids != undefined &&
      (window?.dfp_preview_ids as Array<string>)?.length != 0
    );
  };

  const setCatfishExpiryCookie = () => {
    if (
      !document.cookie.includes("catfishDisplayed=") ||
      isDfpPreviewIdsValid()
    ) {
      if (screen.width < 767 && window.catfish_enabled === 1) {
        const expiry = new Date();
        expiry.setTime(
          expiry.getTime() + window.catfish_validity * 60 * 60 * 1000,
        );
        document.cookie =
          "catfishDisplayed=yes;path=/; expires=" + expiry?.toUTCString();
      }
    }
  };

  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = useCallback(() => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      googletag
        .pubads()
        .getSlots()
        .forEach(function (_slot) {
          if (_slot.getSlotElementId()) {
            if (_slot.getSlotElementId() === id) {
              googletag.destroySlots([_slot]);
            }
          }
        });

      if (
        adSlotSize &&
        window.canRunAds !== undefined &&
        screen.width <= 767 &&
        window.catfish_enabled === 1 &&
        !document.cookie.includes("catfishDisplayed=")
      ) {
        console.log("catfish: defined slot", slot, adSlotSize, id);
        // define
        const _adSlot = googletag.defineSlot(slot, adSlotSize, id);

        if (_adSlot) {
          _adSlot
            .addService(googletag.pubads())
            .setTargeting("pos", ["catfish"])
            .setTargeting("weight", ["6"]);

          adTarget?.forEach((target: AdTargetingType) => {
            _adSlot.setTargeting(target.key, target.value);
          });

          // display
          console.log("catfish: displaying", id);
          googletag.display(id);
          // set expiry once catfish is displayed
          setCatfishExpiryCookie();
          // Store the ad slot for later use.
          setSlotInstance(_adSlot);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slot, adSlotSize, adTarget]);

  useEffect(() => {
    const callBack = () => {
      if (
        targetNodeRef?.current?.children[0]?.firstElementChild?.tagName ===
        "IFRAME"
      ) {
        setIsAdLoaded(true);
      }
    };

    targetNodeRef.current = document.getElementById(id);
    const observer = new MutationObserver(callBack);

    if (targetNodeRef.current) {
      observer.observe(targetNodeRef.current, {
        childList: true,
        subtree: true,
      });
    }
  }, [setIsAdLoaded]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // TODO add checks for window width
      if (slot && !slotInstance) {
        handleDisplayAd();
      }
    }
  }, [slot, handleDisplayAd, slotInstance]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      googletag.cmd.push(function () {
        slotInstance && googletag.destroySlots([slotInstance]);
      });
    };
  }, [slotInstance]);

  return (
    <div className="catfish-google-ads layout-container fixed bottom-0 left-0 z-[45] min-h-[50px] justify-center p-[10px] lg:hidden">
      <div
        className={clsx("w-full items-center justify-center", {
          invisible: !isAdLoaded,
        })}
      >
        <button id={id}></button>
      </div>
    </div>
  );
}
