// import type { ArticleCardProps } from "@components/ArticleCard/types";
import Link from "@components/Link/Link";
import Timestamp from "@components/Timestamp/Timestamp";
import { ArticlePreviewType } from "@typings/Data";
// import { generateArticleLink } from "@utils/article";
import { ReactElement } from "react";

const TimestampArticleCard = ({
  rootClassName = "",
  article,
}: {
  rootClassName?: string;
  article: ArticlePreviewType;
}): ReactElement => {
  return (
    <div
      className={`card timestamp-card ${rootClassName}`}
      data-testid={`test-article-card-${article.id}`}
    >
      <div className="card-content">
        <div className="text-label1 text-brand">
          <Timestamp
            parsable={article.timestamp}
            formatString="HH:mm"
            format="default"
          />
        </div>
        <Link to={article.href}>
          <h2 className="card-header">{article.title}</h2>
        </Link>
      </div>
    </div>
  );
};

export default TimestampArticleCard;
