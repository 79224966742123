import { navigationDictionary } from "@constants/navigation";
import useSafePrams from "@hooks/useSafeParams";
import languageState from "@stores/language";
import { regexMatchArticle } from "@utils/article";
import { sanitize } from "@utils/sanitize";
import { useSnapshot } from "valtio";

function getLocalListingLabel(key: keyof typeof navigationDictionary) {
  return key in navigationDictionary
    ? navigationDictionary[key].label || ""
    : "";
}

/**
 * Return the English label for a given key in navigationDictionary, or
 * if the key does not exist, return the level2FromRoute with all dashes
 * replaced with spaces.
 *
 * @param key The key to look up in navigationDictionary.
 * @param level2FromRoute The value of level2FromRoute if the key does not exist.
 * @returns The English label for the given key, or the level2FromRoute with
 * dashes replaced with spaces.
 */
function getEnListingLabel(
  key: keyof typeof navigationDictionary,
  level2FromRoute: string = "",
) {
  if (key in navigationDictionary) {
    return navigationDictionary[key].label_en || "";
  }
  return level2FromRoute.replace(/-/g, " ");
}

export default function useGA() {
  const { isTraditionalChinese } = useSnapshot(languageState);

  const {
    level1: level1FromRoute = "",
    level2: level2FromRoute = "",
    level3: level3FromRoute = "",
    level4: level4FromRoute = "",
    level5: level5FromRoute = "",
  } = useSafePrams();

  const fullStoryChapters: string[] = [
    level1FromRoute,
    level2FromRoute,
    level3FromRoute,
    level4FromRoute,
    level5FromRoute,
  ].filter(Boolean);

  const endingPath = sanitize(fullStoryChapters.at(-1) || "");

  // check if ending path is an article
  if (endingPath.match(regexMatchArticle)) {
    fullStoryChapters.pop();
  }

  const chapter1UniqueKey = level2FromRoute
    ? [level1FromRoute, level2FromRoute].filter(Boolean).join("_")
    : "";

  //https://www.zaobao.com.sg/<<level2>>/<<chapter1>>/<<chapter2>>/<<chapter3>>/<<story_path>>
  return {
    level2Default: sanitize(level1FromRoute) || "",
    level2_gaDefault: sanitize(level1FromRoute) || "",
    level2_localDefault: getLocalListingLabel(level1FromRoute),
    chapter1Default: getLocalListingLabel(chapter1UniqueKey),
    chapter1_enDefault: getEnListingLabel(level1FromRoute), // this actually use level2 english value for permutive
    // TODO how about keywords and storythreads pages? need to prerender it in the context if CaaS has
    chapter2Default: sanitize(level3FromRoute) || "",
    chapter2_enDefault: getEnListingLabel(chapter1UniqueKey, level2FromRoute), // this actually use chapter1 english label for permutive
    chapter3Default: sanitize(level4FromRoute) || "",
    story_pathDefault: endingPath.match(regexMatchArticle) ? endingPath : "",
    languageDefault: isTraditionalChinese ? "TC" : "SC",
  };
}
